import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import ReactInputMask from "react-input-mask";
import { AuthContext } from "../../../../../providers/AuthProvider";
import api, { googleAuth } from "../../../../../utils/api";
import unmask from "../../../../../utils/unmask";
import { Loader } from "../../../../../components/Loader";
import parsePhoneAdmin from "../../../../../utils/parsePhoneAdmin";

import arrow from "../../../../../assets/Vector.png";
import logo from "../../../../../assets/407b23225039d193f5e539e2de0900f2.svg";
import iconWhaCL from "../../../../../assets/Vector (12).png";
import iconWhaE from "../../../../../assets/WhatsappLogo.png";
import iconWharnin from "../../../../../assets/Warning.png";
import iconGoogle from "../../../../../assets/GoogleLogo.png";

import "./Step6.css";
import { useNavigate } from "react-router-dom";
import { Google } from "@mui/icons-material";

const navigateimulator = {
  menu: "Simulador do Atendente",
  index: 6,
};

const Step6 = ({
  form,
  saveLoading,
  setMenuSelected,
  section3Ref,
  scrollSimulator,
}) => {
  const navigate = useNavigate();
  const {
    user,
    updateUser,
    updatePartnerDetail,
    signOut,
    checkComplete,
    checkList,
  } = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState("0");
  const [qrLoading, setQrLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const [modalWarning, setModalWarning] = useState(false);
  const [phone, setPhone] = useState(user.serviceNumber || "");
  const [tempPhone, setTempPhone] = useState(phone);
  const [saveLoadings, setSaveLoadings] = useState(false);
  const [checkConnected, setCheckConnected] = useState(false);
  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let width = isMobileDevice() ? 350 : 515;

  const handleMenu = (item) => {
    if (
      item.menu === "Simulador do Atendente" &&
      !checkList.partnerDetailsFilled
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      navigate("/simulador");
    }
  };

  const responseGoogle = async (response) => {
    try {
      const { data } = await googleAuth.post("/callback", {
        code: response.code,
        partnerId: user.id,
      });
      if (data) {
        //console.log(data);
        toast.success("Sincronização de calendário realizada com sucesso!");
        if (user.partnerDetailId) {
          await checkComplete(user.partnerDetailId);
        } else {
          await checkComplete(user.id);
        }
        form.setFieldValue("connectedWithGoogle", true);
        navigate("/agendamento");
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar sincronizar o calendário.");
    }
    //console.log(response);
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => responseGoogle(tokenResponse),
    scope: ["https://www.googleapis.com/auth/calendar"],
    flow: "auth-code",
  });

  const setPrimary = () => {
    setModal(false);
    setModalWarning(false);
    setQrCode("");
    setStep("0");
  };

  const updatePhone = async () => {
    setSaveLoadings(true);

    try {
      if (phone) {
        const { data } = await api.put(`/partners/partners/${user.id}`, {
          data: {
            attributes: {
              service_number: unmask(phone),
            },
          },
        });
        if (data) {
          setStep("1");
          updateUser(data);
          toast.success(
            "Número atualizado com sucesso! Conclua a validação para concluir a ativação."
          );
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar o número cadastrado.");
    } finally {
      setSaveLoadings(false);
    }
  };

  const getQRCode = async () => {
    setQrLoading(true);

    try {
      const { data } = await api.get("/partners/auth_whatsapp", {
        responseType: "arraybuffer",
      });

      if (data) {
        const blob = new Blob([data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);

        if (url) {
          setQrCode(url);
        } else {
          setQrCode("QRCodeError");
          throw Error("No URL");
        }
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar gerar o QR Code de validação.");
      if (error?.message !== "No URL") {
        setQrCode("GeneralError");
      }
    } finally {
      setQrLoading(false);
    }
  };

  useEffect(() => {
    if (step === "1") {
      getQRCode();
    }
  }, [step]);

  useEffect(() => {
    setTempPhone(phone);
  }, [phone]);

  const checkWhatsConeccted = async () => {
    try {
      const { data } = await api.get("/partners/partners/wpp_connected", {
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      setCheckConnected(data.wppConnected);
    } catch (error) {}
  };

  // checkWhatsConeccted();

  const CountdownTimer = () => {
    const [seconds, setSeconds] = useState(60);

    if (seconds === 0 && !checkConnected) {
      setModal(false);
      setModalWarning(false);
      setQrCode("");
      setStep("0");
      toast.error("Tempo expirado! Por favor, gere um novo QR Code.");
    } else if (checkConnected) {
      setModal(false);
      setModalWarning(false);
      setQrCode("");
      setStep("0");
      toast.success("Numero do atendente conectado com sucesso!");
      if (user.partnerDetailId) {
        checkComplete(user.partnerDetailId);
      } else {
        checkComplete(user.id);
      }
    }

    useEffect(() => {
      const countdownInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      const checkInterval = setInterval(async () => {
        await checkWhatsConeccted();
        if (checkConnected) {
          clearInterval(countdownInterval);
          clearInterval(checkInterval);
        }
      }, 8000);

      return () => {
        clearInterval(countdownInterval);
        clearInterval(checkInterval);
      };
    }, [checkConnected]);

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p
          style={{
            color: seconds <= 10 && "red",
            margin: 0,
            fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', "sans-serif"],
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
            alignSelf: "flex-start",
            marginTop: "15px",
            marginBottom: "15px",
            textAlign: "center",
            width: "100%",
          }}
        >
          Tempo restante: {minutes}:{remainingSeconds < 10 ? "0" : ""}
          {remainingSeconds}
        </p>
      </div>
    );
  };

  const handleDigit = (input) => {
    if (input?.length >= 11 && !input.startsWith("55")) {
      let num;
      num = `55${input}`;
      return num;
    }
    return input;
  };

  return (
    <div className="containerStep6" ref={section3Ref}>
      <div className="titlePage">Vincular Contas</div>
      <div className="containerInput">
        <div className="title">Conta do WhatsApp</div>
        <div className="subTitleInput">
          Vincule a conta do WhatsApp que será usada pelo assistente
        </div>

        <div className="containerButtonsContas">
          {tempPhone && (
            <div className="containerNumber">
              <img src={iconWhaCL} alt="arrow" />
              <div>{parsePhoneAdmin(handleDigit(tempPhone))}</div>
            </div>
          )}

          <div
            className="button"
            onClick={() => {
              setModal(true);
            }}
            style={{
              color: "#102649",
              backgroundColor: "#77E191",
            }}
          >
            <img src={iconWhaE} alt="arrow" />
            <button>Atualizar Conta de WhatsApp</button>
          </div>

          <div
            className="button"
            style={{
              color: user?.wppConnected ? "#102649" : "#539d65",
              backgroundColor: user?.wppConnected ? "#77E191" : "#e6e9ed",
            }}
            onClick={() => {
              setStep(user.wppConnected ? "2" : "1");
              setModal(true);
            }}
          >
            <img src={iconWhaE} alt="arrow" />
            <button>
              {user?.wppConnected
                ? "Telefone do Atendente vinculado"
                : "Vincular Telefone do Atendente"}
            </button>
          </div>
        </div>
      </div>

      <div className="containerInput">
        <div className="title">Conta do Calendário Google</div>
        <div className="subTitleInput">
          Vincule a conta do Calendário Google que será usada pelo assistente
          para agendar reuniões
        </div>

        <div className="containerButtonsContas">
          <div className="containervinculo">
            {form?.values?.connectedWithGoogle ? (
              // <img style={{ color: "#539d65", }} src={iconGoogle} alt="arrow" />
              <Google color="primary" />
            ) : (
              <img src={iconWharnin} alt="arrow" />
            )}

            <div>
              {form?.values?.connectedWithGoogle
                ? "Conta Google vinculada"
                : "Nenhuma conta Google vinculada"}
            </div>
          </div>

          <div
            className="button"
            onClick={() => {
              login();
            }}
            style={{
              color: "#102649",
              backgroundColor: "#77E191",
            }}
          >
            {/* <img src={iconGoogle} alt="arrow" /> */}
            <Google />
            <button style={{ cursor: "pointer" }}>
              Vincular Calendário Google
            </button>
          </div>
        </div>
      </div>

      <div className="containerButtons">
        <div
          onClick={() => handleMenu(navigateimulator)}
          className={`buttonSimulator ${
            !checkList?.partnerDetailsFilled && "disabledMenus"
          }`}
        >
          Simular atendimento
        </div>

        <div onClick={form.handleSubmit} className="buttonSave">
          {saveLoading ? (
            "Carregando..."
          ) : (
            <>
              Salvar alterações
              <img src={arrow} alt="arrow" />
            </>
          )}
        </div>
      </div>

      {modal && (
        <Modal
          open
          onClose={() => {
            setModal(false);
            setQrLoading(true);
            if (step === "1") {
              setModalWarning(true);
              setStep("0");
            } else {
              setStep("0");
            }
          }}
        >
          <Box
            style={{
              background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
              borderRadius: "20px",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: width,
              // height: 200,
              p: 2.5,
            }}
          >
            {step === "0" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ReactInputMask
                  mask={
                    tempPhone.replace(/\s/g, "").length > 13
                      ? "(99) 9 9999-9999"
                      : "(99) 9999-99999"
                  }
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  maskChar={""}
                >
                  {() => (
                    <TextField
                      label="Insira o número de telefone desejado"
                      value={phone}
                      style={{ marginTop: "15px", marginBottom: "5px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={logo}
                              alt="test"
                              style={{ width: "35px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </ReactInputMask>
                <Button
                  sx={{
                    height: "35px",
                    p: 2,
                    m: 1,
                    alignSelf: "center",
                  }}
                  variant="contained"
                  disabled={saveLoadings}
                  onClick={() => {
                    updatePhone();
                  }}
                >
                  <Typography
                    color={"white"}
                    variant={isMobileDevice() ? "h7" : "h6"}
                  >
                    {saveLoadings ? "Carregando" : "Salvar"}
                  </Typography>
                </Button>
                <Typography fontSize={11} sx={{ marginLeft: 1 }}>
                  *Tenha seu número de telefone ao alcance para realizar a
                  validação na próxima etapa
                </Typography>
              </div>
            )}

            {step === "1" && (
              <div>
                {qrLoading ? (
                  <Loader size={150} />
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        margin: 0,
                        fontFamily: [
                          '"Roboto"',
                          '"Helvetica"',
                          '"Arial"',
                          "sans-serif",
                        ],
                        fontWeight: 500,
                        fontSize: "1.25rem",
                        lineHeight: 1.6,
                        letterSpacing: "0.0075em",
                        alignSelf: "flex-start",
                        marginTop: "15px",
                        marginBottom: "15px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {qrCode === "QRCodeError" || qrCode === "GeneralError" ? (
                        <Typography
                          fontSize={24}
                          fontWeight={600}
                          color={"#539d65"}
                        >
                          Erro
                        </Typography>
                      ) : (
                        "Leia o QRCode abaixo para realizar a validação"
                      )}
                    </Typography>
                    {console.log(qrCode)}
                    {qrCode === "QRCodeError" || qrCode === "GeneralError" ? (
                      <Typography
                        textAlign={"center"}
                        style={{ marginBottom: "25px" }}
                      >
                        {qrCode === "QRCodeError"
                          ? "Ocorreu um erro ao gerar o QRCode, verifique se o número registrado está disponível para vinculação."
                          : "Ocorreu um erro ao gerar o QRCode, por favor tente novamente."}
                      </Typography>
                    ) : (
                      <img
                        src={qrCode}
                        alt="QR Code"
                        style={{ borderRadius: "20px" }}
                      />
                    )}
                    <CountdownTimer />
                  </div>
                )}
              </div>
            )}

            {step === "2" && (
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      margin: 0,
                      fontFamily: [
                        '"Roboto"',
                        '"Helvetica"',
                        '"Arial"',
                        "sans-serif",
                      ],
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.0075em",
                      alignSelf: "flex-start",
                      marginTop: "15px",
                      marginBottom: "15px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Typography fontSize={28} fontWeight={600} color={"red"}>
                      ATENÇÃO
                    </Typography>
                  </Typography>

                  <Typography
                    textAlign={"center"}
                    fontWeight={"600"}
                    style={{ marginBottom: "25px" }}
                    fontSize={20}
                  >
                    {"Telefone já conectado"}
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    style={{ marginBottom: "25px" }}
                    fontSize={18}
                  >
                    {
                      "Para vincular novamente, desconecte-o primeiro do seu telefone atual. Em seguida, saia da conta e faça login novamente para atualizar as informações."
                    }
                  </Typography>

                  <Button
                    sx={{
                      height: "35px",
                      p: 2,
                      m: 1,
                      alignSelf: "center",
                    }}
                    variant="contained"
                    disabled={saveLoadings}
                    onClick={() => {
                      setPrimary();
                    }}
                  >
                    <Typography color={"white"} variant={"h6"}>
                      {"OK, ENTENDI"}
                    </Typography>
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      )}

      {modalWarning && (
        <Modal
          open
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setModalWarning("false");
            }
          }}
        >
          <Box
            style={{
              background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
              borderRadius: "20px",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobileDevice() ? 350 : 700,
              // height: 200,
              p: 2.5,
            }}
          >
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: [
                      '"Roboto"',
                      '"Helvetica"',
                      '"Arial"',
                      "sans-serif",
                    ],
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    alignSelf: "flex-start",
                    marginTop: "15px",
                    marginBottom: "15px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Typography fontSize={28} fontWeight={600} color={"#539d65"}>
                    Aviso
                  </Typography>
                </Typography>

                <Typography
                  textAlign={"center"}
                  fontWeight={"600"}
                  style={{ marginBottom: "25px" }}
                  fontSize={20}
                >
                  {"Não responda diretamente pelo WhatsApp"}
                </Typography>
                <Typography
                  textAlign={"center"}
                  style={{ marginBottom: "25px" }}
                  fontSize={18}
                >
                  {
                    "Após configurar seu bot, evite responder diretamente pelo WhatsApp. Qualquer interação direta pode afetar o funcionamento do bot e a experiência do cliente."
                  }
                </Typography>
                <Typography
                  textAlign={"center"}
                  style={{ marginBottom: "25px" }}
                  fontSize={18}
                >
                  {
                    "Para modificações, use os campos de texto e seleção da nossa plataforma."
                  }
                </Typography>
                <Button
                  sx={{
                    height: "35px",
                    p: 2,
                    m: 1,
                    alignSelf: "center",
                  }}
                  variant="contained"
                  disabled={saveLoadings}
                  onClick={() => {
                    setModalWarning(false);
                  }}
                >
                  <Typography color={"white"} variant={"h6"}>
                    {"OK, ENTENDI"}
                  </Typography>
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Step6;
