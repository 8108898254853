import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";

import { Loader } from "../../../components/Loader";

import { useLocation, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/keyboard";
import "./modalSwiperStyling.css";
import {
  Add,
  ArrowBack,
  ArrowForward,
  Close,
  Email,
  Person,
  Store,
  HowToReg,
} from "@mui/icons-material";
import { trimString } from "../../../utils/trimString";
import { InputWithLabel } from "../../../components/InputWithLabel";
import { useFormik } from "formik";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { SelectWithLabel } from "../../../components/SelectWithLabel";
import api from "../../../utils/api";
import logo from "../../../assets/407b23225039d193f5e539e2de0900f2.svg";
import unmask from "../../../utils/unmask";
import { StrictModeDroppable } from "./components/StrictModeDroppable";
import { toast } from "react-toastify";

function isMobileDevice() {
  return window.innerWidth <= 700;
}

export const CRM = () => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [stepModal, setStepModal] = useState("lead");
  const [section, setSection] = useState("identity");
  const [tempPhone, setTempPhone] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const optionsArray = [
    "Agendar uma reunião",
    "Adquirir email do cliente",
    "Outro",
  ];

  const toneArray = [
    "Respeitoso & Empático",
    "Animado & Engajador",
    "Profissional & Firme",
    "Atencioso & Calmo",
    "Encorajador & Motivador",
    "Compassivo & Compreensivo",
    "Divertido & Cativante",
    "Cortês & Gentil",
    "Solidário & Amigável",
    "Eficiente & Rápido",
    "Confiável & Preciso",
    "Assertivo & Decisivo",
    "Perspicaz & Observador",
    "Outro",
  ];

  const daysArray = [
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
    "Domingo",
  ];

  const form = useFormik({
    initialValues: {
      id: null,
      nameAttendant: "",
      companyName: "",
      companyNiche: "",
      servedRegion: "",
      companyProducts: "",
      companyServices: "",
      companyContact: "",
      companyObjectives: [],
      companyObjectivesExtra: "",
      marketingChannels: "",
      keyDifferentials: "",
      toneVoice: [],
      toneVoiceExtra: "",
      preferentialLanguage: "",
      catalogLink: "",
      connectedWithGoogle: false,
    },

    // validationSchema: yup.object({
    //   money: yup.number("Deve ser um número"),
    //   ratio: yup.number("Deve ser um número").required("Campo obrigatório"),
    // }),

    onSubmit: () => updatePrompts(),
  });

  const handleChange = (event, field) => {
    let values = event.target.value;

    // Verifica se o valorEspecial está no array.
    const especialIndex = values.indexOf("Outro");

    // Se o valorEspecial estiver no array, remova-o e adicione-o de volta ao final.
    if (especialIndex !== -1) {
      values.splice(especialIndex, 1);
      values.push("Outro");
    }

    form.setFieldValue(field, values);
  };

  const handleDrop = async (result) => {
    try {
      const { destination, source, draggableId } = result;

      if (!destination) return;

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      )
        return;

      const columns = Array.from(clients);

      const [removedItem] = columns.splice(source.index, 1);

      const newItem = {
        ...removedItem,
        state: handleState(destination.droppableId),
      };

      await updateState(newItem);

      columns.splice(destination.index, 0, newItem);

      console.log(columns);

      setClients(columns);
    } catch (error) {}
  };

  const handleState = (title) => {
    switch (title) {
      case "Prospecção":
        return "prospec";
      case "Proposta":
        return "proposal";
      case "Negociação":
        return "bargain";
      case "Fechamento":
        return "closure";
      default:
        return "archived";
    }
  };

  const [clients, setClients] = useState([]);

  // const [clients, setClients] = useState([
  //   {
  //     id: 1,
  //     contactName: "Nathaniel Bloomfield",
  //     companyName: "Lucas Publicidade e Propaganda Ltda",
  //     email: "marketing@evelynelucaspublicidadeepropagandaltda.com.br",
  //     state: "prospec",
  //     extraContent: "",
  //     registered: false,
  //   },
  //   {
  //     id: 2,
  //     contactName: "Carolina Luna",
  //     companyName: "Carolina Consultoria Financeira",
  //     email: "marketing@lunaecarolinaconsultoriafinanceiraltda.com.br",
  //     state: "prospec",
  //     extraContent: "",
  //     registered: true,
  //   },
  //   {
  //     id: 3,
  //     contactName: "Kevin Ramos",
  //     companyName: "Arthur Assessoria Jurídica",
  //     email: "rh@franciscoearthurassessoriajuridicaltda.com.br",
  //     state: "prospec",
  //     extraContent: "",
  //     registered: true,
  //   },
  //   {
  //     id: 4,
  //     contactName: "Sophia Andrade",
  //     companyName: "Carla Sophia Contabilidade",
  //     email: "estoque@sophiaecarlacontabilme.com.br",
  //     state: "prospec",
  //     extraContent: "",
  //     registered: false,
  //   },
  //   {
  //     id: 5,
  //     contactName: "Bruno Chagas",
  //     companyName: "Juan Assessoria Jurídica",
  //     email: "faleconosco@gabriellyejuanassessoriajuridicaltda.com.br",
  //     state: "proposal",
  //     extraContent: "",
  //     registered: true,
  //   },
  //   {
  //     id: 6,
  //     contactName: "Manoel Silva",
  //     companyName: "Vicente Publicidade e Propaganda",
  //     email: "faleconosco@manoelevicentepublicidadeepropagandame.com.br",
  //     state: "proposal",
  //     extraContent: "",
  //     registered: false,
  //   },
  //   {
  //     id: 7,
  //     contactName: "Benedita Souza",
  //     companyName: "Benedita Advocacia",
  //     email: "fiscal@yagoebeneditaadvocacialtda.com.br",
  //     state: "proposal",
  //     extraContent: "",
  //     registered: false,
  //   },
  //   {
  //     id: 8,
  //     contactName: "Jaqueline Santos",
  //     companyName: "Rosa Informática Ltda",
  //     email: "qualidade@franciscoerosainformaticaltda.com.br",
  //     state: "bargain",
  //     extraContent: "",
  //     registered: false,
  //   },
  //   {
  //     id: 9,
  //     contactName: "Anderson Lima",
  //     companyName: "Eduardo Entregas",
  //     email: "contabilidade@andersoneeduardoentregasexpressasltda.com.br",
  //     state: "bargain",
  //     extraContent: "",
  //     registered: true,
  //   },
  //   {
  //     id: 10,
  //     contactName: "João Victor Capistrano da Silva",
  //     companyName: "Julio Caldas Imobiliária",
  //     email: "suporte@juliocaldas.com",
  //     state: "closure",
  //     extraContent: "",
  //     registered: true,
  //   },
  // ]);

  const clientForm = useFormik({
    initialValues: {
      id: "",
      email: "",
      phone: "",
      contactName: "",
      companyName: "",
      extraContent: "",
      state: "",
    },

    // validationSchema: yup.object({
    //   money: yup.number("Deve ser um número"),
    //   ratio: yup.number("Deve ser um número").required("Campo obrigatório"),
    // }),

    onSubmit: (values) => (values?.id ? updateCRM(values) : newCRM(values)),
  });

  const getCRM = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/affiliates/prospect_cards");

      if (data) {
        const tempArray = data.data.map((item) => ({
          id: item?.id,
          email: item?.attributes?.email,
          phone: item?.attributes?.phone,
          contactName: item?.attributes?.name,
          companyName: item?.attributes?.companyName,
          extraContent: item?.attributes?.observations,
          state:
            item?.attributes?.status && item?.attributes?.status !== "0"
              ? item?.attributes?.status
              : "prospec",

          prospectDetails: item?.attributes?.prospectDetail,
        }));
        console.log(tempArray);
        setClients(tempArray);
      }

      if (data) {
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTempPhone(clientForm.values.phone);
  }, [clientForm.values.phone]);

  const handleTrial = async () => {
    try {
      const { data } = await api.post(
        `/affiliates/bot_configurations/set_prospect_card/${clientForm.values.id}`
      );
      if (data) {
        toast.success("Dados enviados para demonstração com sucesso!");
        setModal(false);
        setStepModal("lead");
        clientForm.setValues(clientForm.initialValues);
      }
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao tentar enviar os dados para demonstração"
      );
      console.log(error);
    }
  };

  const getPrompts = (data) => {
    console.log(data?.company_objectives);
    let valorExtra = "";
    const valoresFiltrados = data?.company_objectives
      ? Object.keys(data?.company_objectives).reduce((acc, key) => {
          if (optionsArray.includes(data?.company_objectives[key])) {
            acc[key] = data?.company_objectives[key];
          } else {
            acc[key] = "Outro";
            valorExtra = data?.company_objectives[key];
          }

          return acc;
        }, [])
      : [];

    let toneExtra = "";
    const toneVoiceFiltrados = data?.tone_voice
      ? Object.keys(data?.tone_voice).reduce((acc, key) => {
          if (toneArray.includes(data?.tone_voice[key])) {
            acc[key] = data?.tone_voice[key];
          } else {
            acc[key] = "Outro";
            toneExtra = data?.tone_voice[key];
          }

          return acc;
        }, [])
      : [];

    console.log("369", data);
    form.setValues({
      ...data,
      nameAttendant: data?.name_attendant,
      companyName: data?.company_name,
      companyNiche: data?.company_niche,
      servedRegion: data?.served_region,
      companyProducts: data?.company_products,
      companyServices: data?.company_services,
      companyContact: data?.company_contact,
      marketingChannels: data?.marketing_channels,
      keyDifferentials: data?.key_differentials,
      preferentialLanguage: data?.preferential_language,
      connectedWithGoogle: false,
      companyObjectives: valoresFiltrados,
      companyObjectivesExtra: valorExtra,
      toneVoice: toneVoiceFiltrados,
      toneVoiceExtra: toneExtra,
      catalogLink: data?.catalog_link || "",
    });
  };

  const updatePrompts = async () => {
    setSaveLoading(true);

    try {
      if (form?.values?.id) {
        const { data } = await api.put(
          `/affiliates/prospect_details/${form.values.id}`,
          {
            data: {
              attributes: {
                nameAttendant: form.values.nameAttendant,
                companyName: form.values.companyName,
                companyNiche: form.values.companyNiche,
                servedRegion: form.values.servedRegion,
                companyServices: form.values.companyServices,
                companyProducts: form.values.companyProducts,
                companyContact: form.values.companyContact,
                companyObjectives: form?.values?.companyObjectives?.map(
                  (item) => {
                    if (item === "Outro") {
                      return form.values.companyObjectivesExtra;
                    } else {
                      return item;
                    }
                  }
                ),
                marketing_channels: form.values.marketingChannels,
                key_differentials: form.values.keyDifferentials,
                tone_voice: form?.values?.toneVoice?.map((item) => {
                  if (item === "Outro") {
                    return form.values.toneVoiceExtra;
                  } else {
                    return item;
                  }
                }),
                preferentialLanguage: form.values.preferentialLanguage,
                catalogLink: form.values.catalogLink,
              },
            },
          }
        );

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
        }
      } else {
        const { data } = await api.post(`/affiliates/prospect_details/`, {
          data: {
            attributes: {
              prospectCardId: clientForm.values.id,
              nameAttendant: form.values.nameAttendant,
              companyName: form.values.companyName,
              companyNiche: form.values.companyNiche,
              servedRegion: form.values.servedRegion,
              companyServices: form.values.companyServices,
              companyProducts: form.values.companyProducts,
              companyContact: form.values.companyContact,
              companyObjectives: form?.values?.companyObjectives?.map(
                (item) => {
                  if (item === "Outro") {
                    return form.values.companyObjectivesExtra;
                  } else {
                    return item;
                  }
                }
              ),
              marketing_channels: form.values.marketingChannels,
              key_differentials: form.values.keyDifferentials,
              tone_voice: form?.values?.toneVoice?.map((item) => {
                if (item === "Outro") {
                  return form.values.toneVoiceExtra;
                } else {
                  return item;
                }
              }),
              preferentialLanguage: form.values.preferentialLanguage,
              catalogLink: form.values.catalogLink,
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          form.setFieldValue("id", data.data.id);
          toast.success("Prompts atualizados com sucesso!");
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
    }
  };

  const updateCRM = async (values) => {
    try {
      setCreateLoading(true);
      const { data } = await api.put(
        `/affiliates/prospect_cards/${values.id}`,
        {
          data: {
            attributes: {
              name: values?.contactName,
              companyName: values?.companyName,
              email: values?.email,
              phone: unmask(values?.phone),
              observations: values?.extraContent,
              prospectDetailAttributes: {},
            },
          },
        }
      );

      if (data) {
        console.log(data);
        setModal(false);
        clientForm.setValues(clientForm.initialValues);
        toast.success("Lead atualizado com sucesso!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar o card de lead.");
    } finally {
      setCreateLoading(false);
    }
  };

  const updateState = async (values) => {
    try {
      setCreateLoading(true);
      const { data } = await api.put(
        `/affiliates/prospect_cards/${values.id}`,
        {
          data: {
            attributes: {
              status: values.state,
            },
          },
        }
      );

      if (data) {
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar o card de lead.");
      throw Error();
    } finally {
      setCreateLoading(false);
    }
  };

  const newCRM = async (values) => {
    try {
      console.log(values);
      setCreateLoading(true);
      const { data } = await api.post("/affiliates/prospect_cards", {
        data: {
          attributes: {
            name: values?.contactName,
            companyName: values?.companyName,
            email: values?.email,
            phone: unmask(values?.phone),
            observations: values?.extraContent,
            prospectDetailAttributes: {},
          },
        },
      });

      if (data) {
        console.log(data);
        setCreateModal(false);
        clientForm.setValues(clientForm.initialValues);
        toast.success("Lead cadastrado com sucesso!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Ocorreu um erro ao tentar criar o card de lead.");
    } finally {
      setCreateLoading(false);
    }
  };

  useEffect(() => {
    getCRM();
  }, []);

  const CRMCard = ({ client, index }) => {
    return (
      <Draggable draggableId={String(client.id)} index={index}>
        {(provided) => {
          return (
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{
                backgroundColor: "white",
                marginBottom: "10px",
                borderRadius: "15px",
                display: "flex",
                minHeight: "50px",
                justifyContent: "space-around",
                alignItems: "center",
                border: "1px solid #102649",
                width: "100%",
                padding: "5px",
                cursor: "grab",
                ":hover": {
                  opacity: "50%",
                },
                flexDirection: "column",
                position: "relative",
              }}
              onClick={() => {
                setModal(true);
                setTempPhone(client?.phone);
                setStepModal("lead");
                clientForm.setValues(client);
                getPrompts(client?.prospectDetails);
              }}
            >
              {client?.registered ? (
                <div
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "2px",
                    cursor: "pointer",
                  }}
                  title={client.registered ? "Registrado" : "Não registrado"}
                >
                  {client.registered ? (
                    <HowToReg sx={{ color: "#102649" }} />
                  ) : (
                    <Person sx={{ color: "red" }} />
                  )}
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "85%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "15%",
                        justifyContent: "center",
                      }}
                    >
                      <Store color="#102649" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "70%",
                        justifyContent: "flex-start",
                        overflow: "hidden",
                      }}
                    >
                      {client.companyName}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "15%",
                        justifyContent: "center",
                      }}
                    >
                      <Person color="#102649" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "70%",
                        justifyContent: "flex-start",
                        overflow: "hidden",
                      }}
                    >
                      {client.contactName}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "15%",
                        justifyContent: "center",
                      }}
                    >
                      <Email color="#102649" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "70%",
                        justifyContent: "flex-start",
                        overflow: "hidden",
                      }}
                    >
                      {trimString(client.email, 27)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "15%",
                    justifyContent: "center",
                  }}
                >
                  <ArrowForward
                    color="#102649"
                    style={{ width: "35px", height: "35px" }}
                  />
                </div>
              </div>
            </Box>
          );
        }}
      </Draggable>
    );
  };

  const CRMSection = ({ title, children }) => (
    <div>
      <Box
        sx={{
          display: "flex",
          top: "10%",
          bgcolor: "white",
          width: isMobileDevice() ? "80vw" : "24%",
          // width: "24%",
          minWidth: isMobileDevice() ? "auto" : "350px",
          //height: "45%",
          flexDirection: "column",
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "white",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
            borderBottom: "1px solid #102649",
            backgroundColor: "#102649",
          }}
        >
          <div className="titleCrm2">{title}</div>
        </Box>
        <StrictModeDroppable droppableId={title}>
          {(provided) => {
            // console.log(provided);
            // console.log("teste");
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#102649",
                  minHeight: "500px",
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "500px",
                    backgroundColor: "white",
                    border: "2px solid #102649",
                    borderRadius: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      padding: "10px",
                      width: "100%",

                      // borderLeft: "1px solid #102649",
                      // borderRight: "1px solid #102649",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      {children}
                      {provided.placeholder}
                    </div>
                  </Box>
                </div>
              </div>
            );
          }}
        </StrictModeDroppable>
      </Box>
    </div>
  );

  return (
    <AuthorizedLayout>
      {loading ? (
        // <Typography variant="h6">Carregando... </Typography>
        <Loader size={150} />
      ) : (
        <div style={{ margin: "24px" }}>
          <Box
            // sx={{
            //   display: "flex",
            //   top: "10%",
            //   bgcolor: "white",
            //   flexDirection: "column",
            //   alignSelf: "center",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   boxShadow: 10,
            //   width: "100%",
            //   paddingTop: 3,
            // }}
            className="containerCRms"
          >
            <button
              endIcon={<Add />}
              onClick={() => {
                setCreateModal(true);
              }}
              className="buttonMoreLead"
            >
              Cadastrar novo Lead
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-evenly",
                padding: "10px",
              }}
            >
              <DragDropContext
                // onDragStart={() => console.log("start")}
                onDragEnd={(result) => handleDrop(result)}
              >
                <CRMSection title={"Prospecção"}>
                  {clients &&
                    clients.length > 0 &&
                    clients.map((item, index) => {
                      if (item.state === "prospec")
                        return (
                          <CRMCard client={item} key={item.id} index={index} />
                        );
                      else return null;
                    })}
                </CRMSection>

                <CRMSection title={"Proposta"}>
                  {clients &&
                    clients.length > 0 &&
                    clients.map((item, index) => {
                      // console.log(item);
                      if (item.state === "proposal")
                        return (
                          <CRMCard client={item} key={item.id} index={index} />
                        );
                      else return null;
                    })}
                </CRMSection>

                <CRMSection title={"Negociação"}>
                  {clients &&
                    clients.length > 0 &&
                    clients.map((item, index) => {
                      if (item.state === "bargain")
                        return (
                          <CRMCard client={item} key={item.id} index={index} />
                        );
                      else return null;
                    })}
                </CRMSection>

                <CRMSection title={"Fechamento"}>
                  {clients &&
                    clients.length > 0 &&
                    clients.map((item, index) => {
                      if (item.state === "closure")
                        return (
                          <CRMCard client={item} key={item.id} index={index} />
                        );
                      else return null;
                    })}
                </CRMSection>
              </DragDropContext>
            </div>
          </Box>
        </div>
      )}
      {modal && (
        <Modal
          open
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setModal(false);
              setStepModal("lead");
              clientForm.setValues(clientForm.initialValues);
            }
          }}
        >
          <Box
            style={{
              background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
              borderRadius: "20px",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobileDevice() ? "90vw" : "700px",
              overflowY: "auto",
              height: "95vh",
              p: 5,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {(stepModal === "prompt" || stepModal === "bottrial") && (
                  <IconButton
                    onClick={() => {
                      setStepModal("lead");
                    }}
                    style={{ position: "absolute", top: "5px", left: "5px" }}
                  >
                    <ArrowBack style={{ color: "#102649" }} />
                  </IconButton>
                )}
                <div className="titleCrm">
                  {stepModal === "lead"
                    ? "Detalhamento do Lead"
                    : stepModal === "prompt"
                    ? "Gerenciamento dos Prompts"
                    : "Enviar para Demonstração"}
                </div>
                <IconButton
                  onClick={() => {
                    setModal(false);
                    setStepModal("lead");
                    clientForm.setValues(clientForm.initialValues);
                  }}
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                >
                  <Close style={{ color: "#102649" }} />
                </IconButton>
              </div>
              {stepModal === "lead" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="containerAllINputsCrm">
                    {/* <InputWithLabel
                    label="Nome da Empresa"
                    value={"companyName"}
                    form={clientForm}
                    helperText="Insira o nome da empresa"
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                    // className="containerInput"
                  /> */}

                    <div className="containerInputCrm">
                      <div className="title">Nome da Empresa</div>
                      <div className="subTitleInput">
                        Insira o nome da empresa
                      </div>
                      <input
                        className="inputCRm"
                        type="text"
                        name="companyName"
                        onChange={clientForm.handleChange}
                        onBlur={clientForm.handleBlur}
                        value={clientForm.values.companyName}
                        autoComplete="new-password"
                      />
                      {clientForm.touched.companyName &&
                      clientForm.errors.companyName ? (
                        <div>{clientForm.errors.companyName}</div>
                      ) : null}
                    </div>
                    {/* 
                  <InputWithLabel
                    label="Nome do Contato"
                    value={"contactName"}
                    form={clientForm}
                    helperText="Insira o nome do seu contato na empresa"
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  /> */}

                    <div className="containerInputCrm">
                      <div className="title">Nome do Contato</div>
                      <div className="subTitleInput">
                        Insira o nome do seu contato na empresa
                      </div>
                      <input
                        className="inputCRm"
                        type="text"
                        name="contactName"
                        onChange={clientForm.handleChange}
                        onBlur={clientForm.handleBlur}
                        value={clientForm.values.contactName}
                        autoComplete="new-password"
                      />
                      {clientForm.touched.contactName &&
                      clientForm.errors.contactName ? (
                        <div>{clientForm.errors.contactName}</div>
                      ) : null}
                    </div>
                    {/* <InputWithLabel
                    label="E-mail"
                    value={"email"}
                    form={clientForm}
                    helperText="Insira o email de contato do lead"
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  /> */}

                    <div className="containerInputCrm">
                      <div className="title">E-mail</div>
                      <div className="subTitleInput">
                        Insira o email de contato do lead
                      </div>
                      <input
                        className="inputCRm"
                        type="email"
                        name="email"
                        onChange={clientForm.handleChange}
                        onBlur={clientForm.handleBlur}
                        value={clientForm.values.email}
                        autoComplete="new-password"
                      />
                      {clientForm.touched.email && clientForm.errors.email ? (
                        <div>{clientForm.errors.email}</div>
                      ) : null}
                    </div>
                    {/* <InputWithLabel
                    label="Telefone de Contato"
                    value={"phone"}
                    form={clientForm}
                    helperText="Digite o telefone de contato do lead"
                    variant="outlined"
                    mask={
                      tempPhone?.replace(/\s/g, "").length > 13
                        ? "(99) 9 9999-9999"
                        : "(99) 9999-99999"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={logo}
                            alt="test"
                            style={{ width: "35px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  /> */}

                    <div className="containerInputCrm">
                      <div className="title">Telefone de Contato</div>
                      <div className="subTitleInput">
                        Digite o telefone de contato do lead
                      </div>
                      <InputMask
                        className="inputCRm"
                        mask={
                          tempPhone?.replace(/\s/g, "").length > 13
                            ? "(99) 9 9999-9999"
                            : "(99) 9999-99999"
                        }
                        name="phone"
                        onChange={clientForm.handleChange}
                        onBlur={clientForm.handleBlur}
                        value={clientForm.values.phone}
                        autoComplete="new-password"
                      >
                        {(inputProps) => <input {...inputProps} type="text" />}
                      </InputMask>
                      {clientForm.touched.phone && clientForm.errors.phone ? (
                        <div>{clientForm.errors.phone}</div>
                      ) : null}
                    </div>

                    {/* <InputWithLabel
                    label="Observações"
                    value={"extraContent"}
                    form={clientForm}
                    helperText="Insira qualquer informação adicional a respeito do lead ou do andamento do processo"
                    variant="outlined"
                    multiline={true}
                    minRows={6}
                    maxRows={6}
                    style={{ marginBottom: "10px" }}
                  /> */}

                    <div className="containerInputCrm">
                      <div className="title">Observações</div>
                      <div className="subTitleInput">
                        Insira qualquer informação adicional a respeito do lead
                        ou do andamento do processo
                      </div>
                      {/* <textarea
                      className="inputCRm"
                      type="text"
                      name="extraContent"
                      onChange={clientForm.handleChange}
                      onBlur={clientForm.handleBlur}
                      value={clientForm.values.extraContent}
                      autoComplete="new-password"
                      rows={5}
                    /> */}

                      <textarea
                        className="inputCRm"
                        type="text"
                        name="extraContent"
                        onChange={clientForm.handleChange}
                        onBlur={clientForm.handleBlur}
                        value={clientForm.values.content}
                        rows="10"
                        style={{ height: "100%" }}
                      />
                      {clientForm.touched.extraContent &&
                      clientForm.errors.extraContent ? (
                        <div>{clientForm.errors.extraContent}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="containerButonsCrm">
                    <button
                      disabled={false}
                      onClick={() => setStepModal("bottrial")}
                      className="buttonMOre"
                    >
                      Enviar para Demonstração
                    </button>
                    <button
                      disabled={false}
                      onClick={() => setStepModal("prompt")}
                      className="buttonMOre"
                    >
                      Gerenciar Prompts
                    </button>
                    <button
                      disabled={saveLoading}
                      onClick={() => clientForm.submitForm()}
                      className="buttonSend"
                    >
                      SALVAR
                    </button>
                  </div>
                </div>
              )}
              {stepModal === "bottrial" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      textAlign={"center"}
                      style={{ marginBottom: "25px" }}
                      fontSize={18}
                    >
                      {
                        "Deseja carregar o Bot de Demonstração com os prompts do lead selecionado?"
                      }
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      style={{ marginBottom: "25px" }}
                      fontSize={18}
                    >
                      {
                        "O comportamento do atendente mudará de acordo com as configurações inseridas, as conversas anteriores serão perdidas e a contagem de tokens será reiniciada."
                      }
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={{
                          height: "35px",
                          marginRight: "10px",
                          p: 2,
                          m: 1,
                          alignSelf: "center",
                        }}
                        color="warning"
                        variant="outlined"
                        onClick={() => {
                          setModal(false);
                          setStepModal("lead");
                        }}
                      >
                        <Typography color={"orange"} variant={"h8"}>
                          {"Cancelar"}
                        </Typography>
                      </Button>
                      <Button
                        sx={{
                          height: "35px",
                          p: 2,
                          m: 1,
                          alignSelf: "center",
                          marginRight: "10px",
                        }}
                        variant="outlined"
                        disabled={false}
                        onClick={() => handleTrial()}
                      >
                        <Typography color={"#102649"} variant={"h8"}>
                          {"Confirmar"}
                        </Typography>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {stepModal === "prompt" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "25px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      width: "100%",
                      marginBottom: "25px",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        width: "20%",
                        backgroundColor:
                          section === "identity" ? "#102649" : "#e6e9ed",
                        borderRadius: 25,
                        color: section === "identity" ? "#e6e9ed" : null,
                        ":hover": {
                          backgroundColor: "#566986",
                          color: "#e6e9ed",
                        },
                      }}
                      onClick={() => setSection("identity")}
                    >
                      Identidade e Comunicação
                    </Button>
                    <Button
                      sx={{
                        width: "20%",
                        backgroundColor:
                          section === "attendant" ? "#102649" : "#e6e9ed",
                        borderRadius: 25,
                        color: section === "attendant" ? "#e6e9ed" : null,
                        ":hover": {
                          backgroundColor: "#566986",
                          color: "#e6e9ed",
                        },
                      }}
                      onClick={() => setSection("attendant")}
                    >
                      Informações do Negócio
                    </Button>
                    <Button
                      sx={{
                        width: "20%",
                        backgroundColor:
                          section === "goals" ? "#102649" : "#e6e9ed",
                        borderRadius: 25,
                        color: section === "goals" ? "#e6e9ed" : null,
                        ":hover": {
                          backgroundColor: "#566986",
                          color: "#e6e9ed",
                        },
                      }}
                      onClick={() => setSection("goals")}
                    >
                      Produtos e Serviços
                    </Button>
                    <Button
                      sx={{
                        width: "20%",
                        backgroundColor:
                          section === "strategy" ? "#102649" : "#e6e9ed",
                        borderRadius: 25,
                        color: section === "strategy" ? "#e6e9ed" : null,
                        ":hover": {
                          backgroundColor: "#566986",
                          color: "#e6e9ed",
                        },
                      }}
                      onClick={() => setSection("strategy")}
                    >
                      Estratégia de Atendimento
                    </Button>
                  </div>

                  {section === "identity" ? (
                    <Grid container spacing={1} sx={{ height: "100%" }}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InputWithLabel
                          label="Nome do/a Atendente"
                          value={"nameAttendant"}
                          form={form}
                          helperText="Insira o nome do atendente responsável por interações com os clientes"
                          variant="outlined"
                          // multiline={true}
                        />
                        {/* <InputWithLabel
                          label="Tom e voz"
                          value={"toneVoice"}
                          form={form}
                          helperText="Escolha o estilo e a atitude da comunicação que refletem a personalidade do negócio"
                          variant="outlined"
                          multiline={true}
                        /> */}
                        <SelectWithLabel
                          label="Tom e voz"
                          variant="outlined"
                          helperText="Escolha o estilo e a atitude da comunicação que refletem a personalidade do negócio"
                          form={form}
                          value={"toneVoice"}
                          renderValue={(selected) => selected.join(", ")}
                          onChange={(event) => handleChange(event, "toneVoice")}
                          multiple
                        >
                          {toneArray.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                <Checkbox
                                  checked={
                                    form.values.toneVoice.indexOf(item) > -1
                                  }
                                />
                                <ListItemText>{item}</ListItemText>
                              </MenuItem>
                            );
                          })}
                        </SelectWithLabel>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <SelectWithLabel
                          label={"Idioma de Resposta"}
                          variant="outlined"
                          helperText="Idioma que o atendente usará para responder o cliente"
                          form={form}
                          value={"preferentialLanguage"}
                          style={{ marginBottom: "25px" }}
                        >
                          <MenuItem value={"Português Brasileiro"}>
                            Português Brasileiro
                          </MenuItem>
                          <MenuItem value={"Inglês"}>Inglês</MenuItem>
                          <MenuItem value={"Japonês"}>Japonês</MenuItem>
                          <MenuItem value={"Mandarim"}>Mandarim</MenuItem>
                          <MenuItem value={"Hindi"}>Hindi</MenuItem>
                          <MenuItem value={"Espanhol"}>Espanhol</MenuItem>
                        </SelectWithLabel>
                        {form?.values?.toneVoice.includes("Outro") && (
                          <InputWithLabel
                            label="Descrição do tom e voz"
                            value={"toneVoiceExtra"}
                            form={form}
                            helperText="Ex: Enfatiza linguajar empresarial, fala de forma característica de alguma região, etc."
                            variant="outlined"
                            multiline={true}
                          />
                        )}
                        {/* <InputWithLabel
                          label="Público-alvo"
                          value={"targetAudience"}
                          form={form}
                          helperText="Defina o grupo de clientes que você deseja alcançar com a comunicação"
                          variant="outlined"
                          multiline={true}
                        /> */}
                      </Grid>
                    </Grid>
                  ) : section === "attendant" ? (
                    <Grid container spacing={1} sx={{ height: "100%" }}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InputWithLabel
                          label="Nome Fantasia do Negócio"
                          value={"companyName"}
                          form={form}
                          helperText={
                            "Digite o nome que o atendente usará para se referir ao seu negócio"
                          }
                          variant="outlined"
                        />
                        <InputWithLabel
                          label="Campo de atuação do Negócio"
                          value={"servedRegion"}
                          form={form}
                          helperText="Ex: Serviços de acompanhamento escolar, Serviços financeiros, consultoria nutricional"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InputWithLabel
                          label="Nicho do Negócio"
                          value={"companyNiche"}
                          form={form}
                          helperText="Especifique a área de atuação do seu negócio para melhor direcionamento"
                          variant="outlined"
                        />
                        <InputWithLabel
                          label="Site do Negócio"
                          value={"companyContact"}
                          form={form}
                          helperText="Informe o endereço web do seu negócio, caso já possua um site"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  ) : section === "strategy" ? (
                    <Grid container spacing={1} sx={{ height: "100%" }}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InputWithLabel
                          label="Canais de Comunicação"
                          value={"marketingChannels"}
                          form={form}
                          helperText="Liste os canais e seus respectivos links para promover seu negócio e interagir com os clientes"
                          variant="outlined"
                          multiline={true}
                        />
                        <SelectWithLabel
                          label={"Proposta a fazer ao cliente"}
                          variant="outlined"
                          helperText="Escolha os objetivos do atendente durante a conversa com o cliente"
                          form={form}
                          value={"companyObjectives"}
                          renderValue={(selected) => selected.join(", ")}
                          onChange={(event) =>
                            handleChange(event, "companyObjectives")
                          }
                          multiple
                        >
                          {optionsArray.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                <Checkbox
                                  checked={
                                    form.values.companyObjectives.indexOf(
                                      item
                                    ) > -1
                                  }
                                />
                                <ListItemText>{item}</ListItemText>
                              </MenuItem>
                            );
                          })}
                        </SelectWithLabel>

                        {/* {form?.values?.companyObjectives.includes(
                          "Agendar uma reunião"
                        ) && (
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <Button
                              sx={{ height: "35px", p: 2, marginTop: "10px" }}
                              variant="contained"
                              onClick={() => {
                                setModalSchedules(true);
                              }}
                            >
                              <Typography color={"white"} variant={"h7"}>
                                Configurações de Agendamento
                              </Typography>
                            </Button>
                          </div>
                        )} */}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InputWithLabel
                          label="Diferenciais chave"
                          value={"keyDifferentials"}
                          form={form}
                          helperText="Destaque os principais aspectos que tornam seu negócio único no mercado"
                          variant="outlined"
                          multiline={true}
                        />

                        {form?.values?.companyObjectives.includes("Outro") && (
                          <InputWithLabel
                            label="Descrição da proposta a fazer ao cliente"
                            value={"companyObjectivesExtra"}
                            form={form}
                            helperText="Ex: Agendar uma reunião online ou adquirir o email do cliente"
                            variant="outlined"
                            multiline={true}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ) : section === "goals" ? (
                    <Grid container spacing={1} sx={{ height: "100%" }}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InputWithLabel
                          label="Produtos do Negócio"
                          value={"companyProducts"}
                          form={form}
                          helperText="Descreva os produtos ou itens distintos que seu negócio disponibiliza no mercado"
                          variant="outlined"
                          multiline={true}
                        />
                        <InputWithLabel
                          label="Catálogo de Produtos/Serviços"
                          value={"catalogLink"}
                          form={form}
                          helperText="Insira o link do seu catálogo de produtos/serviços para o atendente fornecer ao cliente sempre que solicitado"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InputWithLabel
                          label="Serviços do Negócio"
                          value={"companyServices"}
                          form={form}
                          helperText="Liste os principais serviços ou soluções oferecidos pelo seu negócio"
                          variant="outlined"
                          multiline={true}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  <Button
                    sx={{ height: "35px", p: 2, marginTop: "25px" }}
                    variant="contained"
                    onClick={() => form.submitForm()}
                    disabled={saveLoading}
                  >
                    <Typography color={"white"} variant={"h6"}>
                      Salvar
                    </Typography>
                  </Button>
                </Box>
              )}
            </div>
          </Box>
        </Modal>
      )}

      {createModal && (
        <Modal
          open
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setCreateModal(false);

              clientForm.setValues(clientForm.initialValues);
            }
          }}
        >
          <Box
            style={{
              background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
              borderRadius: "20px",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobileDevice() ? "90vw" : "700px",
              overflowY: "auto",
              height: "95vh",
              p: 5,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {/* <Typography
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  color={"#102649"}
                  fontSize={20}
                  fontWeight={600}
                  textAlign={"center"}
                  marginBottom={"15px"}
                >
                  {stepModal === "lead"
                    ? "Detalhamento do Lead"
                    : stepModal === "prompt"
                    ? "Gerenciamento dos Prompts"
                    : "Enviar para Demonstração"}
                </Typography> */}

                <div className="titleCrm">
                  {stepModal === "lead"
                    ? "Detalhamento do Lead"
                    : stepModal === "prompt"
                    ? "Gerenciamento dos Prompts"
                    : "Enviar para Demonstração"}
                </div>
                <IconButton
                  onClick={() => {
                    setCreateModal(false);
                    clientForm.setValues(clientForm.initialValues);
                  }}
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                >
                  <Close style={{ color: "#102649" }} />
                </IconButton>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="containerAllINputsCrm">
                  {/* <InputWithLabel
                    label="Nome da Empresa"
                    value={"companyName"}
                    form={clientForm}
                    helperText="Insira o nome da empresa"
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                    // className="containerInput"
                  /> */}

                  <div className="containerInputCrm">
                    <div className="title">Nome da Empresa</div>
                    <div className="subTitleInput">
                      Insira o nome da empresa
                    </div>
                    <input
                      className="inputCRm"
                      type="text"
                      name="companyName"
                      onChange={clientForm.handleChange}
                      onBlur={clientForm.handleBlur}
                      value={clientForm.values.companyName}
                      autoComplete="new-password"
                    />
                    {clientForm.touched.companyName &&
                    clientForm.errors.companyName ? (
                      <div>{clientForm.errors.companyName}</div>
                    ) : null}
                  </div>
                  {/* 
                  <InputWithLabel
                    label="Nome do Contato"
                    value={"contactName"}
                    form={clientForm}
                    helperText="Insira o nome do seu contato na empresa"
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  /> */}

                  <div className="containerInputCrm">
                    <div className="title">Nome do Contato</div>
                    <div className="subTitleInput">
                      Insira o nome do seu contato na empresa
                    </div>
                    <input
                      className="inputCRm"
                      type="text"
                      name="contactName"
                      onChange={clientForm.handleChange}
                      onBlur={clientForm.handleBlur}
                      value={clientForm.values.contactName}
                      autoComplete="new-password"
                    />
                    {clientForm.touched.contactName &&
                    clientForm.errors.contactName ? (
                      <div>{clientForm.errors.contactName}</div>
                    ) : null}
                  </div>
                  {/* <InputWithLabel
                    label="E-mail"
                    value={"email"}
                    form={clientForm}
                    helperText="Insira o email de contato do lead"
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  /> */}

                  <div className="containerInputCrm">
                    <div className="title">E-mail</div>
                    <div className="subTitleInput">
                      Insira o email de contato do lead
                    </div>
                    <input
                      className="inputCRm"
                      type="email"
                      name="email"
                      onChange={clientForm.handleChange}
                      onBlur={clientForm.handleBlur}
                      value={clientForm.values.email}
                      autoComplete="new-password"
                    />
                    {clientForm.touched.email && clientForm.errors.email ? (
                      <div>{clientForm.errors.email}</div>
                    ) : null}
                  </div>
                  {/* <InputWithLabel
                    label="Telefone de Contato"
                    value={"phone"}
                    form={clientForm}
                    helperText="Digite o telefone de contato do lead"
                    variant="outlined"
                    mask={
                      tempPhone?.replace(/\s/g, "").length > 13
                        ? "(99) 9 9999-9999"
                        : "(99) 9999-99999"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={logo}
                            alt="test"
                            style={{ width: "35px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  /> */}

                  <div className="containerInputCrm">
                    <div className="title">Telefone de Contato</div>
                    <div className="subTitleInput">
                      Digite o telefone de contato do lead
                    </div>
                    <InputMask
                      className="inputCRm"
                      mask={
                        tempPhone?.replace(/\s/g, "").length > 13
                          ? "(99) 9 9999-9999"
                          : "(99) 9999-99999"
                      }
                      name="phone"
                      onChange={clientForm.handleChange}
                      onBlur={clientForm.handleBlur}
                      value={clientForm.values.phone}
                      autoComplete="new-password"
                    >
                      {(inputProps) => <input {...inputProps} type="text" />}
                    </InputMask>
                    {clientForm.touched.phone && clientForm.errors.phone ? (
                      <div>{clientForm.errors.phone}</div>
                    ) : null}
                  </div>

                  {/* <InputWithLabel
                    label="Observações"
                    value={"extraContent"}
                    form={clientForm}
                    helperText="Insira qualquer informação adicional a respeito do lead ou do andamento do processo"
                    variant="outlined"
                    multiline={true}
                    minRows={6}
                    maxRows={6}
                    style={{ marginBottom: "10px" }}
                  /> */}

                  <div className="containerInputCrm">
                    <div className="title">Observações</div>
                    <div className="subTitleInput">
                      Insira qualquer informação adicional a respeito do lead ou
                      do andamento do processo
                    </div>
                    {/* <textarea
                      className="inputCRm"
                      type="text"
                      name="extraContent"
                      onChange={clientForm.handleChange}
                      onBlur={clientForm.handleBlur}
                      value={clientForm.values.extraContent}
                      autoComplete="new-password"
                      rows={5}
                    /> */}

                    <textarea
                      className="inputCRm"
                      type="text"
                      name="extraContent"
                      onChange={clientForm.handleChange}
                      onBlur={clientForm.handleBlur}
                      value={clientForm.values.content}
                      rows="10"
                      style={{ height: "100%" }}
                    />
                    {clientForm.touched.extraContent &&
                    clientForm.errors.extraContent ? (
                      <div>{clientForm.errors.extraContent}</div>
                    ) : null}
                  </div>
                </div>

                <div
                  className="containerButonsCrm"
                  style={{ justifyContent: "center" }}
                >
                  <button
                    disabled={saveLoading}
                    onClick={() => clientForm.submitForm()}
                    className="buttonSend"
                  >
                    SALVAR
                  </button>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    sx={{
                      height: "35px",
                      p: 2,
                      m: 1,
                      alignSelf: "center",
                    }}
                    variant="contained"
                    disabled={loading}
                    onClick={() => clientForm.submitForm()}
                  >
                    <Typography color={"white"} variant={"h6"}>
                      {"SALVAR"}
                    </Typography>
                  </Button>
                </div> */}
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </AuthorizedLayout>
  );
};
