import axios from "axios";

export const baseURL = () => {
  const env =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_URL
      : process.env.REACT_APP_DEV_URL;

  return env;
};

const api = axios.create({
  baseURL: `${baseURL()}api/v1`,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  // },
});

export const googleAuth = axios.create({
  baseURL: `${baseURL()}auth/google_oauth2`,
});

export const devEnv = false;

export default api;
