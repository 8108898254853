import { InfoOutlined } from "@mui/icons-material";
import { InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import ReactInputMask from "react-input-mask";

export const InputWithLabel = ({
  label,
  value,
  form,
  disabled = false,

  validate = false,

  mask = "",
  maskChar = "",

  helperText = "",

  multiline = false,

  variant = "standard",

  ...props
}) => (
  <>
    {mask ? (
      <>
        <Typography
          style={{ marginLeft: "2px" }}
          fontSize={16}
          fontWeight={600}
          color={"#539d65"}
        >
          {label}
        </Typography>
        <Typography
          style={{ marginLeft: "2px", marginBottom: "5px" }}
          fontSize={14}
          color={"#566986"}
        >
          {helperText}
        </Typography>
        <ReactInputMask
          mask={mask}
          value={form.values[value]}
          onChange={(e) => form.setFieldValue(`${value}`, e.target.value)}
          onBlur={() => {
            form.setFieldTouched(`${value}`, true);
          }}
          maskChar={maskChar}
          disabled={disabled}
        >
          {() => (
            <TextField
              variant={variant}
              sx={{
                marginBottom: "25px",
              }}
              value={form.values[value]}
              multiline={multiline}
              minRows={4}
              maxRows={4}
              disabled={disabled}
              {...props}
            />
          )}
        </ReactInputMask>
      </>
    ) : (
      <>
        <Typography
          style={{ marginLeft: "2px" }}
          fontSize={16}
          fontWeight={600}
          color={"#539d65"}
        >
          {label}
        </Typography>
        <Typography
          style={{ marginLeft: "2px", marginBottom: "5px" }}
          fontSize={14}
          color={"#566986"}
        >
          {helperText}
        </Typography>
        <TextField
          variant={variant}
          sx={{
            marginBottom: "25px",
          }}
          value={form.values[value]}
          onChange={(e) => form.setFieldValue(`${value}`, e.target.value)}
          onBlur={() => {
            form.setFieldTouched(`${value}`, true);
          }}
          multiline={multiline}
          minRows={4}
          maxRows={4}
          disabled={disabled}
          {...props}
        />
      </>
    )}

    {validate && form.errors[value] && form.touched[value] && (
      <Typography
        style={{ marginBottom: "10px", marginTop: "-15px" }}
        color={"red"}
      >
        {form.errors[value]}
      </Typography>
    )}
  </>
);
