import React, { useState, useContext, useEffect } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import { CheckRounded, ContentCopyRounded, TaskAlt } from "@mui/icons-material";
import api from "../../../utils/api";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import iconMensage from "../../../assets/Vector (5).png";
import iconUsers from "../../../assets/Vector (4).png";
import iconWarning from "../../../assets/Warning.png";
import arrow from "../../../assets/Vector.png";

import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [icon, setIcon] = useState(<ContentCopyRounded />);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const getFirstName = (fullName) => {
    if (!fullName) return ""; // Verifica se a string é vazia ou null
    return fullName.split(" ")[0];
  };

  const getMetrics = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/affiliates/dashboard`);

      if (data) {
        setReports(data.data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetrics();
  }, []);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleClick = async () => {
    setIcon(<CheckRounded />);

    toast.success("Link copiado com sucesso!");

    await delay(10000);

    setIcon(<ContentCopyRounded />);
  };

  function NumberTransition(props) {
    const [value, setValue] = useState(0);
    const [moneyType, setMoneyType] = useState(false);

    useEffect(() => {
      const { TARGET_NUMBER, MONEY } = props;
      setMoneyType(MONEY);
      let interval;
      const step = Math.ceil(TARGET_NUMBER / 100);
      interval = setInterval(() => {
        if (shouldAnimate) {
          if (value + step >= TARGET_NUMBER) {
            clearInterval(interval);
            setValue(TARGET_NUMBER);
          } else {
            setValue((prevValue) => prevValue + step);
          }
        } else {
          clearInterval(interval);
        }
      }, 10);

      return () => {
        clearInterval(interval);
      };
    }, [props, value]);

    return (
      <div className="titleTotal">
        {moneyType ? `R$ ${value},00` : value || 0}
      </div>
    );
  }

  return (
    <AuthorizedLayout>
      <div className="containerDashboard">
        <div className="containerLeft">
          <div className="containerHeader" style={{ marginBottom: "20px" }}>
            <span className="title">{`Olá novamente, ${getFirstName(
              user.name
            )}!  👋`}</span>
            <span className="text">
              {/* Aqui está um resumo da sua assistente virtual: */}
              Confira o que você perdeu enquanto estava fora:
            </span>
          </div>

          <div
            style={{ border: "1px solid #7fda98"}}
          ></div>

          <div className="containerHeader linkInvite">
            <div className="title">
              Convide novos usuários para a plataforma!
            </div>
            <div className="text">
              Abaixo está o seu link de afiliado para divulgação:
            </div>
            <button
              onClick={() => {
                handleClick();
                navigator.clipboard.writeText(user?.affiliateUrl);
              }}
              className="link"
            >
              {user?.affiliateUrl}
            </button>

            <div
              style={{ color: "#A7A7A7", margin: "8px 0", fontSize: "0.9rem" }}
            >
              Clique no link acima para copiá-lo!
            </div>
          </div>
        </div>

        <div className="line"></div>

        <div className="containerRigh">
          <div className="containerHeader">
            <div className="title">Análise de vendas</div>
          </div>
          <div className="containerInfosLeadsSends">
            <div className="containerInfo">
              <div className="textImg">
                <AttachMoneyIcon sx={{ color: "#7fda98", fontSize: "60px" }} />
                {reports?.salesAnalysis?.closing || 0}
              </div>
              <div>Fechados</div>
            </div>

            <div className="containerInfo">
              <div className="textImg">
                <AttachMoneyIcon sx={{ color: "#7fda98", fontSize: "60px" }} />
                {reports?.salesAnalysis?.financial || 0}
              </div>
              <div>Financeiro</div>
            </div>
          </div>
          <div className="containerHeader">
            <div className="title">Leads</div>
          </div>
          <div className="containerInfosLeadsSends">
            <div className="containerInfo">
              <div className="textImg">
                <img src={iconUsers} alt="users" />
                <NumberTransition TARGET_NUMBER={reports?.salesFunnel?.new} />
              </div>
              <div>Novos Lead</div>
            </div>

            <div className="containerInfo">
              <div className="textImg">
                <TaskAlt sx={{ fontSize: "60px", color: "#7fda98" }} />
                <NumberTransition
                  TARGET_NUMBER={reports?.salesFunnel?.closed}
                />
              </div>
              <div>Leads fechados</div>
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Dashboard;
