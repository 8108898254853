import React, { useContext, useState } from "react";
import { useFormik } from "formik";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";

import robo from "../../../assets/robo 1.png";
import arrow from "../../../assets/Vector.png";

import "./Support.css";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { toast } from "react-toastify";

const Support = () => {
  const { user } = useContext(AuthContext);
  const [supportLoading, setSupportLoading] = useState(false);

  const supportForm = useFormik({
    initialValues: {
      email: user?.email || "",
      subject: "",
      content: "",
    },

    // validationSchema: yup.object({
    //   money: yup.number("Deve ser um número"),
    //   ratio: yup.number("Deve ser um número").required("Campo obrigatório"),
    // }),

    onSubmit: (values) => sendSupport(values),
  });

  const sendSupport = async (values) => {
    setSupportLoading(true);
    try {
      const { data } = await api.post("/affiliates/support/send_mail", {
        data: {
          attributes: {
            contact: values.email,
            subject: values.subject,
            body: values.concent,
          },
        },
      });

      if (data) {
        //console.log(data);
        toast.success(
          "Mensagem enviada com sucesso! Nossa equipe entrará em contato com você em breve."
        );
        supportForm.setValues(supportForm.initialValues);
      }
    } catch (error) {
      //console.log(error);
      toast.error(
        "Ocorreu um erro tentar enviar a mensagem de suporte, tente novamente mais tarde."
      );
    } finally {
      setSupportLoading(false);
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerImage">
        <div className="containerSuporte">
          <div className="title">Entre em contato conosco!</div>
          <div style={{ lineHeight: "20px" }}>
            <div className="subTitle">
              Está com alguma dúvida? Tem alguma sugestão?
            </div>
            <div className="subTitle">Entre em contato conosco!</div>
          </div>

          <form className="containerCard">
            <div className="card">
              <div className="containerInput">
                <div className="title">E-mail</div>
                <div className="subTitle">
                  Insira seu melhor E-mail para contato
                </div>
                <input
                  className="inputSuporte"
                  type="email"
                  name="email"
                  onChange={supportForm.handleChange}
                  onBlur={supportForm.handleBlur}
                  value={supportForm.values.email}
                />
                {supportForm.touched.email && supportForm.errors.email ? (
                  <div>{supportForm.errors.email}</div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Assunto</div>
                <div className="subTitle">
                  Descreva brevemente o que deseja tratar conosco
                </div>
                <input
                  className="inputSuporte"
                  type="text"
                  name="subject"
                  onChange={supportForm.handleChange}
                  onBlur={supportForm.handleBlur}
                  value={supportForm.values.subject}
                />
                {supportForm.touched.subject && supportForm.errors.subject ? (
                  <div>{supportForm.errors.subject}</div>
                ) : null}
              </div>
            </div>

            <div className="line"></div>

            <div className="card">
              <div className="containerInput" style={{ height: "100%" }}>
                <div className="title">Mensagem</div>
                <div className="subTitle">
                  Detalhe que tipo de suporte precisa
                </div>
                <textarea
                  className="inputStyle"
                  type="text"
                  name="content"
                  onChange={supportForm.handleChange}
                  onBlur={supportForm.handleBlur}
                  value={supportForm.values.content}
                  rows="10"
                  style={{ height: "100%" }}
                />
                {supportForm.touched.content && supportForm.errors.content ? (
                  <div>{supportForm.errors.content}</div>
                ) : null}
              </div>
            </div>
          </form>

          <div className="containerButtonsSumary" style={{ marginTop: "20px" }}>
            <button
              className="buttonNextSuporte"
              disabled={supportLoading}
              onClick={() => supportForm.handleSubmit()}
            >
              {supportLoading ? (
                "Enviando..."
              ) : (
                <>
                  Enviar
                  <img src={arrow} alt="arrow" />
                </>
              )}
            </button>
          </div>
        </div>

        <img src={robo} alt="robo" className="robo" />
      </div>
    </AuthorizedLayout>
  );
};

export default Support;
