import React, { useContext, useEffect } from "react";
import NewPlanContext from "../Context/NewPlanContext";
import { useNavigate } from "react-router-dom";

import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";
import InputMask from "react-input-mask";

import iconCard from "../../../../assets/CreditCard.png";
import arrow from "../../../../assets/Vector.png";

import "./Step2.css";

const Step2 = () => {
  const navigate = useNavigate();
  const { step1Form, step2Form, saveCard } = useContext(NewPlanContext);

  useEffect(() => {
    if (step1Form.values.title === "Plano Gratuito") {
      navigate("/financeiro/step3"); 
    } else if (step1Form.values.title === "") {
      navigate("/financeiro/step1"); 
    }
  }, [navigate, step1Form.values.title]);

  return (
    <AuthorizedLayout>
      <div className="containerCredCard">
        <div className="title">
          <img src={iconCard} alt="card" />
          OPÇÕES DE PAGAMENTO
        </div>
        <div className="subTitle">
          Insira os dados do seu Cartão de Crédito:
        </div>

        <form className="containerCard">
          <div className="card">
            <div className="containerInput">
              <div className="title">Nome do Titular do Cartão</div>
              <input
                className="inputCredCard"
                type="text"
                name="cardFullName"
                onChange={step2Form.handleChange}
                onBlur={step2Form.handleBlur}
                value={step2Form.values.cardFullName}
              />
              {step2Form.touched.cardFullName &&
              step2Form.errors.cardFullName ? (
                <div>{step2Form.errors.cardFullName}</div>
              ) : null}
            </div>

            <div className="containerInput">
              <div className="title">Número do Cartão</div>
              <InputMask
                className="inputCredCard"
                mask="**** **** **** 9999"
                name="cardNumber"
                onChange={step2Form.handleChange}
                onBlur={() => {
                  step2Form.setFieldTouched("cardNumber", true);
                }}
                value={step2Form.values.cardNumber}
              >
                {(inputProps) => <input {...inputProps} type="text" />}
              </InputMask>
              {step2Form.touched.cardNumber && step2Form.errors.cardNumber ? (
                <div>{step2Form.errors.cardNumber}</div>
              ) : null}
            </div>
          </div>

          <div className="line"></div>

          <div className="card">
            <div className="containerInput">
              <div className="title">Validade</div>
              <InputMask
                className="inputCredCard"
                mask="99/99"
                name="cardExpirationDate"
                onChange={step2Form.handleChange}
                onBlur={step2Form.handleBlur}
                value={step2Form.values.cardExpirationDate}
              >
                {(inputProps) => <input {...inputProps} type="text" />}
              </InputMask>
              {step2Form.touched.cardExpirationDate &&
              step2Form.errors.cardExpirationDate ? (
                <div>{step2Form.errors.cardExpirationDate}</div>
              ) : null}
            </div>

            <div className="containerInput">
              <div className="title">Código de Segurança</div>
              <InputMask
                className="inputCredCard"
                mask="999"
                name="cardCVV"
                onChange={step2Form.handleChange}
                onBlur={step2Form.handleBlur}
                value={step2Form.values.cardCVV}
              >
                {(inputProps) => <input {...inputProps} type="text" />}
              </InputMask>
              {step2Form.touched.cardCVV && step2Form.errors.cardCVV ? (
                <div>{step2Form.errors.cardCVV}</div>
              ) : null}
            </div>
          </div>
        </form>

        <div className="containerButtonsSumary">
          <div
            onClick={() => navigate("/financeiro/step1")}
            className="buttonBackCRedCard"
          >
            <img src={arrow} alt="arrow" />
            Anterior
          </div>

          <div onClick={step2Form.handleSubmit} className="buttonNextCRedCard">
            {saveCard ? (
              "Carregando..."
            ) : (
              <>
                Próximo
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Step2;
