// import { Box, Button, Grid, Typography } from "@mui/material";
// import MUIDataTable from "mui-datatables";
// import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
// import api from "../../../utils/api";
// import { Loader } from "../../../components/Loader";
// import moment from "moment";

// export const SalesFinance = () => {
//   const [transactions, setTransactions] = useState([]);
//   const [metrics, setMetrics] = useState({});
//   const [loadingMetrics, setLoadingMetrics] = useState(true);

//   const [tablePage, setTablePage] = useState(0);

//   const columns = [
//     {
//       name: "id",
//       label: "ID",
//       options: {
//         filter: false,
//       },
//     },
//     {
//       name: "partner",
//       label: "Parceiro",
//       options: {
//         filter: true,
//         filterOptions: {
//           fullWidth: true,
//         },
//       },
//     },
//     {
//       name: "value",
//       label: "Valor",
//       options: {
//         filter: false,
//         customBodyRender: (_value, { rowData }) => {
//           if (rowData[3] === "Saída") {
//             return (
//               <Typography fontSize={14} color={"red"}>
//                 - R$ {Number(_value).toFixed(2)}
//               </Typography>
//             );
//           } else {
//             return (
//               <Typography fontSize={14} color={"#539d65"}>
//                 + R$ {Number(_value).toFixed(2)}
//               </Typography>
//             );
//           }
//         },
//       },
//     },
//     {
//       name: "type",
//       label: "Tipo de Transação",
//       options: {
//         filter: true,
//         filterOptions: {
//           fullWidth: true,
//         },
//       },
//     },
//     {
//       name: "date",
//       label: "Data e Hora",
//       options: {
//         filter: false,
//         sort: false,
//       },
//     },
//   ];

//   const getMetrics = async () => {
//     setLoadingMetrics(true);
//     try {
//       const { data } = await api.get("/affiliates/revenues");

//       api.get(`/affiliates/dashboard`);
//       if (data) {
//         setMetrics(data.data);

//         setTransactions(
//           data?.data?.salesAnalysis?.revenues.map((item) => {
//             return {
//               id: item?.partner?.id,
//               partner: item?.partner?.name || "Não Informado",
//               value: item?.value / 100,
//               type: item?.partner_transaction?.type,
//               date: moment(item?.createdAt).format("DD/MM/YYYY hh:mm"),
//             };
//           })
//         );
//       }
//       setLoadingMetrics(false);
//     } catch (error) {
//       //console.log(error);
//       toast.error("Não foi possível carregar as métricas financeiras");
//     } finally {
//       setLoadingMetrics(false);
//     }
//   };

//   const changePage = async (page) => {
//     setTablePage(page);
//   };

//   useEffect(() => {
//     getMetrics();
//   }, []);

//   return (
//     <AuthorizedLayout>
//       {loadingMetrics ? (
//         <Loader size={150} />
//       ) : (
//         <div style={{ margin: "24px" }}>
//           <Box
//             sx={{
//               display: "flex",
//               top: "10%",
//               bgcolor: "white",
//               minWidth: "650px",
//               //height: "45%",
//               flexDirection: "column",
//               alignSelf: "center",
//               justifyContent: "center",
//               alignItems: "center",
//               boxShadow: 10,
//               p: 4,
//               marginBottom: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 bgcolor: "white",
//                 width: "100%",
//                 justifyContent: "center",
//                 alignItems: "flex-start",
//               }}
//             >
//               <Typography
//                 sx={{
//                   margin: 0,
//                   fontFamily: [
//                     '"Roboto"',
//                     '"Helvetica"',
//                     '"Arial"',
//                     "sans-serif",
//                   ],
//                   fontWeight: 500,
//                   fontSize: "1.25rem",
//                   lineHeight: 1.6,
//                   letterSpacing: "0.0075em",
//                   alignSelf: "flex-start",
//                   marginTop: "-15px",
//                   marginBottom: "15px",
//                 }}
//               >
//                 Relatório Financeiro
//               </Typography>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   bgcolor: "white",
//                   width: "50%",
//                   //maxWidth: "525px",
//                   minWidth: "525px",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   //   border: "2px solid #539d65",
//                   //   backgroundColor: "#6fc7b2",
//                   //   borderRadius: "30px 30px 30px 30px",
//                   //   minHeight: "175px",
//                   marginLeft: "25px",
//                 }}
//               >
//                 {loadingMetrics ? (
//                   <Loader size={150} />
//                 ) : (
//                   <Grid container spacing={1} sx={{ height: "100%" }}>
//                     <Grid
//                       item
//                       xs={6}
//                       sx={{
//                         display: "flex",
//                         flexDirection: "column",
//                       }}
//                     >
//                       <Typography
//                         sx={{
//                           margin: 0,
//                           fontFamily: [
//                             '"Roboto"',
//                             '"Helvetica"',
//                             '"Arial"',
//                             "sans-serif",
//                           ],
//                           fontWeight: 600,
//                           fontSize: "1rem",
//                           lineHeight: 1.6,
//                           letterSpacing: "0.0075em",
//                           alignSelf: "flex-start",
//                           // color: "white",
//                           marginLeft: "30px",
//                         }}
//                       >
//                         Fechamento
//                       </Typography>
//                       <Typography
//                         sx={{
//                           margin: 0,
//                           fontFamily: [
//                             '"Roboto"',
//                             '"Helvetica"',
//                             '"Arial"',
//                             "sans-serif",
//                           ],
//                           fontWeight: 600,
//                           fontSize: "1.5rem",
//                           lineHeight: 1.6,
//                           letterSpacing: "0.0075em",
//                           alignSelf: "flex-start",
//                           color: "#539d65",
//                           marginLeft: "30px",
//                         }}
//                       >
//                         {`R$ ${
//                           metrics?.salesAnalysis?.closing
//                             ? (metrics?.salesAnalysis?.closing / 100).toFixed(2)
//                             : 0.0
//                         }`}
//                       </Typography>
//                     </Grid>
//                     <Grid
//                       item
//                       xs={6}
//                       sx={{
//                         display: "flex",

//                         flexDirection: "column",
//                       }}
//                     >
//                       <Typography
//                         sx={{
//                           margin: 0,
//                           fontFamily: [
//                             '"Roboto"',
//                             '"Helvetica"',
//                             '"Arial"',
//                             "sans-serif",
//                           ],
//                           fontWeight: 600,
//                           fontSize: "1rem",
//                           lineHeight: 1.6,
//                           letterSpacing: "0.0075em",
//                           alignSelf: "flex-start",
//                           // color: "white",
//                           marginLeft: "30px",
//                         }}
//                       >
//                         Financeiro
//                       </Typography>
//                       <Typography
//                         sx={{
//                           margin: 0,
//                           fontFamily: [
//                             '"Roboto"',
//                             '"Helvetica"',
//                             '"Arial"',
//                             "sans-serif",
//                           ],
//                           fontWeight: 600,
//                           fontSize: "1.5rem",
//                           lineHeight: 1.6,
//                           letterSpacing: "0.0075em",
//                           alignSelf: "flex-start",
//                           color: "#539d65",
//                           marginLeft: "30px",
//                           marginBottom: "25px",
//                         }}
//                       >
//                         {`R$ ${
//                           metrics?.salesAnalysis?.financial
//                             ? (metrics?.salesAnalysis?.financial / 100).toFixed(
//                                 2
//                               )
//                             : 0.0
//                         }`}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 )}
//               </Box>
//               <Button
//                 // onClick={() =>
//                 //   navigate("/tokens", {
//                 //     state: {
//                 //       metrics,
//                 //     },
//                 //   })
//                 // }
//                 sx={{
//                   marginLeft: "30px",
//                   borderRadius: "10px",
//                   marginTop: "20px",
//                 }}
//                 variant="contained"
//               >
//                 Comprar mais tokens
//               </Button>
//             </Box>
//           </Box>
//           <MUIDataTable
//             title={
//               <Typography
//                 variant="h6"
//                 style={{ display: "flex", flexDirection: "row" }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     width: "100%",
//                   }}
//                 >
//                   Histórico de transações
//                 </div>
//                 {loadingMetrics && (
//                   <Loader size={32} style={{ justifyContent: "flex-start" }} />
//                 )}
//               </Typography>
//             }
//             data={transactions}
//             columns={columns}
//             options={{
//               serverSide: true,
//               onTableChange: (action, tableState) => {
//                 switch (action) {
//                   case "changePage":
//                     changePage(tableState.page);
//                     break;
//                   default:
//                   //console.log("action not handled.");
//                 }
//               },
//               page: tablePage,
//               pagination: true,
//               rowsPerPage: 10,
//               rowsPerPageOptions: [],
//               count:
//                 transactions?.length === 10
//                   ? 10 * (tablePage + 1) + 1
//                   : 10 * tablePage + transactions?.length,
//               selectableRows: "none",
//               tableBodyHeight: "65vh",
//               sortFilterList: false,
//               filter: true,
//               textLabels: {
//                 body: {
//                   noMatch: "Nenhum registro encontrado.",
//                   toolTip: "Ordenar",
//                   columnHeaderTooltip: (column) =>
//                     `Ordenar por ${column.label}`,
//                 },
//                 pagination: {
//                   next: "Página Seguinte",
//                   previous: "Página Anterior",
//                   rowsPerPage: "Linhas por página:",
//                   displayRows: "de",
//                 },
//                 toolbar: {
//                   search: "Pesquisar",
//                   downloadCsv: "Fazer download de CSV",
//                   print: "Imprimir",
//                   viewColumns: "Ver Colunas",
//                   filterTable: "Filtrar Tabela",
//                 },
//                 filter: {
//                   all: "Todos",
//                   title: "FILTROS",
//                   reset: "Reiniciar",
//                 },
//                 viewColumns: {
//                   title: "Mostrar Colunas",
//                   titleAria: "Mostrar/Ocultar Colunas da Tabela",
//                 },
//                 selectedRows: {
//                   text: "linha(s) selecionada(s)",
//                   delete: "Apagar",
//                   deleteAria: "Apagar linhas selecionadas",
//                 },
//               },
//             }}
//           />
//         </div>
//       )}
//     </AuthorizedLayout>
//   );
// };

import React, { useState, useContext, useEffect } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import TableTransactions from "./Components/TableTransactions";

import arrow from "../../../assets/Vector.png";
import iconCHat from "../../../assets/CreditCard.png";
import "./FinancePartner.css";
import { Loader } from "../../../components/Loader";

const SalesFinance = () => {
  const { checkList } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingCards, setLoadingCards] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [cards, setCards] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [metrics, setMetrics] = useState({});

  const getTransactions = async () => {
    setLoading(true);
    setLoadingTable(true);
    try {
      const { data } = await api.get("/affiliates/revenues");
      if (data) {
        // console.log(data);
        setTransactions(
          data?.data?.salesAnalysis?.revenues.map((item) => {
            return {
              id: item?.partner?.id,
              partner: item?.partner?.name || "Não Informado",
              amount: item?.value / 100,
              tType:
                item?.partner_transaction?.type === "PaymentSubscription"
                  ? "Assinatura"
                  : item?.partner_transaction?.type,
              date: moment(item?.createdAt).format("DD/MM/YYYY hh:mm"),
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados das transações.");
    } finally {
      setLoadingTable(false);
      setLoading(false);
    }
  };

  const getCards = async () => {
    setLoadingCards(true);
    try {
      const { data: cards } = await api.get(`/affiliates/credit_cards`);

      if (cards) {
        setCards(
          cards.data.map((item, index) => {
            return {
              id: item.id,
              position: index,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados dos cartões.");
    } finally {
      setLoadingCards(false);
    }
  };

  const getSubscription = async () => {
    setLoadingSubscription(true);
    try {
      // const { data: subscriptionData } = await api.get(
      //   `/partners/payment_subscriptions/`
      // );

      // const { data: plansData } = await api.get(`partners/payment_plans`);

      const [data1, data2] = await Promise.all([
        api.get(`/affiliates/dashboard`),
        api.get(`/affiliates/credit_cards`),
      ]);

      const tokenData = data1.data?.data?.montly_usage;
      const cardsData = data2.data;

      if (tokenData && cardsData) {
        if (tokenData) {
          setMetrics(tokenData);
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados da assinatura.");
    } finally {
      setLoadingSubscription(false);
    }
  };

  useEffect(() => {
    getCards();
    getTransactions();
    getSubscription();
  }, []);

  const isCardExpired = (expiryDate) => {
    if (expiryDate) {
      const [year, month] = expiryDate?.split("-").map(Number);

      const expiry = new Date(year, month);

      const now = new Date();
      return expiry < now;
    }
    return "";
  };

  const getMaskedCard = (card) => {
    const subCardLastDigits = card?.substr(-4) || undefined;

    const maskedCard = subCardLastDigits
      ? `xxxx xxxx xxxx ${subCardLastDigits}`
      : "";

    return maskedCard;
  };

  return (
    <AuthorizedLayout>
      {loadingTable ? (
        <Loader size={100} />
      ) : (
        <div className="containerFinancePartner">
          <div className="headerFinancePartner">
            <div className="cardScore">
              <div className="containerText">
                <div className="title">
                  Tokens restantes do seu plano mensal:{" "}
                  <strong>
                    {" "}
                    {Number(metrics?.remaining_token).toLocaleString() || "0"}
                  </strong>
                  /{" "}
                  {(
                    Number(metrics?.plan_tokens || 0) +
                    Number(metrics?.extra_tokens || 0)
                  ).toLocaleString()}
                </div>
                <div className="bar">
                  <div className="fill">
                    <span
                      className="leads"
                      style={{
                        width: `${(
                          ((metrics?.remaining_token || 0) /
                            ((metrics?.plan_tokens || 0) +
                              (metrics?.extra_tokens || 0))) *
                          100
                        ).toFixed(2)}%`,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate("/vendas/tokens", {
                    state: {
                      metrics,
                    },
                  })
                }
                className="buttonNextCRedCard"
                style={{ alignSelf: "flex-start" }}
              >
                Comprar mais tokens
                <img src={arrow} alt="arrow" className="imgBUtonNex" />
              </div>
            </div>
          </div>

          <div className="containerSumary">
            <div className="containerBox">
              <div className="title">
                <img src={iconCHat} alt="icon" />
                Seu cartão cadastrado
              </div>
              <div className="containerCredCards">
                <div className="cardCredit">
                  <strong className="text">Bandeira</strong>
                  <div className="subtitle">{cards[0]?.brand}</div>
                </div>

                <div className="cardCredit">
                  <strong className="text">Número</strong>
                  <div className="subtitle">
                    {getMaskedCard(cards[0]?.number)}
                  </div>
                </div>

                <div className="cardCredit">
                  <strong className="text">Situação</strong>
                  <div className="subtitle">
                    {cards[0]?.brand
                      ? isCardExpired(cards[0]?.expiresAt)
                        ? "Expirado"
                        : "Válido"
                      : ""}
                  </div>
                </div>

                <div className="cardCredit">
                  <strong className="text">Renovação</strong>
                  <div className="subtitle">
                    {cards[0]?.brand && "Automática"}
                  </div>
                </div>
              </div>

              <div
                onClick={() => navigate("/vendas/financeiro/cartao-de-credito")}
                className="buttonNextCRedCard"
                style={{ alignSelf: "flex-end" }}
              >
                Cadastrar Cartão
                <img src={arrow} alt="arrow" />
              </div>
            </div>

            <TableTransactions
              transactions={transactions}
              loadingTable={loadingTable}
            />
          </div>
        </div>
      )}
    </AuthorizedLayout>
  );
};

export default SalesFinance;
