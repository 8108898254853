import React, { useState, useContext, useEffect, useRef } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../utils/api";
import { Loader } from "../../../components/Loader";
import Switch from "@mui/material/Switch";
import Step1 from "./Components/Step1";
import Step2 from "./Components/Step2";
import Step3 from "./Components/Step3";
import Step4 from "./Components/Step4";
import Step5 from "./Components/Step5";
import Step6 from "./Components/Step6";
import Step7 from "./Components/Step7";
import ChatHistory from "./Components/ChatHistory";

import iconMensage from "../../../assets/Vector (5).png";
import iconUsers from "../../../assets/Vector (4).png";

import "./Attendant.css";

const optionsArray = [
  "Agendar uma reunião",
  "Adquirir email do cliente",
  "Outro",
];

const toneArray = [
  "Respeitoso & Empático",
  "Animado & Engajador",
  "Profissional & Firme",
  "Atencioso & Calmo",
  "Encorajador & Motivador",
  "Compassivo & Compreensivo",
  "Divertido & Cativante",
  "Cortês & Gentil",
  "Solidário & Amigável",
  "Eficiente & Rápido",
  "Confiável & Preciso",
  "Assertivo & Decisivo",
  "Perspicaz & Observador",
  "Outro",
];

const daysArray = [
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
  "Domingo",
];

const menuArray = [
  "Dados Básicos",
  "",
  "Produtos e Serviços",
  "",
  "Estratégia de Atendimento",
  "",
  "Simulador do Atendente",
  "",
  "Vincular Contas",
  "",
  "Agendamento"
];

const Attendant = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { value } = location.state || {};
  const [viewClient, setViewClient] = useState(false);
  const [viewLead, setViewLead] = useState(true);

  const { user } = useContext(AuthContext);
  const [settings, setSettings] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [menuSelected, setMenuSelected] = useState(
    value || {
      menu: "Dados Básicos",
      index: 0,
    }
  );

  useEffect(() => {
    if (value) {
      setMenuSelected(value);
    }
  }, [value]);

  const form = useFormik({
    initialValues: {
      nameAttendant: "",
      companyName: "",
      companyNiche: "",
      servedRegion: "",
      companyProducts: "",
      companyServices: "",
      companyContact: "",
      companyObjectives: [],
      companyObjectivesExtra: "",
      marketingChannels: "",
      keyDifferentials: "",
      toneVoice: [],
      toneVoiceExtra: "",
      preferentialLanguage: "",
      catalogLink: "",
      connectedWithGoogle: false,
    },

    // validationSchema: yup.object({
    //   money: yup.number("Deve ser um número"),
    //   ratio: yup.number("Deve ser um número").required("Campo obrigatório"),
    // }),

    onSubmit: () => null,
  });

  const getSettings = async () => {
    try {
      const { data } = await api.get(
        `/affiliates/bot_configurations/actual_configuration`
      );

      if (data) {
        let valorExtra = "";
        const valoresFiltrados = data.data?.attributes?.companyObjectives
          ? Object.keys(data.data.attributes.companyObjectives).reduce(
              (acc, key) => {
                if (
                  optionsArray.includes(
                    data.data.attributes.companyObjectives[key]
                  )
                ) {
                  acc[key] = data.data.attributes.companyObjectives[key];
                } else {
                  acc[key] = "Outro";
                  valorExtra = data.data.attributes.companyObjectives[key];
                }

                return acc;
              },
              []
            )
          : [];

        let toneExtra = "";
        const toneVoiceFiltrados = data.data?.attributes?.toneVoice
          ? Object.keys(data.data.attributes.toneVoice).reduce((acc, key) => {
              if (toneArray.includes(data.data.attributes.toneVoice[key])) {
                acc[key] = data.data.attributes.toneVoice[key];
              } else {
                acc[key] = "Outro";
                toneExtra = data.data.attributes.toneVoice[key];
              }

              return acc;
            }, [])
          : [];
        form.setValues({
          ...data.data.attributes,
          companyObjectives: valoresFiltrados,
          companyObjectivesExtra: valorExtra,
          toneVoice: toneVoiceFiltrados,
          toneVoiceExtra: toneExtra,
          catalogLink: data?.data?.attributes?.catalogLink || "",
        });
        setSettings(data.data.attributes);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar as configurações do bot.");
    } finally {
    }
  };

  useEffect(() => {
    getSettings();
    // getScheduleSettings();
  }, []);

  const checkList =
    settings?.nameAttendant && settings?.companyName && settings?.companyNiche;

  const renderItemMenu = (name) => {
    switch (name) {
      case "Dados Básicos":
        return (
          <Step1
            form={form}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
          />
        );

      case "Produtos e Serviços":
        return (
          <Step3
            form={form}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
          />
        );
      case "Estratégia de Atendimento":
        return (
          <Step4
            form={form}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
          />
        );
      case "Simulador do Atendente":
        return (
          <Step5
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            menuSelected={menuSelected}
          />
        );
      case "Vincular Contas":
        return (
          <Step6
            form={form}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
          />
        );
      case "Agendamento":
        return (
          <Step7
            form={form}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
          />
        );
      default:
        <Step1
          form={form}
          saveLoading={saveLoading}
          setMenuSelected={setMenuSelected}
          checkList={checkList}
        />;
    }
  };

  const handleMenu = (item) => {
    if (item.menu === "Simulador do Atendente" && !checkList) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      setMenuSelected(item);
    }
  };

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  const scrollToSection1 = () => {
    section1Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleChangeClient = (event) => {
    setViewClient(event.target.checked);
  };

  const handleChangeLead = (event) => {
    setViewLead(event.target.checked);
  };

  return (
    <AuthorizedLayout>
      <div className="containerAttendantSales">
        <div className="containerTitleDIv" style={{ marginBottom: "20px" }}>
          <div className="sliderCOntainer">
            <div className="title">Lead</div>
            <Switch
              checked={viewLead}
              label={viewLead ? "Ocultar" : "Visualizar"}
              onChange={handleChangeLead}
            />
          </div>
          <div className="line"></div>
        </div>

        {viewLead && (
          <>
            <div className="cardChatHistory">
              {/* <div className="containerImg">
              <img src={iconUser} alt="usuario" className="iconUser" />
            </div> */}
              <div>
                <strong>Nome do Lead:</strong>{" "}
                {form?.values?.prospectCard?.name || "Não Informado"}
              </div>
              <div>
                <strong>Nome da Empresa:</strong>{" "}
                {form?.values?.prospectCard?.company_name || "Não Informado"}
              </div>
              <div>
                <strong>Email:</strong>{" "}
                {form?.values?.prospectCard?.email || "Não Informado"}
              </div>
            </div>

            <div className="containerMenu">
              {menuArray.map((item, index) => (
                <div
                  key={index}
                  className={`${item !== "" ? "menu" : "line"} ${
                    item === menuSelected.menu && "menuSelected"
                  } ${
                    (index === menuSelected.index - 1 ||
                      index === menuSelected.index + 1) &&
                    "none"
                  } ${
                    item === "Simulador do Atendente" &&
                    !checkList &&
                    "disabledMenus"
                  }`}
                  onClick={() => handleMenu({ menu: item, index: index })}
                >
                  {item}
                </div>
              ))}
            </div>

            <div className="contentWeb">
              {renderItemMenu(menuSelected.menu)}
            </div>

            <div className="contentMobile">
              <Step1
                form={form}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                scrollToSection1={scrollToSection1}
                checkList={checkList}
              />

              <Step3
                form={form}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                scrollToSection1={scrollToSection1}
                checkList={checkList}
              />

              <Step4
                form={form}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                section2Ref={section2Ref}
                scrollToSection1={scrollToSection1}
                checkList={checkList}
              />

              {checkList ? (
                <Step5
                  saveLoading={saveLoading}
                  setMenuSelected={setMenuSelected}
                  menuSelected={menuSelected}
                  section1Ref={section1Ref}
                />
              ) : (
                <div className="containerInfoSimulator" ref={section1Ref}>
                  <div className="titlePage" style={{ color: "#102649" }}>
                    Simulador do Atendente
                  </div>
                  <strong>Atenção:</strong>
                  <div>
                    Preencha o nome do (a) atendente, o nome fantasia do negócio
                    e o nicho do negócio para ter acesso ao simulador do
                    atendente!
                  </div>
                </div>
              )}

              <Step6
                form={form}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                scrollToSection1={scrollToSection1}
                checkList={checkList}
              />

              <Step7
                form={form}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                scrollToSection1={scrollToSection1}
                checkList={checkList}
              />
            </div>
          </>
        )}

        {menuSelected.menu !== "Simulador do Atendente" && (
          <>
            <div
              className="containerTitleDIv"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="sliderCOntainer">
                <div className="title">Cliente</div>
                <Switch
                  // control={<Switch viewClient />}
                  checked={viewClient}
                  label={viewClient ? "Ocultar" : "Visualizar"}
                  onChange={handleChangeClient}
                />
              </div>
              <div className="line"></div>
            </div>
            {viewClient ? <ChatHistory /> : ""}
          </>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default Attendant;
