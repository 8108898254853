import React from "react";
import "./LeadsGraph.css";
import moment from "moment";

const LeadsGraph = () => {
  const leadsData = [
    { month: "Jan", leads: 4, value: 1 },
    { month: "Fev", leads: 2, value: 2 },
    { month: "Mar", leads: 4, value: 3 },
    { month: "Abr", leads: 3, value: 4 },
    { month: "Mai", leads: 5, value: 5 },
    { month: "Jun", leads: 0, value: 6 },
    { month: "Jul", leads: 0, value: 7 },
    { month: "Ago", leads: 0, value: 8 },
    { month: "Set", leads: 0, value: 9 },
    { month: "Out", leads: 0, value: 10 },
    { month: "Nov", leads: 0, value: 11 },
    { month: "Dez", leads: 0, value: 12 },
  ];

  var mesAtual = moment().month() + 1;

  const totalLeads = leadsData
    .filter((item) => item.value <= mesAtual)
    .reduce((sum, data) => sum + data.leads, 0);

  return (
    <div className="leads-graph">
      <div className="header">
        <span className="text">Gráfico de novos leads / mês:</span>
        <span className="text">
          Ano: <strong>2024</strong>
        </span>
      </div>
      <div className="graph-container">
        {leadsData
          .filter((item) => item.value <= mesAtual)
          .map((data, index) => {
            // const height = data.leads === 1 ? data.leads * 15 : data.leads * 10;
            const height = (data.leads / totalLeads) * 168;
            return (
              <div key={index} className="bar">
                <div className="fill">
                  <span className="leads" style={{ height: `${height}px` }}>
                    {data.leads}
                  </span>
                </div>
                <div className="label">{data.month}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LeadsGraph;
