import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../utils/api";
import iconCheck from "../../../../assets/Check.png";
import arrowIcon from "../../../../assets/Vector (7).png";
import "./CheckList.css";
import { toast } from "react-toastify";
import { faker } from "@faker-js/faker";
import { Box, Button, Modal, Typography } from "@mui/material";

const CheckList = () => {
  const { user, checkComplete, checkLoading, updatePartnerDetail } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [isUrlSelected, setIsUrlSelected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [modalWarning, setModalWarning] = useState(false);

  const handleOptionChange = (e) => {
    setIsUrlSelected(e.target.value === "url");
    setError("");
  };

  const optionsArray = [
    "Agendar uma reunião",
    "Adquirir email do cliente",
    "Outro",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      if (isUrlSelected) {
        if (!websiteUrl) {
          setError("Por favor, insira a URL do seu site.");
          setLoading(false);
          return;
        }
        // Enviar a URL para o serviço de terceiros
        const { data } = await api.post(
          "https://primary-production-5d91.up.railway.app/webhook/atendente",
          {
            url: handleUrl(websiteUrl),
          }
        );

        if (data?.analysisResult) {
          // Supondo que a resposta contenha as informações necessárias para a configuração
          await checkComplete(user.id);
          // Redirecionar para a tela de chat de demonstração
          console.log("Resposta do serviço:", data?.analysisResult);

          updateSettings({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
            companyContact: handleUrl(websiteUrl),
          });
        } else {
          setError("Falha ao configurar o bot. Por favor, tente novamente.");
          console.log("Resposta do serviço:", data);
        }
      } else {
        if (!companyDescription.trim()) {
          setError("Por favor, insira a descrição da sua empresa.");
          setLoading(false);
          return;
        }

        // Enviar a descrição para outro serviço de terceiros
        const { data } = await api.post(
          "https://primary-production-5d91.up.railway.app/webhook/atendente-text",
          {
            description: companyDescription.trim(),
          }
        );

        if (data?.analysisResult) {
          // Supondo que a resposta contenha as informações necessárias para a configuração
          await checkComplete(user.id);
          // Redirecionar para a tela de chat de demonstração
          console.log("Resposta do serviço:", data?.analysisResult);

          if (
            data?.analysisResult?.nome_fantasia &&
            data?.analysisResult?.nicho
          ) {
            updateSettings({
              companyName: data?.analysisResult?.nome_fantasia,
              companyNiche: data?.analysisResult?.nicho,
              companyProducts: data?.analysisResult?.produtos,
              companyServices: data?.analysisResult?.servicos,
              servedRegion: data?.analysisResult?.campo_atuacao,
              keyDifferentials: data?.analysisResult?.diferenciais,
              nameAttendant: faker.person.firstName(),
              companyObjectives: data?.analysisResult?.objetivos,
              companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
            });
          } else {
            setLoading(false);
            // toast.error(
            //   "Descrição insuficiente. Por favor, elabore um descritivo mais detalhado a respeito de sua empresa ou serviço."
            // );
            setModalWarning(true);
          }
        } else {
          setError("Falha ao configurar o bot. Por favor, tente novamente.");
          console.log("Resposta do serviço:", data);
        }
      }
    } catch (err) {
      // Registrar o erro no console
      console.error("Erro ao configurar o bot:", err);
      setError("Ocorreu um erro. Por favor, tente novamente.");
      setLoading(false);
    } finally {
    }
  };

  const updateSettings = async (values) => {
    setLoading(true);

    try {
      let body = {
        data: {
          attributes: {
            nameAttendant: values?.nameAttendant,
            companyName: values?.companyName,
            companyNiche: values?.companyNiche,
            servedRegion: values?.servedRegion,
            companyServices: values?.companyServices?.join(", "),
            companyProducts: values?.companyProducts?.join(", "),
            companyContact: values.companyContact,
            // companyObjectives: values?.companyObjectives?.map((item) => {
            //   if (item === "Outro") {
            //     return values.companyObjectivesExtra;
            //   } else {
            //     return item;
            //   }
            // }),
            companyObjectives: [
              // values?.companyObjectives?.agendar_reuniao && optionsArray[0],
              // values?.companyObjectives?.adquirir_email && optionsArray[1],
              false,
              false,
              values?.companyObjectives?.outro &&
                values?.companyObjectives?.outro,
            ]?.filter(Boolean),
            attendantInstructions: values?.companyObjectives?.outro,
            // twitterXLink: values.twitterXLink,
            // youtubeLink: values.youtubeLink,
            // facebookLink: values.facebookLink,
            // instagramLink: values.instagramLink,
            key_differentials: values?.keyDifferentials?.join(", "),
            // tone_voice: values?.toneVoice?.map((item) => {
            //   if (item === "Outro") {
            //     return values.toneVoiceExtra;
            //   } else {
            //     return item;
            //   }
            // }),
            // preferentialLanguage: values.preferentialLanguage,
            // catalogLink: values.catalogLink,
          },
        },
      };

      if (!user?.partnerDetailId) {
        body.data.attributes.partnerId = user.id;
      }

      const { data } = user?.partnerDetailId
        ? await api.put(
            `partners/partner_details/${user.partnerDetailId}`,
            body
          )
        : await api.post(`partners/partner_details/`, body);

      if (data) {
        // Adding it's token to all requests for now on.
        toast.success("Configurações atualizadas com sucesso!");
        updatePartnerDetail(data);
        await checkComplete(user.id);
        navigate("/simulador");
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setLoading(false);
    }
  };

  const handleUrl = (value) => {
    if (value.startsWith("http://") || value.startsWith("https://")) {
      return value;
    } else {
      return "http://" + value;
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  return (
    <div className="containerCheckProfile">
      {modalWarning && (
        <Modal
          open
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setModalWarning(false);
            }
          }}
        >
          <Box
            style={{
              background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
              borderRadius: "20px",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobileDevice() ? 350 : 700,
              p: 2.5,
            }}
          >
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: [
                      '"Roboto"',
                      '"Helvetica"',
                      '"Arial"',
                      "sans-serif",
                    ],
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    alignSelf: "flex-start",
                    marginTop: "15px",
                    marginBottom: "15px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Typography fontSize={28} fontWeight={600} color={"red"}>
                    ATENÇÃO
                  </Typography>
                </Typography>

                <Typography
                  textAlign={"center"}
                  fontWeight={"600"}
                  style={{ marginBottom: "25px" }}
                  fontSize={20}
                >
                  {"Descrição insuficiente"}
                </Typography>
                <Typography
                  textAlign={"center"}
                  style={{ marginBottom: "25px" }}
                  fontSize={18}
                >
                  {
                    "Por favor, elabore um descritivo mais detalhado a respeito de sua empresa ou serviço."
                  }
                </Typography>

                <Button
                  sx={{
                    height: "35px",
                    p: 2,
                    m: 1,
                    alignSelf: "center",
                  }}
                  variant="contained"
                  onClick={() => {
                    setModalWarning(false);
                  }}
                >
                  <Typography color={"white"} variant={"h6"}>
                    {"OK, ENTENDI"}
                  </Typography>
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      <div className="containerHeader">
        <span className="title">Configure seu Assistente Virtual</span>
        <span className="text">
          Por favor, selecione uma das opções abaixo para configurar seu
          atendente virtual.
        </span>
      </div>

      <form onSubmit={handleSubmit} className="urlForm">
        <div className="optionSelection">
          <label className="custom-radio">
            <input
              type="radio"
              name="configOption"
              value="url"
              checked={isUrlSelected}
              onChange={handleOptionChange}
            />
            <span className="radio-btn"></span>
            Enviar URL do site
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name="configOption"
              value="description"
              checked={!isUrlSelected}
              onChange={handleOptionChange}
            />
            <span className="radio-btn"></span>
            Enviar descrição da empresa
          </label>
        </div>

        {isUrlSelected ? (
          <input
            // type="url"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            required
            className="urlInput"
          />
        ) : (
          <textarea
            placeholder="Digite a descrição da sua empresa"
            value={companyDescription}
            onChange={(e) => setCompanyDescription(e.target.value)}
            required
            className="descriptionInput"
            rows="4"
          ></textarea>
        )}

        {error && <div className="errorMessage">{error}</div>}
        <button type="submit" disabled={loading} className="submitButton">
          {loading ? "Processando..." : "Enviar"}
          {/* <img src={arrowIcon} alt="arrow icon" /> */}
        </button>
      </form>

      {loading && (
        <div className="progressScreen">
          <div className="spinner"></div>
          <span>Configurando seu bot...</span>
        </div>
      )}
    </div>
  );
};

export default CheckList;
