import React, { useContext } from "react";
import {
  Button,
  IconButton,
  Typography,
  MenuItem,
  Fade,
  Menu,
  Box,
} from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import {
  AppSettingsAlt,
  AttachMoney,
  ContactMail,
  EventAvailable,
  Home,
  Person,
  ScheduleSend,
  Settings,
  Store,
} from "@mui/icons-material";

import wallet from "../../assets/Wallet.png";
import userP from "../../assets/User.png";
import users from "../../assets/Users.png";
import headset from "../../assets/Headset.png";
import diadmonds from "../../assets/DiamondsFour.png";
import question from "../../assets/Question.png";
import iconNotifi from "../../assets/Vector (2).png";
import human from "../../assets/pngtree-users-vector-icon-png-image_3725294.jpg";
import iconUser from "../../assets/noAvatar.svg";
import arrowBotton from "../../assets/Vector (3).png";

import "./TopBar.css";

const color = "#77E191";

const TopBar = ({
  notificationsVisible,
  setNotificationsVisible,
  loading,
  notifications,
}) => {
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);
  const { id, hash } = useParams();
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const notificationsRead =
    notifications?.length > 0 &&
    notifications?.reduce(
      (acc, item) => (item.readed === false ? acc + 1 : acc),
      0
    );

  const checkWpp = user?.wppConnected ? true : false;
  const checkGoogle = user?.connectedWithGoogle ? true : false;

  function getBasePath(url) {
    const parts = url.split('/'); // Divide a URL em partes separadas por "/"
    
    // Retorna as três primeiras partes: ['', 'auto-mensagens', 'edite']
    return `${parts[0]}/${parts[1]}/${parts[2]}`;
  }

  const title = () => {
    switch (pathname) {
      case "/clientes":
        return { name: "Clientes", img: users };

      case "/dashboard":
        return { name: "Dashboard", img: diadmonds };

      case "/simulador":
        return { name: "Simulador do Atendente", img: headset };

      case "/vincular":
        return { name: "Vincular Contas", img: headset };

      case `/configuracoes`:
        return { name: "Configurações do Atendente", img: headset };

      case `/configuracoes/automatica`:
        return { name: "Configurações do Atendente", img: headset };

      case `/conta`:
        return { name: "Perfil", img: userP };

      case `/agendamento`:
        return {
          name: "Agendamento",
          img: <EventAvailable sx={{ color: color }} className="imgMenu" />,
        };


        case `/auto-mensagens`:
          return {
            name: "Auto Mensagens",
            img: <ScheduleSend sx={{ color: color }} className="imgMenu" />,
          };

          case `/auto-mensagens/edite/:id` :
            return {
              name: "Editar Auto Mensagens",
              img: <ScheduleSend sx={{ color: color }} className="imgMenu" />,
            };

            case `/auto-mensagens/create`:
              return {
                name: "Criar Auto Mensagens",
                img: <ScheduleSend sx={{ color: color }} className="imgMenu" />,
              };

      case `/chat/${id}`:
        return { name: "Clientes", img: users };

      case "/financeiro":
        return { name: "Financeiro", img: wallet };

      case "/financeiro/step1":
        return { name: "Financeiro", img: wallet };

      case "/financeiro/step2":
        return { name: "Financeiro", img: wallet };

      case "/financeiro/step3":
        return { name: "Financeiro", img: wallet };

      case "/financeiro/cartao-de-credito":
        return { name: "Financeiro", img: wallet };

      case "/planos":
        return "Planos de Assinatura";

      case `/planos/${hash}`:
        return "Planos de Assinatura";

      case "/tokens":
        return "Recarga de Tokens";

      case "/carrinho":
        return "Resumo da Compra";

      case "/admin/parceiros":
        return {
          name: "Parceiros",
          img: <Store sx={{ color: color }} />,
        };


      case "/admin/afiliados":
        return {
          name: "Afiliados",
          img: users,
        };

      case "/admin/financeiro":
        return {
          name: "Financeiro",
          img: <AttachMoney sx={{ color: color }} />,
        };

      case `/admin/parceiros/${id}`:
        return {
          name: "Clientes do Parceiro",
          img: <Person sx={{ color: color }} />,
        };

      case "/vendas/clientes":
        return {
          name: "Clientes",
          img: <Person sx={{ color: color }} />,
        };

      case "/vendas/crm":
        return {
          name: "CRM",
          img: <ContactMail sx={{ color: color }} />,
        };

      case "/vendas/dashboard":
        return {
          name: "Dashboard",
          img: <Home sx={{ color: color }} />,
        };

      case "/vendas/demo":
        return {
          name: "Bot de Demonstração",
          img: <AppSettingsAlt sx={{ color: color }} />,
        };


      case "/vendas/suporte":
        return { name: "Suporte", img: question };

      case "/suporte":
        return { name: "Suporte", img: question };

      case `/vendas/conta`:
        return {
          name: "Configurações de Conta",
          img: <Settings sx={{ color: color }} />,
        };

      case "/vendas/financeiro":
        return {
          name: "Financeiro",
          img: <AttachMoney sx={{ color: color }} />,
        };

      case "/vendas/financeiro/cartao-de-credito":
        return { name: "Financeiro", img: wallet };

      case "/vendas/tokens":
        return "Recarga de Tokens";

      default:
        return "";
    }
  };

  function getFirstName(fullName) {
    if (!fullName) return ""; // Verifica se a string é vazia ou null
    return fullName.split(" ")[0];
  }

  const isMaterialIcon = typeof title().img === "object";

  return (
    <div className="topbar">
      <div className="containerTitlePage">
        {title().img &&
          (isMaterialIcon ? (
            title().img
          ) : (
            <img src={title().img} alt={title().name} className="imgMenu" />
          ))}
        <div className="titleTop">{title().name || title()}</div>
      </div>
      <div className="containerRigthTop">
        <div className="containerStatusCheck">
          {user.userType !== "partner" ? null : (
            <Box
              sx={{
                cursor: "pointer",
                ":hover": {
                  opacity: "55%",
                },
              }}
              onClick={() =>
                navigate("/vincular", {
                  state: {
                    value: {
                      menu: "Vincular Contas",
                      index: 8,
                    },
                  },
                })
              }
              className={`${checkWpp}`}
            >
              <div className="cicle"></div>
              <div>WHATSAPP</div>
            </Box>
          )}

          {user.userType !== "partner" ? null : (
            <Box
              sx={{
                cursor: "pointer",
                ":hover": {
                  opacity: "55%",
                },
              }}
              onClick={() =>
                navigate("/vincular", {
                  state: {
                    value: {
                      menu: "Vincular Contas",
                      index: 8,
                    },
                  },
                })
              }
              className={`${checkGoogle}`}
            >
              <div className="cicle"></div>
              <div>CALENDÁRIO</div>
            </Box>
          )}
        </div>

        {user.userType !== "partner" ? null : (
          <div>
            <IconButton
              color="inherit"
              onClick={() => setNotificationsVisible(!notificationsVisible)}
              edge="start"
              style={{ color: "#fff" }}
            >
              {notificationsRead > 0 ? (
                <Typography
                  color={"white"}
                  fontSize={15}
                  fontWeight={600}
                  textAlign={"center"}
                  className="iconNotifica"
                  style={{
                    marginTop: -20,
                    marginRight: -20,
                  }}
                >
                  {notificationsRead > 9 ? "9+" : notificationsRead}
                </Typography>
              ) : null}

              {/* <Notifications fontSize="large" /> */}
              <img src={iconNotifi} alt="icon notificação" className="imgTOp" />
            </IconButton>
          </div>
        )}

        <img
          src={user?.avatar || iconUser}
          alt="user"
          className="userIMage"
          onClick={() =>
            user.userType !== "admin" &&
            navigate(
              user.userType === "partner"
                ? "/conta"
                : user.userType === "sales"
                ? "/vendas/conta"
                : ""
            )
          }
          style={user.userType === "admin" ? { cursor: "default" } : null}
        />
        <div
          style={{
            display: "flex",
            alignItems: "start",
            gap: "10px",
            flexDirection: "column",
            lineHeight: "10px",
          }}
        >
          <div
            className="nameText"
            onClick={() =>
              user.userType !== "admin" &&
              navigate(
                user.userType === "partner"
                  ? "/conta"
                  : user.userType === "sales"
                  ? "/vendas/conta"
                  : ""
              )
            }
            style={user.userType === "admin" ? { cursor: "default" } : null}
          >
            {getFirstName(user?.name)}
          </div>

          <div className="subText">{user?.currentPlan?.name || ""}</div>
          {/* <div
            id="fade-button"
            aria-controls={open ? "fade-menu" : user?.firstName}
            aria-haspopup="true"
            aria-expanded={open ? "true" : user?.firstName}
            onClick={handleClick}
          >
            <img
              src={arrowBotton}
              style={{ margin: "auto" }}
              alt="icon logout"
            />
          </div>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={signOut}>Sair</MenuItem>
          </Menu> */}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
