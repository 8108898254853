import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FormControl, NativeSelect, Tooltip } from "@mui/material";
import { Loader } from "../../../../components/Loader";
import Papa from "papaparse";

import iconArronw from "../../../../assets/Vector.png";
import iconLUpa from "../../../../assets/MagnifyingGlass.png";
import iconFilter from "../../../../assets/Funnel.png";
import iconExport from "../../../../assets/Export.png";

import "./TableTransactions.css";

const animatedComponents = makeAnimated();

const topArray = [
  { label: "ID", value: "id" },
  { label: "Valor", value: "amount" },
  { label: "Descrição", value: "description" },
  { label: "Status da Transação", value: "tStatus" },
  { label: "Data e Hora", value: "date" },
];

const TableTransactions = ({ transactions, loadingTable }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState("");

  const handleClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = transactions?.filter((item) =>
    item?.id.toString().includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const selectedsNamesFilter = filterKeys.map((item) => item.value);

  const generateCSV = (data) => {
    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      Valor:
        item?.type === "Saída"
          ? `- R$ ${Number(item?.amount).toFixed(2)}`
          : `+ R$ ${Number(item?.amount).toFixed(2)}`,
      Descricao: item?.description || "",
      Status_transacao: item?.tStatus || "",
      Data_Hora: item?.date,
    }));

    const csv = Papa.unparse(formattedData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  return (
    <div className="containerTableTransactions">
      <div
        className="containerInputsTableTransactions"
        style={{ display: transactions.length <= 0 && "none" }}
      >
        {typeFilter === "lupa" && (
          <input
            type="text"
            placeholder="Buscar por ID"
            value={searchTerm}
            onChange={handleSearchChange}
            className="inputSearch"
          />
        )}

        {typeFilter === "filter" && (
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={filterKeys}
            onChange={setFilterKeys}
            isMulti
            options={topArray}
          />
        )}
      </div>
      <div className="cardTableTransactions">
        <div className="header">
          {filterKeys?.map((value, idx) => (
            <div
              style={{
                width: value.label === "ID" && "100px",
                flex:
                  value.label === "ID"
                    ? "0.3"
                    : value.label === "Valor"
                    ? "0.8"
                    : value.label === "Status da Transação" && "1.2",
              }}
            >
              <div key={idx}>{value.label}</div>
            </div>
          ))}

          <div>
            <div className="containerIconFilter">
              {typeFilter === "lupa" ? (
                <button onClick={() => setTypeFilter("")}>X</button>
              ) : (
                <img
                  src={iconLUpa}
                  alt="icon"
                  onClick={() => setTypeFilter("lupa")}
                />
              )}
              <img
                src={iconExport}
                alt="icon"
                onClick={() => generateCSV(currentItems)}
              />

              {typeFilter === "filter" ? (
                <button onClick={() => setTypeFilter("")}>X</button>
              ) : (
                <img
                  src={iconFilter}
                  alt="icon"
                  onClick={() => setTypeFilter("filter")}
                />
              )}
            </div>
          </div>
        </div>

        {currentItems.length <= 0 && !loadingTable && (
          <div className="containerItens">
            <div className="client">
              <div>Nenhum pagamento foi efetuado.</div>
            </div>
          </div>
        )}

        {loadingTable ? (
          <Loader size={100} />
        ) : (
          <div className="containerItens">
            {currentItems?.map((item, idx) => (
              <div key={idx} className="client">
                <div
                  style={{
                    width: "100px",
                    backgroundColor: idx % 2 === 0 && "#FFFFFF",
                    borderRadius: "20px 0px 0px 20px",
                    flex: "0.3",
                  }}
                >
                  {selectedsNamesFilter.includes("id") && (
                    <Tooltip title={item?.id} className="containerItem">
                      <div>{item?.id}</div>
                    </Tooltip>
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: idx % 2 === 0 && "#FFFFFF",
                    flex: "0.8",
                  }}
                >
                  {selectedsNamesFilter.includes("amount") && (
                    <Tooltip title={item?.amount}>
                      <div className="containerName">
                        <div>
                          {item?.type === "Saída" ? (
                            <div style={{ color: "red" }}>
                              {" "}
                              - R$ {Number(item?.amount).toFixed(2)}
                            </div>
                          ) : (
                            <div>+ R$ {Number(item?.amount).toFixed(2)}</div>
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  )}
                </div>

                <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                  {selectedsNamesFilter.includes("description") && (
                    <Tooltip title={item?.description}>
                      <div>{item?.description}</div>
                    </Tooltip>
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: idx % 2 === 0 && "#FFFFFF",
                    flex: "1.2",
                  }}
                >
                  {selectedsNamesFilter.includes("tStatus") && (
                    <Tooltip title={item?.tStatus}>
                      <div>{item?.tStatus}</div>
                    </Tooltip>
                  )}
                </div>

                <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                  {selectedsNamesFilter.includes("date") && (
                    <Tooltip title={item?.date}>
                      <div>{item?.date}</div>
                    </Tooltip>
                  )}
                </div>

                <div
                  style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                ></div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="containerPagination">
        <div className="pagination">
          <button
            className="containerButton"
            onClick={() => handleClick("prev")}
            disabled={currentPage === 1}
            style={{ opacity: currentPage === 1 && "55%" }}
          >
            <img src={iconArronw} alt="icon" className="left" />
            <div>Anterior</div>
          </button>
          <strong>{` ${currentPage}-${totalPages} `}</strong>de{" "}
          <strong>{`${totalPages}`}</strong>
          <button
            className="containerButton"
            onClick={() => handleClick("next")}
            disabled={totalPages === 1}
            style={{ opacity: totalPages === 1 && "55%" }}
          >
            <div>Próxima</div>
            <img src={iconArronw} alt="icon" />
          </button>
        </div>

        <div
          className="totalPage"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="text">Linhas por página:</div>
          <div>
            {isMobileDevice() ? (
              <input
                type="text"
                value={itemsPerPage}
                onChange={handlePerPageChange}
                style={{ width: "70px", height: "25px" }}
              />
            ) : (
              <FormControl fullWidth>
                <NativeSelect
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                  <option value={30}>30</option>
                  <option value={35}>35</option>
                  <option value={40}>40</option>
                  <option value={45}>45</option>
                  <option value={50}>50</option>
                  <option value={80}>80</option>
                  <option value={100}>100</option>
                </NativeSelect>
              </FormControl>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableTransactions;
