import React, { useState, useContext, useEffect } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import TableTransactions from "./Components/TableTransactions";

import arrow from "../../../assets/Vector.png";
import iconCHat from "../../../assets/CreditCard.png";
import "./FinancePartner.css";
import { Loader } from "../../../components/Loader";

const FinancePartner = () => {
  const { checkList } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingCards, setLoadingCards] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [cards, setCards] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [metrics, setMetrics] = useState({});

  const getTransactions = async () => {
    setLoading(true);
    setLoadingTable(true);
    try {
      const { data } = await api.get(`partners/payment_transactions?page=1`);
      if (data) {
        // console.log(data);
        setTransactions(
          data.map((item) => {
            return {
              id: item.galaxPayId,
              description: item?.Subscription
                ? "Pagamento de Assinatura"
                : item?.Charge
                ? "Compra de Tokens"
                : "Não Informado",
              date: moment(item.createdAt).format("DD/MM/YYYY HH:mm"),
              type: "Saída",
              amount: item.value / 100,
              tStatus: handleStatus(item.status),
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados das transações.");
    } finally {
      setLoadingTable(false);
      setLoading(false);
    }
  };

  const handleStatus = (status) => {
    switch (status) {
      case "notSend":
        return "Ainda não enviada para operadora de Cartão";
      case "authorized":
        return "Autorizado";
      case "captured":
        return "Capturada na Operadora de Cartão";
      case "denied":
        return "Negada na Operadora de Cartão";
      case "reversed":
        return "Estornada na Operadora de Cartão";
      case "cancel":
        return "Cancelada manualmente";
      case "payExternal":
        return "Paga fora do sistema";
      case "cancelByContract":
        return "Cancelada ao cancelar a cobrança";
      case "free":
        return "Gratuito";
      default:
        return "Outro";
    }
  };

  const getCards = async () => {
    setLoadingCards(true);
    try {
      const { data: cards } = await api.get(`/partners/credit_cards`);

      if (cards) {
        setCards(
          cards.data.map((item, index) => {
            return {
              id: item.id,
              position: index,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados dos cartões.");
    } finally {
      setLoadingCards(false);
    }
  };

  const getSubscription = async () => {
    setLoadingSubscription(true);
    try {
      // const { data: subscriptionData } = await api.get(
      //   `/partners/payment_subscriptions/`
      // );

      // const { data: plansData } = await api.get(`partners/payment_plans`);

      const [data1, data2, data3] = await Promise.all([
        api.get(`partners/payment_subscriptions/last_active_subscription`),
        api.get(`partners/payment_plans`),
        api.get(`partners/credit_cards`),
      ]);

      const subscriptionData = data1.data;
      const plansData = data2.data;
      const cardsData = data3.data;

      // if (!checkList.activePlan) {
      //   navigate("/financeiro/step1");
      // }

      if (subscriptionData?.data !== null && plansData && cardsData) {
        const sub = plansData?.data.find(
          (item) =>
            Number(item.id) ===
            Number(subscriptionData?.data?.attributes?.paymentPlan.id)
        );

        // const subCard = cardsData?.data?.find(
        //   (item) =>
        //     Number(item.id) ===
        //     Number(subscriptionData?.data?.attributes?.creditCardId)
        // );

        console.log(cardsData);

        const subCardLastDigits = cardsData?.data[cardsData.data.length - 1]
          ?.attributes
          ? cardsData?.data[
              cardsData.data.length - 1
            ].attributes?.number.substr(-4)
          : undefined;

        const maskedCard = subCardLastDigits
          ? `xxxx xxxx xxxx ${subCardLastDigits}`
          : "";

        const payDate = moment().diff(
          moment(subscriptionData?.data?.attributes?.firstPayDayDate),
          "months"
        );

        if (subscriptionData) {
          const { data } = await api.get("partners/montly_usage_history");
          setMetrics(data?.data?.usageStatistics);
        }

        // console.log(subCard);

        setSubscription({
          name: sub?.attributes?.name,
          periodicity: sub?.attributes?.periodicity,
          planPricePayment: sub?.attributes?.planPricePayment,
          planPriceValue: sub?.attributes?.planPriceValue,
          payValidate: moment(
            subscriptionData?.data?.attributes?.firstPayDayDate
          ).add(payDate + 1, "months"),
          payDate: moment(subscriptionData?.data?.attributes?.firstPayDayDate),
          card: maskedCard,
          brand: cardsData?.data[cardsData.data.length - 1]?.attributes
            ? cardsData?.data[cardsData.data.length - 1]?.attributes?.brand
            : "",
          expiresAt: cardsData?.data[cardsData.data.length - 1]?.attributes
            ? cardsData?.data[cardsData.data.length - 1]?.attributes?.expiresAt
            : "",
        });
        // console.log(sub);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados da assinatura.");
    } finally {
      setLoadingSubscription(false);
    }
  };

  useEffect(() => {
    getCards();
    getTransactions();
    getSubscription();
  }, []);

  const isCardExpired = (expiryDate) => {
    if (expiryDate) {
      const [year, month] = expiryDate?.split("-").map(Number);

      const expiry = new Date(year, month);

      const now = new Date();
      return expiry < now;
    }
    return "";
  };

  const checkPLan = subscription?.name === "Plano Gratuito" ? true : false;
  return (
    <AuthorizedLayout>
      {loadingTable ? (
        <Loader size={100} />
      ) : (
        <div className="containerFinancePartner">
          <div className="headerFinancePartner">
            <div className="cardFinancePartner">
              <div className="title">{subscription?.name}</div>
              <div className="containerText" style={{ display: 'flex', alignItems :'center'}}>
                <strong>Data de contratação:</strong>{" "}
                {moment(subscription?.payDate).format("DD/MM/YYYY")}
                <span>{id}</span>
              </div>
              <div className="containerText"  style={{ display: 'flex', alignItems :'center'}}>
                {(
                  <>
                    <strong>Válido até:</strong>{" "}
                    {moment(subscription?.payValidate).format("DD/MM/YYYY")}
                  </>
                )}
              </div>

              <div
                onClick={() => navigate("/financeiro/step1")}
                className="buttonNextCRedCard"
                style={{ alignSelf: "flex-start" }}
              >
                {checkPLan ? "Adquirir Plano" : "Alterar Plano"}
                <img src={arrow} alt="arrow" className="imgBUtonNex" />
              </div>
            </div>

            <div className="cardScore">
              <div className="containerText">
                <div className="title">
                  Tokens consumidos do seu plano mensal:{" "}
                  <strong>
                    {" "}
                    {Number(metrics?.montlyTokensConsumed).toLocaleString() ||
                      "0"}
                  </strong>
                  /{" "}
                  {(
                    Number(metrics?.remainingTokens || 0) +
                    Number(metrics?.montlyTokensConsumed || 0)
                  ).toLocaleString()}
                </div>
                <div className="bar">
                  <div className="fill">
                    <span
                      className="leads"
                      style={{
                        width: `${(
                          ((metrics?.montlyTokensConsumed || 0) /
                            ((metrics?.montlyTokensConsumed || 0) +
                              (metrics?.remainingTokens || 0))) *
                          100
                        ).toFixed(2)}%`,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate("/tokens", {
                    state: {
                      metrics,
                    },
                  })
                }
                className="buttonNextCRedCard"
                style={{ alignSelf: "flex-start" }}
              >
                Comprar mais tokens
                <img src={arrow} alt="arrow" className="imgBUtonNex"/>
              </div>
            </div>
          </div>

          <div className="containerSumary">
            <div className="containerBox">
              <div className="title">
                <img src={iconCHat} alt="icon" />
                Seu cartão cadastrado
              </div>
              <div className="containerCredCards">
                <div className="cardCredit">
                  <strong className="text">Bandeira</strong>
                  <div className="subtitle">{subscription?.brand}</div>
                </div>

                <div className="cardCredit">
                  <strong className="text">Número</strong>
                  <div className="subtitle">{subscription?.card}</div>
                </div>

                <div className="cardCredit">
                  <strong className="text">Situação</strong>
                  <div className="subtitle">
                    {subscription?.brand
                      ? isCardExpired(subscription?.expiresAt)
                        ? "Expirado"
                        : "Válido"
                      : ""}
                  </div>
                </div>

                <div className="cardCredit">
                  <strong className="text">Renovação</strong>
                  <div className="subtitle">
                    {subscription?.brand && "Automática"}
                  </div>
                </div>

                <div className="cardCredit">
                  <strong className="text">Data da Renovação</strong>
                  <div className="subtitle">
                    {subscription?.brand && moment(subscription?.payValidate).format("DD/MM/YYYY")}
                  </div>
                </div>
              </div>

              <div
                onClick={() => navigate("/financeiro/cartao-de-credito")}
                className="buttonNextCRedCard"
                style={{ alignSelf: "flex-end", marginTop: '20px' }}
              >
                Cadastrar Cartão
                <img src={arrow} alt="arrow" style={{  width: '12px', height: '12px' }}/>
              </div>
            </div>

            <TableTransactions
              transactions={transactions}
              loadingTable={loadingTable}
            />
          </div>
        </div>
      )}
    </AuthorizedLayout>
  );
};

export default FinancePartner;
