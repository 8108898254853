import { InfoOutlined } from "@mui/icons-material";
import {
  FormControl,
  Select,
} from "@mui/material";
import { styled } from '@mui/material/styles';

const CustomSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});


export const NewSelectWithLabel = ({
  label,
  value,
  form,
  disabled = false,

  validate = false,

  helperText = "",

  variant = "standard",

  multiple = false,

  renderValue = null,

  style,

  onChange,

  children,

  ...props
}) => (
  <FormControl fullWidth>
    <CustomSelect
      disabled={disabled}
      value={form.values[value]}
      multiple={multiple}
      renderValue={renderValue}
      className="inputStyle"
      onChange={
        onChange
          ? onChange
          : (e) => form.setFieldValue(`${value}`, e.target.value)
      }
      variant={variant}
    >
      {children}
    </CustomSelect>
  </FormControl>
);
