import React, { useContext, useEffect, useState } from "react";
import NewPlanContext from "../Context/NewPlanContext";
import { useNavigate } from "react-router-dom";
import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";

import iconCheck from "../../../../assets/CheckFat.png";
import arrow from "../../../../assets/Vector.png";

import "./Step3.css";

const Step3 = () => {
  const navigate = useNavigate();
  const { step1Form, step2Form, step3Form, subscribeLoading } =
    useContext(NewPlanContext);

  useEffect(() => {
    if (step1Form.values.title === "Plano Gratuito") {
    } else if (
      step1Form.values.title === "" &&
      step2Form.values.cardNumber === ""
    ) {
      navigate("/financeiro/step1");
    } else if (
      step1Form.values.title !== "" &&
      step2Form.values.cardNumber === ""
    ) {
      navigate("/financeiro/step2");
    }
  }, [navigate, step1Form.values.title, step2Form.values.cardNumber]);

  function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return cnpj;
  }

  const backHabdle = () => {
    if (step1Form.values.title === "Plano Gratuito") {
      navigate("/financeiro/step1");
    } else {
      navigate("/financeiro/step2");
    }
  };

  const handleTYpePlan = () => {
    if (step1Form.values.title === "Plano Gratuito") {
      navigate("/dashboard");
    } else {
      step3Form.handleSubmit();
    }
  }

  return (
    <AuthorizedLayout>
      <div className="containerSummary">
        <div className="title">
          <img src={iconCheck} alt="card" />
          RESUMO DOS DADOS
        </div>
        <div className="subTitle">
          Confira os dados e finalize o seu cadastro:
        </div>

        <div className="containerCard">
          <div className="card">
            <div className="containerInput">
              <div className="title">Nome do Negócio</div>
              <div className="subTitle">{step3Form.values.name}</div>
            </div>

            <div className="containerInput">
              <div className="title">Telefone</div>
              <div className="subTitle">{step3Form.values.phone}</div>
            </div>

            <div className="containerInput">
              <div className="title">E-mail</div>
              <div className="subTitle">{step3Form.values.email}</div>
            </div>

            <div className="containerInput">
              <div className="title">CPF/CNPJ</div>
              <div className="subTitle">
                {step3Form.values.documentType === "cpf"
                  ? formatCPF(step3Form.values.cpf)
                  : formatCNPJ(step3Form.values.cnpj)}
              </div>
            </div>
          </div>

          <div className="line"></div>

          <div className="card">
            <div className="containerInput">
              <div className="title">Plano Escolhido</div>
              <div className="subTitle">{step1Form.values.title}</div>
            </div>

            {step1Form.values.title !== "Plano Gratuito" && (
              <>
                <div className="containerInput">
                  <div className="title">Tipo de Assinatura</div>
                  <div className="subTitle">Mensal</div>
                </div>

                <div className="containerInput">
                  <div className="title">Tipo de Pagamento</div>
                  <div className="subTitle">Cartão de Crédito</div>
                </div>
              </>
            )}

            <div className="containerInput">
              <div className="title">Valor da Cobrança</div>
              <div className="subTitle">{`R$${step1Form.values.amount}`}</div>
            </div>
          </div>
        </div>

        <div className="containerButtonsSumary">
          <div onClick={() => backHabdle()} className="buttonBackCRedCard">
            <img src={arrow} alt="arrow" />
            Anterior
          </div>

          <div onClick={() => handleTYpePlan()} className="buttonNextCRedCard">
            {step1Form.values.title === "Plano Gratuito"
              ? "Escolher Plano"
              : subscribeLoading
              ? "Carregando..."
              : "Contratar Plano"}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Step3;
