export const parsePhoneAdmin = (phoneNumber) => {
  if (!phoneNumber) {
    return ""; // ou qualquer valor padrão que você deseja retornar
  } else if (
    phoneNumber.replace(/@s\.whatsapp\.net|@g\.us|@c\.us/g, "").length === 13
  ) {
    const formattedPhoneNumber = `+${phoneNumber.slice(
      0,
      2
    )} (${phoneNumber.slice(2, 4)}) ${phoneNumber.slice(
      4,
      5
    )} ${phoneNumber.slice(5, 9)}-${phoneNumber.slice(9)}`;
    return formattedPhoneNumber.replace(/@s\.whatsapp\.net|@g\.us|@c\.us/g, "");
  } else if (
    phoneNumber.replace(/@s\.whatsapp\.net|@g\.us|@c\.us/g, "").length === 11
  ) {
    const formattedPhoneNumber = `(${phoneNumber.slice(
      0,
      2
    )}) ${phoneNumber.slice(2, 3)} ${phoneNumber.slice(
      3,
      7
    )}-${phoneNumber.slice(7)}`;
    return formattedPhoneNumber.replace(/@s\.whatsapp\.net|@g\.us|@c\.us/g, "");
  } else if (
    phoneNumber.replace(/@s\.whatsapp\.net|@g\.us|@c\.us/g, "").length === 10
  ) {
    const formattedPhoneNumber = `(${phoneNumber.slice(
      0,
      2
    )}) ${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6)}`;
    return formattedPhoneNumber.replace(/@s\.whatsapp\.net|@g\.us|@c\.us/g, "");
  } else {
    return phoneNumber.replace(/@s\.whatsapp\.net|@g\.us|@c\.us/g, "");
  }
};

export default parsePhoneAdmin;
