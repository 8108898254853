// SignUpSales.js
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import InputMask from "react-input-mask";

import { AuthContext } from "../../providers/AuthProvider";
import logo from "../../assets/Frame 1.png";
import arrow from "../../assets/Vector.png";
import iconRegister from "../../assets/NotePencil.png";
import image1 from "../../assets/fuundo_ondas (1).png";
import image2 from "../../assets/fuundo_ondas.png";

import validateCPF from "../../utils/validateCPF";
import validateCNPJ from "../../utils/validateCNPJ";

import "./SignUpSales.css";

const SignUpSales = () => {
  const { signUpSales } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      cpf: "",
      cnpj: "",
      documentType: "",
      terms: false,
    },

    validationSchema: yup.object({
      name: yup.string().required("Nome é obrigatório!"),
      phone: yup
        .string()
        .min(14, "Telefone inválido!")
        .required("Telefone de contato é obrigatório!"),

      documentType: yup
        .string()
        .required("Escolher um tipo de documento é obrigatório!"),

      cpf: yup.string().when("documentType", {
        is: "cpf",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test("isValid", "CPF inválido", (value) =>
              validateCPF(value || "")
            ),
      }),

      cnpj: yup.string().when("documentType", {
        is: "cnpj",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test("isValid", "CNPJ inválido", (value) =>
              validateCNPJ(value || "")
            ),
      }),

      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),

      password: yup
        .string()
        .min(8, "Senha deve conter no mínimo 8 caracteres.")
        .required("Senha é obrigatória!"),

      passwordConfirmation: yup
        .string()
        .required("Confirmação da senha é obrigatória!")
        .oneOf([yup.ref("password")], "As senhas devem ser iguais."),

      terms: yup
        .boolean()
        .oneOf([true], "Você deve aceitar os termos de uso.")
        .required("Você deve aceitar os termos de uso."),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    try {
      setLoading(true);

      const response = await signUpSales({ values });

      if (response === "success") {
        navigate("/vendas/dashboard");
      }
    } catch (error) {
      // Handle error if necessary
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Side effects if necessary
  }, [form.values]);

  const renderInputField = (label, name, type = "text", mask = null) => (
    <div className="inputGroup">
      <div className="labelInputRegister">{label}</div>
      {mask ? (
        <InputMask
          mask={mask}
          value={form.values[name]}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          maskChar=""
        >
          {(inputProps) => (
            <input
              {...inputProps}
              className="inputRegister"
              type={type}
              name={name}
              autoComplete="new-password"
            />
          )}
        </InputMask>
      ) : (
        <input
          className="inputRegister"
          type={type}
          name={name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values[name]}
          autoComplete="new-password"
        />
      )}
      {form.touched[name] && form.errors[name] && (
        <Typography className="errorText">{form.errors[name]}</Typography>
      )}
    </div>
  );

  return (
    <div className="containerRegister">
      <header className="headerRegister">
        <img src={logo} alt="Logo" />
      </header>

      <img src={image1} className="image1" alt="Background decoration" />
      <img src={image2} className="image2" alt="Background decoration" />

      <div className="containerCenterRegister">
        <div className="boxRegister">
          <div className="headerBoxRegister">
            <div className="text">
              <img src={iconRegister} alt="Register icon" />
              CADASTRE-SE COMO AFILIADO
            </div>
            <div className="subText">
              Para criar sua conta é simples, basta preencher os campos abaixo:
            </div>
          </div>
          <form onSubmit={form.handleSubmit} className="formRegister">
            {renderInputField("Nome Completo", "name")}
            {renderInputField(
              "Telefone de Contato",
              "phone",
              "text",
              form.values.phone.replace(/\s/g, "").length > 13
                ? "(99) 9 9999-9999"
                : "(99) 9999-9999"
            )}
            {renderInputField("Email", "email", "email")}

            <div className="inputGroup">
              <div className="labelInputRegister">Tipo de Documento</div>
              <div className="documentTypeOptions">
                <label className="radioOption">
                  <input
                    type="radio"
                    name="documentType"
                    value="cpf"
                    checked={form.values.documentType === "cpf"}
                    onChange={form.handleChange}
                  />
                  CPF
                </label>
                <label className="radioOption">
                  <input
                    type="radio"
                    name="documentType"
                    value="cnpj"
                    checked={form.values.documentType === "cnpj"}
                    onChange={form.handleChange}
                  />
                  CNPJ
                </label>
              </div>
              {form.touched.documentType && form.errors.documentType && (
                <Typography className="errorText">
                  {form.errors.documentType}
                </Typography>
              )}
            </div>

            {form.values.documentType &&
              renderInputField(
                "Número do Documento",
                form.values.documentType === "cpf" ? "cpf" : "cnpj",
                "text",
                form.values.documentType === "cpf"
                  ? "999.999.999-99"
                  : "99.999.999/9999-99"
              )}

            {renderInputField("Senha", "password", "password")}
            {renderInputField(
              "Confirmação de Senha",
              "passwordConfirmation",
              "password"
            )}

            <div className="termsSection">
              <label>
                <input
                  type="checkbox"
                  name="terms"
                  checked={form.values.terms}
                  onChange={form.handleChange}
                />
                Concordo com os{" "}
                <a
                  href="https://sacgpt.com.br/termos"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="terms-link"
                >
                  termos de uso
                </a>
              </label>
              {form.touched.terms && form.errors.terms && (
                <Typography className="errorText">
                  {form.errors.terms}
                </Typography>
              )}
            </div>

            <button
              type="submit"
              className="buttonRegister"
              disabled={!form.values.terms || loading}
            >
              {loading ? (
                "Carregando..."
              ) : (
                <div className="buttonContent">
                  Cadastrar
                  <img src={arrow} alt="Seta para a direita" />
                </div>
              )}
            </button>
          </form>
        </div>
      </div>

      <footer className="footerRegister">
        sacgpt.com.br © 2024 - Todos os Direitos Reservados
      </footer>
    </div>
  );
};

export default SignUpSales;
