import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FormControl, NativeSelect, Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import Papa from "papaparse";

import iconUser from "../../../assets/noAvatar.svg";
import iconArronw from "../../../assets/Vector.png";
import iconLUpa from "../../../assets/MagnifyingGlass.png";
import iconFilter from "../../../assets/Funnel.png";
import iconExport from "../../../assets/Export.png";

import "./AfiliateList.css";
import { FileUploadOutlined } from "@mui/icons-material";

const animatedComponents = makeAnimated();

const topArray = [
  { label: "ID", value: "id" },
  { label: "Nome", value: "clientName" },
  { label: "Email", value: "clientEmail" },
  { label: "Telefone", value: "phoneNumber" },
  { label: "Uso de Tokens", value: "tokenUsage" },
  // { label: "Whatsapp", value: "wppConnected" },
  { label: "Google", value: "connectedWithGoogle" },
];

const AfiliateList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState("");

  const handlePlan = (value) => {
    if (value?.id) {
      return value?.name;
    } else {
      switch (value) {
        case "Nenhum plano ativo":
          return "Gratuito";

        default:
          return value;
      }
    }
  };

  const handleStatus = (value) => {
    if (value?.active) {
      switch (value.status) {
        case "Dados Preenchidos":
          return "Inativo";

        case "Cliente Ativo":
          return "Ativo";
        default:
          return value;
      }
    } else {
      return "Cadastrado";
    }
  };

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/admins/affiliates");
      if (data) {
        console.log(data);
        setClients(
          data.data.map((item) => {
            return {
              id: Number(item.id),
              clientName: item.attributes?.name || "Nome não informado",
              phoneNumber:
                item.attributes?.contactNumber || "Número não informado",
              clientEmail: item.attributes?.email || "Email não informado",
              tokenUsage: `${item.attributes?.montlyTokensConsumed || 0} / ${
                item.attributes?.montlyTokensConsumed +
                item.attributes?.monthlyTokensLeft
              }`,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = clients.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const selectedsNamesFilter = filterKeys.map((item) => item.value);

  const generateCSV = (data) => {
    // const formattedData = data.map((item) => ({
    //   ID: Number(item.id),
    //   Nome: item?.clientName || "Nome não informado",
    //   Email: item?.clientEmail || "Email não informado",
    //   Telefone: parsePhoneAdmin(item?.phoneNumber) || "Telefone não informado",
    //   Plano: item?.subscriptionT || "Plano não informado",
    //   Tokens: item?.tokenUsage || "Tokens não informado",
    //   Status: item?.statusT || "Status não informado",
    // }));

    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      Nome: item?.clientName || "Nome não informado",
      Email: item?.clientEmail || "N/A",
      Telefone: parsePhoneAdmin(item?.phoneNumber) || "Número não informado",
      Documento: item?.document || "N/A",
      Google: item?.connectedWithGoogle ? "Vinculado" : "Não Vinculado",
      Status: item?.active ? "Ativa" : "Inativa",
      "Data de Cadastro": item?.createdAt
        ? moment(item?.createdAt).format("DD/MM/YYYY")
        : "N/A",
      "Uso de Tokens": `${item?.montlyTokensConsumed || 0} / ${
        item?.montlyTokensConsumed + item?.monthlyTokensLeft
      }`,
    }));

    const csv = Papa.unparse(formattedData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `SacGPT Afiliados ${moment().format("DD-MM-YYYY")}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClose = () => {
    setTypeFilter("");
    setSearchTerm("");
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const truncateEmail = (email, maxLength) => {
    if (email.length <= maxLength) return email;

    const start = email.slice(0, 5); // Exibir os primeiros 5 caracteres
    const end = email.slice(-10); // Exibir os últimos 10 caracteres

    return `${start}...${end}`;
  };

  function extrairNomeAtePrimeiroEspaco(nomeCompleto) {
    const primeiroEspacoIndex = nomeCompleto.indexOf(" ");

    if (primeiroEspacoIndex === -1) {
      return nomeCompleto;
    }

    const nomeAtePrimeiroEspaco = nomeCompleto.substring(
      0,
      primeiroEspacoIndex
    );

    return nomeAtePrimeiroEspaco;
  }

  return (
    <AuthorizedLayout>
      <div className="containerClients">
        <div
          className="containerInputsClients"
          style={{ display: currentItems.length <= 0 && "none" }}
        >
          {typeFilter === "lupa" && (
            <input
              type="text"
              placeholder="Buscar por nome"
              value={searchTerm}
              onChange={handleSearchChange}
              className="inputSearch"
            />
          )}

          {typeFilter === "filter" && (
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={filterKeys}
              onChange={setFilterKeys}
              isMulti
              options={topArray}
            />
          )}
        </div>
        <div className="cardClients">
          <div className="header">
            {filterKeys?.map((value, idx) => (
              <div key={idx} style={{ flex: value.label === "ID" && "0.2" }}>
                <div>{value.label}</div>
              </div>
            ))}

            <div>
              <div className="containerIconFilter">
                {typeFilter === "lupa" ? (
                  <Tooltip title={"Limpar filtro"}>
                    <button onClick={() => setTypeFilter("")}>X</button>
                  </Tooltip>
                ) : (
                  <Tooltip title={"Buscar"}>
                    <img
                      src={iconLUpa}
                      alt="icon"
                      onClick={() => setTypeFilter("lupa")}
                    />
                  </Tooltip>
                )}

                <Tooltip title={"Exportar página atual"}>
                  <img
                    src={iconExport}
                    alt="icon"
                    onClick={() => generateCSV(currentItems)}
                  />
                </Tooltip>

                <Tooltip title={"Exportar tabela completa"}>
                  <button
                    style={{ backgroundColor: "transparent" }}
                    onClick={() => generateCSV(clients)}
                  >
                    <FileUploadOutlined sx={{ color: "#566986" }} />
                  </button>
                </Tooltip>

                {/* {typeFilter === "filter" ? (
                  <button onClick={() => setTypeFilter("")}>X</button>
                ) : (
                  <img
                    src={iconFilter}
                    alt="icon"
                    onClick={() => setTypeFilter("filter")}
                  />
                )} */}
              </div>
            </div>
          </div>

          {currentItems.length <= 0 && !loading && (
            <div className="containerItens">
              <div className="client">
                <div>Nenhum cliente encontrado.</div>
              </div>
            </div>
          )}

          {loading ? (
            <Loader size={100} />
          ) : (
            <div className="containerItens">
              {currentItems?.map((item, idx) => (
                <div key={idx} className="client">
                  <div
                    style={{
                      width: "100px",
                      backgroundColor: idx % 2 === 0 && "#FFFFFF",
                      borderRadius: "20px 0px 0px 20px",
                      flex: "0.2",
                    }}
                  >
                    {selectedsNamesFilter.includes("id") && (
                      <Tooltip title={item?.id} className="containerItem">
                        <div>{item?.id}</div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("clientName") && (
                      <Tooltip title={item?.clientName}>
                        <div className="containerName">
                          {/* <img
                            src={item?.avatar || iconUser}
                            alt="user"
                            className="userIMage"
                          /> */}
                          <div>
                            {extrairNomeAtePrimeiroEspaco(item?.clientName)}
                          </div>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("clientEmail") && (
                      <Tooltip title={item?.clientEmail}>
                        <div className="containerName">
                          <div>{truncateEmail(item?.clientEmail)}</div>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  {/* <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("clientEmail") && (
                      <Tooltip title={item?.clientEmail}>
                        <div>{item?.clientEmail}</div>
                      </Tooltip>
                    )}
                  </div> */}

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("phoneNumber") && (
                      <Tooltip title={parsePhoneAdmin(item?.phoneNumber)}>
                        <div>{parsePhoneAdmin(item?.phoneNumber)}</div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("tokenUsage") && (
                      <Tooltip title={item?.tokenUsage}>
                        <div>{item?.tokenUsage}</div>
                      </Tooltip>
                    )}
                  </div>

                  {/* <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("wppConnected") && (
                      <Tooltip title={item?.wppConnected}>
                        <div>{item?.wppConnected ? 'Conectado' : 'Desconectado'}</div>
                      </Tooltip>
                    )}
                  </div> */}

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("connectedWithGoogle") && (
                      <Tooltip title={item?.connectedWithGoogle}>
                        <div>
                          {item?.connectedWithGoogle
                            ? "Conectado"
                            : "Desconectado"}
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <div
                    style={{
                      backgroundColor: idx % 2 === 0 && "#FFFFFF",
                      borderRadius: "0px 20px 20px 0px",
                      justifyContent: "flex-end",
                    }}
                    // onClick={() => navigate(`/chat/${item?.id}`)}
                  >
                    {/* <div className="buttonMoreCheck">Ver mais</div> */}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="containerPagination">
          <div className="pagination">
            <button
              className="containerButton"
              onClick={() => handleClick("prev")}
              disabled={currentPage === 1}
              style={{ opacity: currentPage === 1 && "55%" }}
            >
              <img src={iconArronw} alt="icon" className="left" />
              <div>Anterior</div>
            </button>
            <strong>{` ${currentPage}-${totalPages} `}</strong>de{" "}
            <strong>{`${totalPages}`}</strong>
            <button
              className="containerButton"
              onClick={() => handleClick("next")}
              disabled={totalPages === 1}
              style={{ opacity: totalPages === 1 && "55%" }}
            >
              <div>Próxima</div>
              <img src={iconArronw} alt="icon" />
            </button>
          </div>

          <div className="totalPage">
            <div className="text">Linhas por página:</div>
            <div>
              {isMobileDevice() ? (
                <input
                  type="text"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                  style={{ width: "70px", height: "25px" }}
                />
              ) : (
                <FormControl fullWidth>
                  <NativeSelect
                    value={itemsPerPage}
                    onChange={handlePerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                    <option value={30}>30</option>
                    <option value={35}>35</option>
                    <option value={40}>40</option>
                    <option value={45}>45</option>
                    <option value={50}>50</option>
                    <option value={80}>80</option>
                    <option value={100}>100</option>
                  </NativeSelect>
                </FormControl>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default AfiliateList;
