import React, { useContext, useState } from "react";
import { Divider, List, Toolbar } from "@mui/material";
import { MainListItems } from "./listItems";
import { AuthContext } from "../../providers/AuthProvider";
import { useLocation } from "react-router-dom";

import question from "../../assets/Question.png";
import logout from "../../assets/Vector (1).png";
import logo from "../../assets/logo-sacgpt.png";

import "./Sidebar.css";
import { SidebarLink } from "./styles";

const Sidebar = ({ toggleMenu, menuOpen }) => {
  const { user } = useContext(AuthContext);
  const { signOut } = useContext(AuthContext);
  const { pathname } = useLocation();

  function isMobileDevice() {
    return window.innerWidth <= 900;
  }

  const sidebarStyle = {
    display: isMobileDevice() && (menuOpen ? "block" : "none"),
  };

  const checkWpp = user?.wppConnected ? true : false;
  const checkGoogle = user?.connectedWithGoogle ? true : false;

  // console.log(user);

  return (
    <div className="containerSideBar">
      <div className="blur" style={sidebarStyle}></div>
      <div className="sidebar" style={sidebarStyle}>
        <div className="itensMenuContainer">
          <Toolbar
            sx={{
              px: [1],
            }}
            className="logoSideBar"
          >
            <img
              src={logo}
              alt={""}
              style={{ width: "50%", margin: "20px 0" }}
            />
            <div
              className={`menu-icon ${menuOpen && "active"}`}
              onClick={toggleMenu}
            >
              <div className="btn">
                <div className="btn-left"></div>
                <div className="btn-right"></div>
              </div>
            </div>
          </Toolbar>
          <Divider />

          <List>
            <MainListItems />
            {user.userType === "sales" && (
              <SidebarLink to={"/vendas/suporte"}>
                <div
                  className={`containerMenuLink ${
                    pathname === "/vendas/suporte" && "selectedMenu"
                  }`}
                >
                  <img src={question} alt="suporte" className="imgMenu" />
                  <div className="textMenu">Suporte</div>
                </div>
              </SidebarLink>
            )}

            {user.userType === "partner" && (
              <SidebarLink to={"/suporte"}>
                <div
                  className={`containerMenuLink ${
                    pathname === "/suporte" && "selectedMenu"
                  }`}
                >
                  <img src={question} alt="suporte" className="imgMenu" />
                  <div className="textMenu">Suporte</div>
                </div>
              </SidebarLink>
            )}
          </List>

          <div className="containerStatusCheckMobiles">
            <div className={`${checkWpp}`}>
              <div className="cicle"></div>
              <div>WHATSAPP</div>
            </div>

            <div className={`${checkGoogle}`}>
              <div className="cicle"></div>
              <div>CALENDÁRIO</div>
            </div>
          </div>
        </div>

        <div className="containerMenuLink menuSair" onClick={() => signOut()}>
          <img
            src={logout}
            alt="sair"
            style={{ marginLeft: "30px" }}
            className="imgMenu"
          />
          <div className="textMenu">Sair</div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
