import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import * as yup from "yup";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import validateCPF from "../../../utils/validateCPF";
import validateCNPJ from "../../../utils/validateCNPJ";
import unmask from "../../../utils/unmask";
import WppTemplate from "./WppTemplate";

import cpuIcon from "../../../assets/Cpu.png";
import medalIcon from "../../../assets/Medal.png";
import iconUser from "../../../assets/noAvatar.svg";
import iconHIstory from "../../../assets/ClockCounterClockwise.png";
import iconCHat from "../../../assets/ChatCircleText.png";
import iconLUpa from "../../../assets/MagnifyingGlass.png";
import iconExport from "../../../assets/Export.png";

import "./ChatHistory.css";

const ChatHistory = () => {
  const {
    user,
    updateUser,
    updatePartnerDetail,
    signOut,
    checkComplete,
    checkList,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState({});
  const [history, setHistory] = useState([]);
  const [txtData, setTxtData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [lead, setLead] = useState("");
  const [tokens, setTokens] = useState("");
  const [summary, setSummary] = useState("");
  const [leadScore, setLeadScore] = useState("");
  const [loadingLead, setLoadingLead] = useState(true);
  const [messages, setMessages] = useState([]);
  const [typeFilter, setTypeFilter] = useState("");

  const getHistory = async () => {
    setLoading(true);
    getLead();

    try {
      const { data } = await api.get(
        `partners/partner_client_messages/list_by_client/${id}`
      );

      if (data) {
        setHistory(data.data);
        parseTxt(data.data);
        setMessages(
          data?.data?.map((item) => {
            return {
              date: moment(item?.attributes?.createdAt).format("YYYY-MM-DD"),
              time: moment(item?.attributes?.createdAt).format("hh:mm A"),
              send: item?.attributes?.message || "",
              received: item?.attributes?.automaticResponse || "",
              createdAt: item?.attributes?.createdAt,
              audioMessage: item?.attributes?.audioMessage || false,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar o histórico de conversa.");
    } finally {
      setLoading(false);
    }
  };

  const getLead = async () => {
    setLoadingLead(true);
    try {
      const { data } = await api.get(
        `/partners/partner_clients/lead_classification/${id}`
      );

      if (data) {
        setLead(data.data.attributes.leadClassification);
        setSummary(data.data.attributes.conversationSummary);
        setTokens(data.data.attributes.tokenCount);
        setLeadScore(data.data.attributes.leadScore);
      }
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao tentar gerar a Classificação de Interesse"
      );
    } finally {
      setLoadingLead(false);
    }
  };

  const parseTxt = (data) => {
    let parsedArray = [];
    parsedArray = data.map((item) => {
      let response = "";
      if (item.attributes.message) {
        response += `[${moment(item.attributes.createdAt).format(
          "HH:mm, DD/MM/YYYY"
        )}] ${item.attributes?.partnerClient?.name || "Cliente"}: ${
          item.attributes.message
        }`;
      }
      if (item.attributes.automaticResponse) {
        response +=
          "\n" +
          `[${moment(item.attributes.updatedAt).format(
            "HH:mm, DD/MM/YYYY"
          )}] Atendente Virtual: ${item.attributes.automaticResponse}`;
      }
      return response;
    });
    const blob = new Blob([parsedArray.join("\n")], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `SACGPT ${data[0].attributes.partnerClient.phone.replace(
      /@s\.whatsapp\.net$/,
      ""
    )} ${moment().format("DD-MM-YYYY")}.txt`;
    link.href = url;
    setTxtData(link);
  };

  useEffect(() => {
    getHistory();
  }, [id]);

  const getFirstName = (fullName) => {
    if (!fullName) return ""; // Verifica se a string é vazia ou null
    const names = fullName.split(" ");
    if (names.length < 2) return names[0]; // Verifica se há pelo menos dois nomes
    return `${names[0]} ${names[1]}`;
  };

  function calcularTotalDeMensagens(messages) {
    return messages.reduce((acc, curr) => {
      const messageCount = curr.attributes.message ? 1 : 0;
      const automaticResponseCount = curr.attributes.automaticResponse ? 1 : 0;
      return acc + messageCount + automaticResponseCount;
    }, 0);
  }

  const removeTextUntilFirstPeriod = (text) => {
    // Usar regex para encontrar e remover tudo até o primeiro ponto (inclusive)
    return text.replace(/^.*?\.\s*/, "").trim();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <AuthorizedLayout>
      <div className="containerChatHistory">
        <div className="headerChatHistory">
          <div className="cardChatHistory">
            {/* <div className="containerImg">
              <img src={iconUser} alt="usuario" className="iconUser" />
            </div> */}
            <div className="title">
              {getFirstName(history[0]?.attributes?.partnerClient?.name) ||
                "Nome não informado"}
            </div>
            <div className="containerText">
              <strong>ID:</strong>
              <span>{id}</span>
            </div>
            <div>
              <strong>Telefone:</strong>{" "}
              {history[0]?.attributes?.partnerClient?.phone
                ? parsePhoneAdmin(history[0]?.attributes?.partnerClient?.phone)
                : "Telefone não informado"}
            </div>
            <div>
              <strong>Último contato:</strong>{" "}
              {moment(
                history[history.length - 1]?.attributes?.createdAt
              ).format("DD/MM/YYYY") || "Sem informações"}
            </div>
          </div>

          <div className="cardScore">
            <img src={medalIcon} alt="icon" />
            <div className="containerText">
              <div className="title">Classificação de Interesse:</div>
              <div className="subtitle">
                {leadScore
                  ? `${leadScore} ${leadScore > 1 ? "pontos" : "ponto"}`
                  : "Sem classificação"}
              </div>
            </div>
          </div>

          <div className="cardScore">
            <img src={cpuIcon} alt="icon" />
            <div className="containerText">
              <div className="title">Tokens utilizados:</div>
              <div className="subtitle">{`${tokens || 0} tokens`}</div>
            </div>
          </div>
        </div>

        <div className="containerSumary">
          <div className="containerBox">
            <div className="title">
              <img src={iconCHat} alt="icon" />
              Resumo da Conversa
            </div>
            <div className="text">
              {summary
                ? summary
                : "Não foi possível gerar o Resumo da Conversa"}
            </div>
          </div>

          <div className="containerBox">
            <div className="title">
              <img src={medalIcon} alt="icon" />
              Classificação de Interesse
            </div>
            <div className="text">
              {lead ? (
                <>
                  <strong>{`A conversa foi classificada com nota de ${leadScore}/5. `}</strong>{" "}
                  {removeTextUntilFirstPeriod(lead)}
                </>
              ) : (
                "Não foi possível gerar a Classificação de Interesse"
              )}
            </div>
          </div>

          <div className="containerBox boxWpp">
            {/* {typeFilter === "lupa" && (
            <input
              type="text"
              placeholder="Buscar por nome"
              value={searchTerm}
              onChange={handleSearchChange}
              className="inputSearch"
            />
          )} */}
            <div className="containerHeader2">
              <div className="title">
                <img src={iconHIstory} alt="icon" />
                Histórico de conversas
              </div>

              <div className="containerIcons">
                {/* <div className="containerIconFilter">
                  {typeFilter === "lupa" ? (
                    <button onClick={() => setTypeFilter("")}>X</button>
                  ) : (
                    <img
                      src={iconLUpa}
                      alt="icon"
                      onClick={() => setTypeFilter("lupa")}
                    />
                  )}
                </div> */}

                <div className="containerIconFilter">
                  <Tooltip title="Fazer download da conversa">
                    <div
                      onClick={() => {
                        if (txtData) txtData.click();
                      }}
                    >
                      <img src={iconExport} alt="icon" />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <WppTemplate messages={messages} />
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default ChatHistory;
