import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../utils/api";
import Step6 from "./Components/Step6";
import "./Attendant.css";

const LinkAccounts = () => {
  const navigate = useNavigate();
  const { user, checkList, checkComplete, updatePartnerDetail } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkSave, setCheckSave] = useState(true);
  const [modalWarning, setModalWarning] = useState(false);

  // Function to check if it's a mobile device
  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  // Fetch settings from API
  const getSettings = async () => {
    setLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.get(
          `partners/partner_details/${user.partnerDetailId}`
        );

        if (data) {
          formStep6.setValues({
            connectedWithGoogle: data.data.attributes?.connectedWithGoogle,
          });
        }
      }
    } catch (error) {
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
      setCheckSave(true);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  // Formik for Step 6 (Vincular Contas)
  const formStep6 = useFormik({
    initialValues: {
      connectedWithGoogle: false,
    },

    validationSchema: yup.object({}),

    onSubmit: (values) => updateStep6(values),
  });

  const updateStep6 = async (values) => {
    setSaveLoading(true);

    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                connectedWithGoogle: values.connectedWithGoogle,
              },
            },
          }
        );

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              connectedWithGoogle: values.connectedWithGoogle,
            },
          },
        });

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
        }
      }
    } catch (error) {
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  const handleNavigate = () => {
    // Implement navigation logic if needed
  };

  const handleMenu = () => {
    if (!checkSave) {
      setModalWarning(true);
    } else {
      // Implement menu selection logic if needed
    }
  };

  const nextPage = () => {
    setModalWarning(false);
    setCheckSave(true);
  };

  return (
    <AuthorizedLayout>
      <div className="containerAttendant">
        <div className="contentWeb">
          <Step6
            form={formStep6}
            saveLoading={saveLoading}
            setCheckSave={setCheckSave}
            checkList={checkList}
          />
        </div>

        {modalWarning && (
          <Modal
            open
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setModalWarning(false);
              }
            }}
          >
            <Box
              style={{
                background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                borderRadius: "20px",
              }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: isMobileDevice() ? 350 : 700,
                p: 2.5,
              }}
            >
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      margin: 0,
                      fontFamily: [
                        '"Roboto"',
                        '"Helvetica"',
                        '"Arial"',
                        "sans-serif",
                      ],
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.0075em",
                      alignSelf: "flex-start",
                      marginTop: "15px",
                      marginBottom: "15px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={28}
                      fontWeight={600}
                      color={"#e52207"}
                    >
                      AVISO
                    </Typography>
                  </Typography>

                  <Typography
                    textAlign={"center"}
                    fontWeight={"600"}
                    style={{ marginBottom: "25px" }}
                    fontSize={20}
                  >
                    {
                      "Você tem alterações não salvas. Se você sair sem salvar, perderá todas as alterações feitas."
                    }
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        setModalWarning(false);
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        Voltar
                      </Typography>
                    </Button>

                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                        backgroundColor: "#e52207",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        nextPage();
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {"Entendi"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        <div className="contentMobile">
          <Step6
            form={formStep6}
            saveLoading={saveLoading}
            setCheckSave={setCheckSave}
            checkList={checkList}
          />
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default LinkAccounts;
