import {
  Close,
  Info,
  InfoOutlined,
  WhatsApp,
  ZoomIn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api from "../../../utils/api";
import { Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ModalAlert from "../../../components/ModalAlert";
import { InputField } from "../../../components/InputField";
import { useFormik } from "formik";
import parsePhone from "../../../utils/parsePhone";
import { InputWithLabel } from "../../../components/InputWithLabel";
import { SelectWithLabel } from "../../../components/SelectWithLabel";
import Papa from "papaparse";
import makeAnimated from "react-select/animated";

import iconUser from "../../../assets/noAvatar.svg";
import iconArronw from "../../../assets/Vector.png";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import Step1 from "../../PartnerScreens/Settings/Components/Step1";
import Step4 from "../../PartnerScreens/Settings/Components/Step4";
import Step3 from "../../PartnerScreens/Settings/Components/Step3";

import "./PartnerClientsList.css";

export const PartnerClientsList = () => {
  const topArray = [
    { label: "ID", value: "id" },
    { label: "Nome", value: "clientName" },
    { label: "Telefone", value: "phoneNumber" },
  ];

  const [clients, setClients] = useState([]);
  const [partnerSettings, setPartnerSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [clientId, setClientId] = useState(null);
  const { id } = useParams();
  const [section, setSection] = useState("identity");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const [menuSelected, setMenuSelected] = useState({
    menu: "Dados Básicos",
    index: 0,
  });

  const animatedComponents = makeAnimated();

  const optionsArray = [
    "Agendar uma reunião",
    "Adquirir email do cliente",
    "Outro",
  ];

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const scrollToSection1 = () => {
    section1Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection2 = () => {
    section2Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection3 = () => {
    section3Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const menuArray = [
    "Dados Básicos",
    "",
    "Produtos e Serviços",
    "",
    "Estratégia de Atendimento",
  ];

  const toneArray = [
    "Respeitoso & Empático",
    "Animado & Engajador",
    "Profissional & Firme",
    "Atencioso & Calmo",
    "Encorajador & Motivador",
    "Compassivo & Compreensivo",
    "Divertido & Cativante",
    "Cortês & Gentil",
    "Solidário & Amigável",
    "Eficiente & Rápido",
    "Confiável & Preciso",
    "Assertivo & Decisivo",
    "Perspicaz & Observador",
    "Outro",
  ];

  // useEffect(() => {
  //   console.log(selectedClient);
  // }, [selectedClient]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
      },
    },

    {
      name: "clientName",
      label: "Nome",
      options: {
        filter: false,
      },
    },

    {
      name: "phoneNumber",
      label: "Telefone",
      options: {
        filter: false,
        customBodyRender: (phoneNumber, { rowData }) => {
          return parsePhone(phoneNumber);
        },
      },
    },
  ];

  const formStep1 = useFormik({
    initialValues: {
      nameAttendant: "",
      companyName: "",
      companyNiche: "",
    },
  });

  const formStep3 = useFormik({
    initialValues: {
      companyServices: "",
      companyProducts: "",
      catalogLink: "",
      servedRegion: "",
      companyContact: "",
      keyDifferentials: "",
    },
  });

  const formStep4 = useFormik({
    initialValues: {
      companyObjectives: [],
      companyObjectivesExtra: "",
      marketingChannels: "",
      twitterXLink: "",
      youtubeLink: "",
      facebookLink: "",
      instagramLink: "",
      toneVoice: [],
      toneVoiceExtra: "",
      preferentialLanguage: "",
    },
  });

  const getSettings = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`admins/partners/${id}`);

      if (data) {
        setClients(
          data.data.attributes.partnerClients
            .map((item) => {
              return {
                id: Number(item.id),
                clientName: item?.name || "Nome não informado",
                phoneNumber: item?.phone || "Número não informado",
                ...item,
              };
            })
            .sort((a, b) => {
              return a.id - b.id;
            })
        );

        let valorExtra = "";
        const valoresFiltrados = data.data?.attributes?.partnerDetail
          ?.company_objectives
          ? Object.keys(
              data.data.attributes.partnerDetail?.company_objectives
            ).reduce((acc, key) => {
              if (
                optionsArray.includes(
                  data.data.attributes.partnerDetail?.company_objectives[key]
                )
              ) {
                acc[key] =
                  data.data.attributes.partnerDetail?.company_objectives[key];
              } else {
                acc[key] = "Outro";
                valorExtra =
                  data.data.attributes.partnerDetail?.company_objectives[key];
              }

              return acc;
            }, [])
          : [];

        let toneExtra = "";
        const toneVoiceFiltrados = data.data?.attributes?.partnerDetail
          ?.tone_voice
          ? Object.keys(data.data.attributes.partnerDetail?.tone_voice).reduce(
              (acc, key) => {
                if (
                  toneArray.includes(
                    data.data.attributes.partnerDetail?.tone_voice[key]
                  )
                ) {
                  acc[key] =
                    data.data.attributes.partnerDetail?.tone_voice[key];
                } else {
                  acc[key] = "Outro";
                  toneExtra =
                    data.data.attributes.partnerDetail?.tone_voice[key];
                }

                return acc;
              },
              []
            )
          : [];

        formStep1.setValues({
          nameAttendant: data.data.attributes?.partnerDetail?.name_attendant,
          companyName: data.data.attributes?.partnerDetail?.company_name,
          companyNiche: data.data.attributes?.partnerDetail?.company_niche,
        });
        formStep3.setValues({
          companyServices:
            data.data.attributes?.partnerDetail?.company_services,
          companyProducts:
            data.data.attributes?.partnerDetail?.company_products,
          catalogLink:
            data?.data?.attributes?.partnerDetail?.catalog_link || "",
          servedRegion: data.data.attributes?.partnerDetail?.served_region,
          keyDifferentials:
            data.data.attributes?.partnerDetail?.key_differentials,
          companyContact: data.data.attributes?.partnerDetail?.company_contact,
        });
        formStep4.setValues({
          companyObjectives: valoresFiltrados,
          companyObjectivesExtra: valorExtra,
          marketingChannels:
            data.data.attributes?.partnerDetail?.marketing_channels,
          twitterXLink: data.data.attributes?.partnerDetail?.twitter_x_link,
          youtubeLink: data.data.attributes?.partnerDetail?.youtube_link,
          facebookLink: data.data.attributes?.partnerDetail?.facebook_link,
          instagramLink: data.data.attributes?.partnerDetail?.instagram_link,
          preferentialLanguage:
            data.data.attributes?.partnerDetail?.preferential_language,
          toneVoice: toneVoiceFiltrados,
          toneVoiceExtra: toneExtra,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = clients.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const selectedsNamesFilter = filterKeys.map((item) => item.value);

  const renderItemMenu = (name) => {
    switch (name) {
      case "Dados Básicos":
        return (
          <Step1
            form={formStep1}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            disabled
          />
        );

      case "Produtos e Serviços":
        return (
          <Step3
            form={formStep3}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            disabled
          />
        );
      case "Estratégia de Atendimento":
        return (
          <Step4
            form={formStep4}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            disabled
          />
        );
      default:
        <Step1
          form={formStep1}
          saveLoading={saveLoading}
          setMenuSelected={setMenuSelected}
          disabled
        />;
    }
  };

  const handleMenu = (item) => {
    setMenuSelected(item);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loader size={150} />
      ) : (
        <div className="containerAttendant">
          <div className="containerMenu">
            {menuArray.map((item, index) => (
              <div
                key={index}
                className={`${item !== "" ? "menu" : "line"} ${
                  item === menuSelected.menu && "menuSelected"
                } ${
                  (index === menuSelected.index - 1 ||
                    index === menuSelected.index + 1) &&
                  "none"
                } `}
                onClick={() => handleMenu({ menu: item, index: index })}
              >
                {item}
              </div>
            ))}
          </div>

          <div className="contentWeb">{renderItemMenu(menuSelected.menu)}</div>

          <div className="contentMobile">
            <Step1
              form={formStep1}
              saveLoading={saveLoading}
              setMenuSelected={setMenuSelected}
              disabled
            />

            <Step3
              form={formStep3}
              saveLoading={saveLoading}
              setMenuSelected={setMenuSelected}
              section1Ref={section1Ref}
              disabled
            />

            <Step4
              form={formStep4}
              saveLoading={saveLoading}
              setMenuSelected={setMenuSelected}
              section2Ref={section2Ref}
              disabled
            />
          </div>
        </div>
      )}

      <div className="containerClients">
        <div className="containerInputsClients">
          {typeFilter === "lupa" && (
            <input
              type="text"
              placeholder="Buscar por nome"
              value={searchTerm}
              onChange={handleSearchChange}
              className="inputSearch"
            />
          )}

          {typeFilter === "filter" && (
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={filterKeys}
              onChange={setFilterKeys}
              isMulti
              options={topArray}
            />
          )}
        </div>
        <div className="cardClients">
          <div className="header">
            {filterKeys?.map((value, idx) => (
              <div key={idx} style={{ width: value.label === "ID" && "100px" }}>
                <div>{value.label}</div>
              </div>
            ))}
          </div>

          {currentItems.length <= 0 && !loading && (
            <div className="containerItens">
              <div className="client">
                <div>Nenhum cliente encontrado.</div>
              </div>
            </div>
          )}

          {loading ? (
            <Loader size={100} />
          ) : (
            <div className="containerItens">
              {currentItems?.map((item, idx) => (
                <div key={idx} className="client">
                  <div
                    style={{
                      width: "100px",
                      backgroundColor: idx % 2 === 0 && "#FFFFFF",
                      borderRadius: "20px 0px 0px 20px",
                    }}
                  >
                    {selectedsNamesFilter.includes("id") && (
                      <Tooltip title={item?.id} className="containerItem">
                        <div>{item?.id}</div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("clientName") && (
                      <Tooltip title={item?.clientName}>
                        <div className="containerName">
                          <img
                            src={item?.avatar || iconUser}
                            alt="user"
                            className="userIMage"
                          />
                          <div>{item?.clientName}</div>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("phoneNumber") && (
                      <Tooltip title={parsePhoneAdmin(item?.phoneNumber)}>
                        <div>{parsePhoneAdmin(item?.phoneNumber)}</div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="containerPagination">
          <div className="pagination">
            <button
              className="containerButton"
              onClick={() => handleClick("prev")}
              disabled={currentPage === 1}
              style={{ opacity: currentPage === 1 && "55%" }}
            >
              <img src={iconArronw} alt="icon" className="left" />
              <div>Anterior</div>
            </button>
            <strong>{` ${currentPage}-${totalPages} `}</strong>de{" "}
            <strong>{`${totalPages}`}</strong>
            <button
              className="containerButton"
              onClick={() => handleClick("next")}
              disabled={totalPages === 1}
              style={{ opacity: totalPages === 1 && "55%" }}
            >
              <div>Próxima</div>
              <img src={iconArronw} alt="icon" />
            </button>
          </div>

          <div className="totalPage">
            <div className="text">Linhas por página:</div>
            <div>
              <FormControl fullWidth>
                <NativeSelect
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                  <option value={30}>30</option>
                  <option value={35}>35</option>
                  <option value={40}>40</option>
                  <option value={45}>45</option>
                  <option value={50}>50</option>
                  <option value={80}>80</option>
                  <option value={100}>100</option>
                </NativeSelect>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default PartnerClientsList;
