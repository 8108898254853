import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import * as yup from "yup";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import validateCPF from "../../../utils/validateCPF";
import validateCNPJ from "../../../utils/validateCNPJ";
import unmask from "../../../utils/unmask";

import editIcon from "../../../assets/Frame 1 (1).png";
import arrow from "../../../assets/Vector.png";
import iconUser from "../../../assets/noAvatar.svg";

import "./Profile.css";

const Profile = () => {
  const {
    user,
    updateUser,
    updateAffiliated,
    signOut,
    checkComplete,
    checkList,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState(false);
  const [menuSelected, setMenuSelected] = useState({
    menu: "Dados Básicos",
    index: 0,
  });

  const handleDigit = (input) => {
    if (input?.length >= 11 && !input.startsWith("55")) {
      let num;
      num = `55${input}`;
      return num;
    }
    return input;
  };

  const handleDigitAPI = (input) => {
    if (input.startsWith("55")) {
      let num;
      num = input.slice(2);
      return num;
    }
    return input;
  };

  const [tempPhone, setTempPhone] = useState(handleDigit(user.contactNumber));

  const form = useFormik({
    initialValues: {
      name: user.name,
      phone: parsePhoneAdmin(user.contactNumber),
      email: user.email,
      cpf: user?.document?.length
        ? user?.document?.length === 11
          ? user?.document
          : ""
        : "",
      cnpj: user?.document?.length
        ? user?.document?.length === 11
          ? ""
          : user?.document
        : "",
      documentType: user.document?.length
        ? user.document?.length === 11
          ? "cpf"
          : "cnpj"
        : "",

      bankCode: user.paymentAccount?.bankCode || "",
      agNumber: user.paymentAccount?.agNumber || "",
      agDv: user.paymentAccount?.agDv || "",
      accNumber: user.paymentAccount?.accNumber || "",
      accDv: user.paymentAccount?.accNumber || "",
      accountType: user.paymentAccount?.accountType || "",
    },

    validationSchema: yup.object({
      name: yup.string().required("Nome é obrigatório!"),
      phone: yup
        .string()
        .min(14, "Telefone inválido!")
        .required("Telefone de contato é obrigatório!"),

      cpf: yup.string().when("documentType", {
        is: "cpf",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test({
              name: "isValid",
              exclusive: false,
              params: {},
              message: "CPF inválido",
              test: function (value) {
                return validateCPF(value || "");
              },
            }),
      }),

      cnpj: yup.string().when("documentType", {
        is: "cnpj",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test({
              name: "isValid",
              exclusive: false,
              params: {},
              message: "CNPJ inválido",
              test: function (value) {
                return validateCNPJ(value || "");
              },
            }),
      }),

      documentType: yup
        .string()
        .required("Escolher um tipo de documento é obrigatório!"),
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => updateSettings(values),
  });

  const bankForm = useFormik({
    initialValues: {
      id: null,
      bankCode: "",
      agNumber: "",
      accNumber: "",
      accountType: "",
      responsible: "",
      document: "",
      pix: "",
    },

    onSubmit: (values) => updateBankData(values),
  });

  const updateSettings = async (values) => {
    setSaveLoading(true);

    const dataUser = values.avatar
      ? {
          data: {
            attributes: {
              name: values.name.trim(),
              // avatar: { data: values.avatar },
              // companyName: values.companyName.trim(),
              contact_number: handleDigitAPI(unmask(values.phone.trim())),
              document:
                values.documentType === "cpf"
                  ? unmask(values.cpf)
                  : values.documentType === "cnpj"
                  ? unmask(values.cnpj)
                  : null,
              password: values.newPassword ? values.newPassword : null,
              passwordConfirmation: values.passwordConfirmation
                ? values.passwordConfirmation
                : null,
            },
          },
        }
      : {
          data: {
            attributes: {
              name: values.name.trim(),
              // companyName: values.companyName.trim(),
              contact_number: handleDigitAPI(unmask(values.phone.trim())),
              document:
                values.documentType === "cpf"
                  ? unmask(values.cpf)
                  : values.documentType === "cnpj"
                  ? unmask(values.cnpj)
                  : null,
              password: values.newPassword ? values.newPassword : null,
              passwordConfirmation: values.passwordConfirmation
                ? values.passwordConfirmation
                : null,
            },
          },
        };

    try {
      const { data } = await api.put(`/affiliates/affiliates/${user.id}`, {
        ...dataUser,
      });

      if (data) {
        updateAffiliated(data);
        toast.success("Dados da conta atualizados com sucesso!");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.errors[0]?.title?.message) {
        toast.error(
          `${error?.response?.data?.errors[0]?.title?.message} Por favor entre em contato com o suporte.`
        );
      } else
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
    } finally {
      setSaveLoading(false);
    }
  };

  const updateBankData = async (values) => {
    setSaveLoading(true);

    try {
      if (values.id) {
        const { data } = await api.put(
          `/affiliates/affiliate_bank_details/${values.id}`,
          {
            data: {
              attributes: {
                responsible: values?.responsible,
                document_number: unmask(values?.document),
                bank_code: values?.bankCode,
                agency: values?.agNumber,
                account: values?.accNumber,
                account_type: values?.accountType === "corrente" ? 0 : 1,
                pix_code: values?.pix,
              },
            },
          }
        );
        if (data) {
          // updateAffiliated(data);
          toast.success("Dados bancários atualizados com sucesso!");
        }
      } else {
        const { data } = await api.post(
          `/affiliates/affiliate_bank_details/${user.id}`,
          {
            data: {
              attributes: {
                responsible: values?.responsible,
                document_number: unmask(values?.document),
                bank_code: values?.bankCode,
                agency: values?.agNumber,
                account: values?.accNumber,
                account_type: values?.accountType === "corrente" ? 1 : 2,
                pix_code: values?.pix,
              },
            },
          }
        );
        if (data) {
          // updateAffiliated(data);
          toast.success("Dados bancários atualizados com sucesso!");
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar os dados bancários.");
    } finally {
      setSaveLoading(false);
    }
  };
  const getBankData = async () => {
    try {
      const { data } = await api.get(
        `/affiliates/affiliate_bank_details/${user.id}`
      );
      console.log(data);
      if (data?.data) {
        // updateAffiliated(data);
        bankForm.setValues({
          id: data.data.id,
          accNumber: data.data.attributes.account,
          accountType:
            data.data.attributes.accountType === "conta_poupanca"
              ? "poupanca"
              : "corrente",
          agNumber: data.data.attributes.agency,
          bankCode: data.data.attributes.bankCode,
          document: data.data.attributes.documentNumber,
          responsible: data.data.attributes.responsible,
          pix: data.data.attributes.pixCode,
        });
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar carregar os dados bancários.");
    } finally {
    }
  };

  useEffect(() => {
    getBankData();
  }, []);

  function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return cnpj;
  }

  const getFirstName = (fullName) => {
    if (!fullName) return ""; // Verifica se a string é vazia ou null
    const names = fullName.split(" ");
    if (names.length < 2) return names[0]; // Verifica se há pelo menos dois nomes
    return `${names[0]} ${names[1]}`;
  };

  useEffect(() => {
    setTempPhone(form.values.phone);
  }, [form.values.phone]);

  const deleteAccount = async () => {
    setLoadingDeleteAccount(true);
    try {
      await api.delete(`/affiliates/affiliates/${user.id}`);
      setModalWarning(false);
      signOut(0);
    } catch (error) {
    } finally {
      setLoadingDeleteAccount(false);
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        // console.log("File Is", file);
        form.setFieldValue(`avatar`, result);
      })
      .catch((error) => {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(`${error.response.data.errors[0].title}`);
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      });
  };

  const menuArray = ["Dados Básicos", "", "Dados Bancários"];

  const renderCard = () => {
    switch (menuSelected.menu) {
      case "Dados Bancários":
        return (
          <div
            className="containerInputsProfile"
            style={{
              borderTopRightRadius: menuSelected !== "Dados Básicos" && "0px",
              borderTopLeftRadius: menuSelected === "Dados Bancários" && "0px",
            }}
          >
            <div className="title">Editar dados Bancários</div>
            <form className="containerCard">
              <div className="card">
                <div className="containerInput">
                  <div className="title">Código do Banco</div>
                  <div className="subTitleInput">
                    Digite o código identificador do serviço bancário utilizado
                  </div>
                  <input
                    className="inputCredCard"
                    type="text"
                    name="bankCode"
                    onChange={bankForm.handleChange}
                    onBlur={bankForm.handleBlur}
                    value={bankForm.values.bankCode}
                    autoComplete="new-password"
                  />
                  {bankForm.touched.bankCode && bankForm.errors.bankCode ? (
                    <div>{bankForm.errors.bankCode}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="title">Agência</div>
                  <div className="subTitleInput">
                    Digite o número da agência
                  </div>
                  <input
                    className="inputCredCard"
                    type="text"
                    name="agNumber"
                    onChange={bankForm.handleChange}
                    onBlur={bankForm.handleBlur}
                    value={bankForm.values.agNumber}
                    autoComplete="new-password"
                  />
                  {bankForm.touched.agNumber && bankForm.errors.agNumber ? (
                    <div>{bankForm.errors.agNumber}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="title">Conta</div>
                  <div className="subTitleInput">
                    Digite o número da conta bancária
                  </div>
                  <input
                    className="inputCredCard"
                    type="text"
                    name="accNumber"
                    onChange={bankForm.handleChange}
                    onBlur={bankForm.handleBlur}
                    value={bankForm.values.accNumber}
                    autoComplete="new-password"
                  />
                  {bankForm.touched.accNumber && bankForm.errors.accNumber ? (
                    <div>{bankForm.errors.accNumber}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="title">Chave Pix</div>
                  <div className="subTitleInput">Digite a sua chave Pix</div>
                  <input
                    className="inputCredCard"
                    type="text"
                    name="pix"
                    onChange={bankForm.handleChange}
                    onBlur={bankForm.handleBlur}
                    value={bankForm.values.pix}
                    autoComplete="new-password"
                  />
                  {bankForm.touched.pix && bankForm.errors.pix ? (
                    <div>{bankForm.errors.pix}</div>
                  ) : null}
                </div>
              </div>

              <div className="line"></div>

              <div className="card">
                <div className="containerInput">
                  <div className="title">Tipo de Conta</div>
                  <div className="subTitleInput">
                    Selecione o tipo de bancária utilizado
                  </div>
                  <RadioGroup
                    row
                    value={bankForm.values.accountType}
                    style={{
                      height: "56px",
                      display: "flex",
                      gap: "20px",
                    }}
                    onClick={(e) => {
                      if (e.target.value)
                        bankForm.setFieldValue("accountType", e.target.value);
                    }}
                  >
                    <label className="checkbox-container">
                      <div
                        className="checkmarkBackground"
                        style={{ borderRadius: "15px" }}
                      >
                        <input
                          type="checkbox"
                          value="corrente"
                          checked={bankForm?.values?.accountType === "corrente"}
                        />
                        <span
                          className="checkmark"
                          style={{ borderRadius: "15px" }}
                        ></span>
                      </div>
                      <div>Corrente</div>
                    </label>
                    <label className="checkbox-container">
                      <div
                        className="checkmarkBackground"
                        style={{ borderRadius: "15px" }}
                      >
                        <input
                          type="checkbox"
                          checked={bankForm?.values?.accountType === "poupanca"}
                          value="poupanca"
                        />
                        <span
                          className="checkmark"
                          style={{ borderRadius: "15px" }}
                        ></span>
                      </div>
                      <div>Poupança</div>
                    </label>
                  </RadioGroup>
                  {bankForm.touched.accountType &&
                  bankForm.errors.accountType ? (
                    <div>{bankForm.errors.accountType}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="title">Nome do Responsável</div>
                  <div className="subTitleInput">
                    Digite o nome do responsável pela conta bancária
                  </div>
                  <input
                    className="inputCredCard"
                    type="text"
                    name="responsible"
                    onChange={bankForm.handleChange}
                    onBlur={bankForm.handleBlur}
                    value={bankForm.values.responsible}
                    autoComplete="new-password"
                  />
                  {bankForm.touched.responsible &&
                  bankForm.errors.responsible ? (
                    <div>{bankForm.errors.responsible}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="title">Documento do Responsável</div>
                  <div className="subTitleInput">
                    Digite o CPF do responsável pela conta bancária
                  </div>
                  <InputMask
                    className="inputCredCard"
                    mask={"999.999.999-99"}
                    name="document"
                    onChange={bankForm.handleChange}
                    onBlur={bankForm.handleBlur}
                    autoComplete="new-password"
                    value={bankForm.values.document}
                  >
                    {(inputProps) => <input {...inputProps} type="text" />}
                  </InputMask>
                  {bankForm.touched.document && bankForm.errors.document ? (
                    <div>{bankForm.errors.document}</div>
                  ) : null}
                </div>
              </div>
            </form>

            <div onClick={bankForm.handleSubmit} className="buttonNextCRedCard">
              {saveLoading ? (
                "Carregando..."
              ) : (
                <>
                  Salvar alterações
                  <img src={arrow} alt="arrow" />
                </>
              )}
            </div>
          </div>
        );
      default:
        return (
          <div className="containerInputsProfile">
            <div className="title">Editar dados da conta</div>
            <form className="containerCard">
              <div className="card">
                <div className="containerInput">
                  <div className="title">E-mail</div>
                  <div className="subTitleInput">
                    Digite o email para login e controle interno
                  </div>
                  <input
                    className="inputCredCard"
                    type="email"
                    name="email"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.email}
                    autoComplete="new-password"
                  />
                  {form.touched.email && form.errors.email ? (
                    <div>{form.errors.email}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="title">Telefone de Contato</div>
                  <div className="subTitleInput">
                    Digite o telefone a ser utilizado para informes
                  </div>
                  <InputMask
                    className="inputCredCard"
                    mask={"+99 99 99999-9999"}
                    name="phone"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.phone}
                    autoComplete="new-password"
                  >
                    {(inputProps) => <input {...inputProps} type="text" />}
                  </InputMask>
                  {form.touched.phone && form.errors.phone ? (
                    <div>{form.errors.phone}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="title">Senha</div>
                  <div className="subTitleInput">
                    Defina a senha de acesso à sua conta
                  </div>
                  <input
                    className="inputCredCard"
                    type="password"
                    name="newPassword"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.newPassword}
                    autoComplete="new-password"
                  />
                  {form.touched.newPassword && form.errors.newPassword ? (
                    <div>{form.errors.newPassword}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="subTitleInput">Confirme a senha</div>
                  <input
                    className="inputCredCard"
                    type="password"
                    name="passwordConfirmation"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.passwordConfirmation}
                    autoComplete="new-password"
                  />
                  {form.touched.passwordConfirmation &&
                  form.errors.passwordConfirmation ? (
                    <div>{form.errors.passwordConfirmation}</div>
                  ) : null}
                </div>
              </div>

              <div className="line"></div>

              <div className="card">
                <div className="containerInput">
                  <div className="title">Nome</div>
                  <div className="subTitleInput">Digite aqui o seu nome</div>
                  <input
                    className="inputCredCard"
                    type="text"
                    name="name"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.name}
                    autoComplete="new-password"
                  />
                  {form.touched.name && form.errors.name ? (
                    <div>{form.errors.name}</div>
                  ) : null}
                </div>

                <div className="containerInput">
                  <div className="title">Tipo de Documento</div>
                  <div className="subTitleInput">
                    Selecione o tipo de documento a ser inserido
                  </div>

                  <RadioGroup
                    row
                    value={form.values.documentType}
                    style={{
                      height: "56px",
                      display: "flex",
                      gap: "20px",
                    }}
                    onClick={(e) => {
                      if (e.target.value)
                        form.setFieldValue("documentType", e.target.value);
                    }}
                  >
                    <label className="checkbox-container">
                      <div
                        className="checkmarkBackground"
                        style={{ borderRadius: "15px" }}
                      >
                        <input
                          type="checkbox"
                          value="cpf"
                          checked={form?.values?.documentType === "cpf"}
                        />
                        <span
                          className="checkmark"
                          style={{ borderRadius: "15px" }}
                        ></span>
                      </div>
                      <div>cpf</div>
                    </label>
                    {/* <FormControlLabel
                    value="cpf"
                    control={<Radio />}
                    label="CPF"
                    className="subTitle"
                  /> */}

                    <label className="checkbox-container">
                      <div
                        className="checkmarkBackground"
                        style={{ borderRadius: "15px" }}
                      >
                        <input
                          type="checkbox"
                          checked={form?.values?.documentType === "CNPJ"}
                          value="CNPJ"
                        />
                        <span
                          className="checkmark"
                          style={{ borderRadius: "15px" }}
                        ></span>
                      </div>
                      <div>CNPJ</div>
                    </label>
                    {/* <FormControlLabel
                    value="cnpj"
                    control={<Radio />}
                    label="CNPJ"
                    className="subTitle"
                  /> */}
                  </RadioGroup>
                  {form.touched.documentType && form.errors.documentType ? (
                    <div>{form.errors.documentType}</div>
                  ) : null}

                  <div className="title">Número do Documento</div>
                  <div className="subTitleInput">
                    Digite o número do seu documento
                  </div>
                  <div className="containerInput">
                    <InputMask
                      className="inputCredCard"
                      mask={
                        form.values.documentType === "cpf"
                          ? "999.999.999-99"
                          : form.values.documentType === "cnpj"
                          ? "99.999.999/9999-99"
                          : ""
                      }
                      name={form.values.documentType}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      autoComplete="new-password"
                      value={
                        form.values.documentType === "cpf"
                          ? form.values.cpf
                          : form.values.documentType === "cnpj"
                          ? form.values.cnpj
                          : null
                      }
                    >
                      {(inputProps) => <input {...inputProps} type="text" />}
                    </InputMask>
                    {form.values.documentType === "cpf" ? (
                      form.touched.cpf && form.errors.cpf ? (
                        <div>{form.errors.cpf}</div>
                      ) : null
                    ) : form.touched.cnpj && form.errors.cnpj ? (
                      <div>{form.errors.cnpj}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>

            <div onClick={form.handleSubmit} className="buttonNextCRedCard">
              {saveLoading ? (
                "Carregando..."
              ) : (
                <>
                  Salvar alterações
                  <img src={arrow} alt="arrow" />
                </>
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerProfile">
        <div className="headerProfile">
          <div className="cardProfile">
            <div className="containerImg">
              <img
                src={form.values.avatar || user?.avatar || iconUser}
                alt="usuario"
                className="iconUser"
              />
              <label className="form-group button" htmlFor="fupload">
                <img
                  src={editIcon}
                  alt="edit"
                  className="editIcon"
                  htmlFor="fupload"
                />
                <input
                  type="file"
                  id="fupload"
                  name="fupload"
                  className="fupload form-control"
                  accept=".jpeg, .png, .jpg"
                  onChange={handleFileInputChange}
                />
              </label>
            </div>
            <div className="title">{getFirstName(form.values.name)}</div>
            <Tooltip title={form.values.email}>
              <div className="containerText">
                <strong>E-mail:</strong>
                <span>{form.values.email}</span>
              </div>
            </Tooltip>
            <div>
              <strong>Telefone:</strong> {`${form.values.phone}`}
            </div>
            <div>
              <strong>CPF/CNPJ:</strong>{" "}
              {form.values.documentType === "cpf"
                ? formatCPF(form.values.cpf) || ""
                : formatCNPJ(form.values.cnpj) || ""}
            </div>

            <div
              onClick={() => {
                setModalWarning(true);
              }}
              className="buttonDelete"
              style={{ alignSelf: "flex-start" }}
            >
              Excluir conta
            </div>
          </div>
        </div>

        {modalWarning && (
          <Modal
            open
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setModalWarning("false");
              }
            }}
          >
            <Box
              style={{
                background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                borderRadius: "20px",
              }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: isMobileDevice() ? 350 : 700,
                // height: 200,
                p: 2.5,
              }}
            >
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      margin: 0,
                      fontFamily: [
                        '"Roboto"',
                        '"Helvetica"',
                        '"Arial"',
                        "sans-serif",
                      ],
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.0075em",
                      alignSelf: "flex-start",
                      marginTop: "15px",
                      marginBottom: "15px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={28}
                      fontWeight={600}
                      color={"#e52207"}
                    >
                      ATENÇÃO
                    </Typography>
                  </Typography>

                  <Typography
                    textAlign={"center"}
                    fontWeight={"600"}
                    style={{ marginBottom: "25px" }}
                    fontSize={20}
                  >
                    {
                      "Tem certeza de que deseja excluir sua conta? Esta ação é permanente e não pode ser desfeita."
                    }
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                        backgroundColor: "#e52207",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        deleteAccount();
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {loadingDeleteAccount ? "EXCLUINDO..." : "EXCLUIR"}
                      </Typography>
                    </Button>

                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        setModalWarning(false);
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {"CANCELAR"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        <div style={{ width: "100%" }}>
          <div className="containerMenu">
            {menuArray.map((item, index) => (
              <div
                key={index}
                className={`${item !== "" ? "menu" : "line"} ${
                  item === menuSelected.menu && "menuSelected"
                } ${
                  (index === menuSelected.index - 1 ||
                    index === menuSelected.index + 1) &&
                  "none"
                } ${item !== "Dados Básicos"}`}
                onClick={() => setMenuSelected({ menu: item, index: index })}
              >
                {item}
              </div>
            ))}
          </div>

          {renderCard()}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Profile;
