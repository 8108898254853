import React from "react";

import arrow from "../../../../../assets/Vector.png";

import "./Step3.css";
import { toast } from "react-toastify";

const navigateimulator = {
  menu: "Simulador do Atendente",
  index: 6,
};

function isMobileDevice() {
  return window.innerWidth <= 768;
}


const Step3 = ({ form, saveLoading, setMenuSelected, checkList, scrollToSection1 }) => {
  const handleMenu = (item) => {
    if (
      item.menu === "Simulador do Atendente" &&
      !checkList
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      setMenuSelected(item);
      if (isMobileDevice()) {
        scrollToSection1();
      }
    }
  };

  return (
    <div className="containerStep2">
      <div className="titlePage">Produtos e Serviços</div>
      <div className="containerInputs">
        <div className="card">
          <div className="containerInput">
            <div className="title">Produtos do Negócio</div>
            {/* <div className="subTitleInput">
              Descreva os produtos ou itens distintos que seu negócio
              disponibiliza no mercado
            </div> */}
            <input
              className="inputStyle"
              type="text"
              name="companyProducts"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.companyProducts}
              disabled
            />
          </div>

          <div className="containerInput">
            <div className="title">Serviços do Negócio</div>
            {/* <div className="subTitleInput">
              Liste os principais serviços ou soluções oferecidos pelo seu
              negócio
            </div> */}
            <input
              className="inputStyle"
              type="text"
              name="companyServices"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.companyServices}
              disabled
            />
          </div>

          <div className="containerInput">
            <div className="title">Catálogo de Produtos/Serviços</div>
            {/* <div className="subTitleInput">
              Insira o link do seu catálogo para o atendente fornecer ao cliente
              sempre que solicitado
            </div> */}
            <input
              className="inputStyle"
              type="text"
              name="catalogLink"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.catalogLink}
              disabled
            />
          </div>
        </div>

        <div className="card">
          <div className="containerInput">
            <div className="title">Campo de Atuação do Negócio</div>
            {/* <div className="subTitleInput">
              Ex: Serviços de acompanhamento escolar, Serviços financeiros,
              Consultoria nutricional
            </div> */}
            <input
              className="inputStyle"
              type="text"
              name="servedRegion"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.servedRegion}
              disabled
            />
          </div>

          <div className="containerInput">
            <div className="title">Diferenciais Chave</div>
            {/* <div className="subTitleInput">
              Destaque os principais aspectos que tornam seu negócio único no
              mercado
            </div> */}
            <input
              className="inputStyle"
              type="text"
              name="keyDifferentials"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.keyDifferentials}
              disabled
            />
          </div>

          <div className="containerInput">
            <div className="title">Site do Negócio</div>
            {/* <div className="subTitleInput">
              Informe o endereço web do seu negócio, caso já possua um site
            </div> */}
            <input
              className="inputStyle"
              type="text"
              name="companyContact"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.companyContact}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="containerButtons">
        <div
          onClick={() => handleMenu(navigateimulator)}
          className={`buttonSimulator ${
            !checkList && "disabledMenus"
          }`}
        >
          Simular atendimento
        </div>

        {/* <div onClick={form.handleSubmit} className="buttonSave">
          {saveLoading ? (
            "Carregando..."
          ) : (
            <>
              Salvar alterações
              <img src={arrow} alt="arrow" />
            </>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Step3;
