import React, { useEffect, useState } from "react";
import {
  LocalizationProvider,
  TimePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import InputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";

import arrow from "../../../assets/Vector.png";

import "./SacSpan.css";
import { toast } from "react-toastify";
import api from "../../../utils/api";
import moment from "moment";
import { useFormik } from "formik";
import { RadioGroup } from "@mui/material";
import unmask from "../../../utils/unmask";


const SacSpan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [modalSchedules, setModalSchedules] = useState(false);
  const [loading, setLoading] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  const handleAddPhoneNumber = () => {
    if (phone && !phoneNumbers.includes(phone)) {
      setPhoneNumbers([...phoneNumbers, phone]);
      setPhone(""); // Limpa o campo após adicionar
    }
  };

  const handleDeletePhoneNumber = (numberToDelete) => {
    setPhoneNumbers(phoneNumbers.filter((number) => number !== numberToDelete));
  };

  const handleDigitAPI = (input) => {
    if (input.startsWith("55")) {
      let num;
      num = input.slice(2);
      return num;
    }
    return input;
  };

  const formSchedules = useFormik({
    initialValues: {
      id: null,
      title: null,
      message: null,
      triggerType: "manual",
      triggerTime: null,
      isFullDayDispatch: true,
      dispatchStart: null,
      dispatchEnd: null,
      automaticMessagePhonesAttributes: [],
    },

    onSubmit: () => updateSchedules(),
  });

  const getINfos = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/partners/automatic_messages/${id}`);
      if (data.data) {
        formSchedules.setValues({
          id: data.data.id,
          title: data.data.attributes.title || null,
          message: data.data.attributes.message || null,
          triggerType: data.data.attributes.triggerType || "manual",
          triggerTime: moment(data.data.attributes.triggerTime) || null,
          isFullDayDispatch: data.data.attributes.isFullDayDispatch || false,
          dispatchStart: moment(data.data.attributes.dispatchStart) || null,
          dispatchEnd: moment(data.data.attributes.dispatchEnd) || null,
          automaticMessagePhonesAttributes: data.data.attributes.automaticMessagePhones || [],
        });

        const numbers = data?.data?.attributes?.automaticMessagePhones.map((phone) => phone.phone)  || [];
        setPhoneNumbers(numbers);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
   if (id) {
    getINfos();
   }
  }, [id]);

  const updateSchedules = async () => {
    setSaveLoading(true);
    
    try {
      if (formSchedules?.values?.id) {
        const { data } = await api.put(
          `partners/automatic_messages/${formSchedules?.values?.id}`,
          {
            data: {
              attributes: {
                title: formSchedules?.values?.title || "",
                message: formSchedules?.values?.message || "",
                triggerType: formSchedules?.values?.triggerType || "",
                triggerTime: formSchedules?.values?.triggerTime || "",
                isFullDayDispatch:
                  formSchedules?.values?.isFullDayDispatch || false,
                dispatchStart:
                  formSchedules?.values?.dispatchStart?.format("HH:mm:ss") || "",
                dispatchEnd:
                  formSchedules?.values?.dispatchEnd?.format("HH:mm:ss") || "",
                automaticMessagePhonesAttributes:
                  phoneNumbers.length > 0
                    ? phoneNumbers.map((phone) => ({ phone: handleDigitAPI(unmask(phone.trim())) }))
                    : [],
              },
            },
          }
        );

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success(
            "Auto Messagem atualizada com sucesso!"
          );
          setModalSchedules(false);
          navigate(-1)
        }
      } else {
        const { data } = await api.post(`partners/automatic_messages`, {
          data: {
            attributes: {
              title: formSchedules?.values?.title || "",
              message: formSchedules?.values?.message || "",
              triggerType: formSchedules?.values?.triggerType || "",
              triggerTime: formSchedules?.values?.triggerTime || "",
              isFullDayDispatch:
                formSchedules?.values?.isFullDayDispatch || "",
              dispatchStart:
                formSchedules?.values?.dispatchStart?.format("HH:mm:ss") || "",
              dispatchEnd:
                formSchedules?.values?.dispatchEnd?.format("HH:mm:ss") || "",
              automaticMessagePhonesAttributes:
                phoneNumbers.length > 0
                  ? phoneNumbers.map((phone) => ({ phone: handleDigitAPI(unmask(phone.trim())) }))
                  : [],
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success(
            "Auto mensagem criada com sucesso!"
          );
          setModalSchedules(false);
          navigate(-1)
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Não foi possível atualizar a Messagem."
      );
    } finally {
      setSaveLoading(false);
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    formSchedules.setFieldValue(name, value);
  };

  return (
    <AuthorizedLayout>
      <div className="containerSacSpan">
      <div
          style={{
            borderRadius: "0px 20px 20px 0px",
            alignSelf: 'flex-start'
          }}
          onClick={() => navigate(-1)}
        >
          <div className="buttonAddAutoBack">
            <img src={arrow} alt="arrow" style={{ transform: 'rotate(180deg)' }} />
            <div>VOLTAR</div>
          </div>
        </div>
        <div className="titlePage">{id ? 'Editar disparo de mensagens' : 'Adicionar disparo de mensagens'}</div>
        <div className="containerInputs">
          <div className="card">
            <div className="containerInput">
              <div className="title">Titulo</div>
              <div className="subTitleInput">Insira o título da mensagem</div>
              <input
                className="inputStyle"
                type="text"
                name="title"
                onChange={handleInput}
                onBlur={formSchedules.handleBlur}
                value={formSchedules.values.title}
              />
              {formSchedules.touched.title && formSchedules.errors.title ? (
                <div style={{ color: "red" }}>{formSchedules.errors.title}</div>
              ) : null}
            </div>

            <div className="containerInput">
              <div className="title">Mensagem</div>
              <div className="subTitleInput">Insira o conteudo da mensagem</div>
              <textarea
                className="inputStyle"
                type="text"
                name="message"
                onChange={handleInput}
                onBlur={formSchedules.handleBlur}
                value={formSchedules.values.message}
                rows="4"
                style={{ height: "100%" }}
              />
              {formSchedules.touched.message && formSchedules.errors.message ? (
                <div style={{ color: "red" }}>{formSchedules.errors.message}</div>
              ) : null}
            </div>

            <div className="containerInput">
              <div className="title">Tipo de disparo</div>
              {/* <div className="subTitleInput">
                Selecione o tipo de bancária utilizado
              </div> */}
              <RadioGroup
                row
                value={formSchedules.values.triggerType}
                style={{
                  height: "56px",
                  display: "flex",
                  gap: "20px",
                }}
                onClick={(e) => {
                  if (e.target.value)
                    formSchedules.setFieldValue("triggerType", e.target.value);
                }}
              >
                <label className="checkbox-container">
                  <div
                    className="checkmarkBackground"
                    style={{ borderRadius: "15px" }}
                  >
                    <input
                      type="checkbox"
                      value="scheduled"
                      checked={
                        formSchedules?.values?.triggerType === "scheduled"
                      }
                    />
                    <span
                      className="checkmark"
                      style={{ borderRadius: "15px" }}
                    ></span>
                  </div>
                  <div>Agendado</div>
                </label>
                <label className="checkbox-container">
                  <div
                    className="checkmarkBackground"
                    style={{ borderRadius: "15px" }}
                  >
                    <input
                      type="checkbox"
                      checked={formSchedules?.values?.triggerType === "manual"}
                      value="manual"
                    />
                    <span
                      className="checkmark"
                      style={{ borderRadius: "15px" }}
                    ></span>
                  </div>
                  <div>Manual</div>
                </label>
              </RadioGroup>
              {formSchedules.touched.triggerType &&
              formSchedules.errors.triggerType ? (
                <div>{formSchedules.errors.triggerType}</div>
              ) : null}
            </div>

            {formSchedules.values.triggerType === "scheduled" && (
              <>
                <div className="containerInput">
                  <div className="title">Data e Hora do disparo</div>
                  <div className="subTitleInput">
                    Selecione a data e hora para o envio da mensagem.{" "}
                  </div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      value={formSchedules?.values?.triggerTime}
                      onChange={(newValue) =>
                        formSchedules.setFieldValue("triggerTime", newValue)
                      }
                      // format="HH[h] mm[m]"
                      className="custom-timepicker"
                    />
                  </LocalizationProvider>
                </div>
              </>
            )}
          </div>

          <div className="card">
            <div className="containerInput">
              <div className="title">Disparo de 24hrs</div>
              {/* <div className="subTitleInput">
                Selecione o tipo de bancária utilizado
              </div> */}
              <RadioGroup
                row
                value={formSchedules.values.isFullDayDispatch}
                style={{
                  height: "56px",
                  display: "flex",
                  gap: "20px",
                }}
                onClick={(e) => {
                  if (e.target.value)
                    formSchedules.setFieldValue(
                      "isFullDayDispatch",
                      e.target.value === "true" ? true : false
                    );
                }}
              >
                  <label className="checkbox-container">
                  <div
                    className="checkmarkBackground"
                    style={{ borderRadius: "15px" }}
                  >
                    <input
                      type="checkbox"
                      checked={formSchedules?.values?.isFullDayDispatch}
                      value={true}
                    />
                    <span
                      className="checkmark"
                      style={{ borderRadius: "15px" }}
                    ></span>
                  </div>
                  <div>Sim</div>
                </label>

                <label className="checkbox-container">
                  <div
                    className="checkmarkBackground"
                    style={{ borderRadius: "15px" }}
                  >
                    <input
                      type="checkbox"
                      value={false}
                      checked={!formSchedules?.values?.isFullDayDispatch}
                    />
                    <span
                      className="checkmark"
                      style={{ borderRadius: "15px" }}
                    ></span>
                  </div>
                  <div>Não</div>
                </label>
              </RadioGroup>
              {formSchedules.touched.isFullDayDispatch &&
              formSchedules.errors.isFullDayDispatch ? (
                <div>{formSchedules.errors.isFullDayDispatch}</div>
              ) : null}
            </div>

            {!formSchedules.values.isFullDayDispatch && (
              <>
                <div className="containerInput">
                  <div className="title"> Hórario de inicio dos disparos</div>
                  <div className="subTitleInput">
                    Selecione o horario de inicio para o envio da mensagem.
                  </div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={formSchedules.values.dispatchStart}
                      onChange={(newValue) =>
                        formSchedules.setFieldValue("dispatchStart", newValue)
                      }
                      // format="HH[h] mm[m]"
                      className="custom-timepicker"
                    />
                  </LocalizationProvider>
                </div>

                <div className="containerInput">
                  <div className="title"> Hórario final dos disparo</div>
                  <div className="subTitleInput">
                    Selecione o horario de témino para o envio da mensagem.
                  </div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={formSchedules.values.dispatchEnd}
                      onChange={(newValue) =>
                        formSchedules.setFieldValue("dispatchEnd", newValue)
                      }
                      // format="HH[h] mm[m]"
                      className="custom-timepicker"
                    />
                  </LocalizationProvider>
                </div>
              </>
            )}

            <div className="containerInput">
              <div className="title">Numeros de telefones</div>
              <div className="subTitleInput">
                Adicione os números de telefone para os quais as mensagens serão
                enviadas.
              </div>

              <div className="containerINputsPhones">
                <InputMask
                  className="inputStyle"
                  mask={"+99 99 99999-9999"}
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Digite o telefone"
                  autoComplete="new-password"
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>

                <button onClick={handleAddPhoneNumber}>+</button>
              </div>

              <div className="subTitleInput" style={{ marginTop: "10px" }}>
                Numeros adicionados:
              </div>

              <div className="containerNUmbers">
                {phoneNumbers.map((number, index) => (
                  <div key={index}>
                    {number}
                    <button onClick={() => handleDeletePhoneNumber(number)}>
                      X
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="containerButtons">
          <div onClick={formSchedules.handleSubmit} className="buttonSave">
            {saveLoading ? (
              "Carregando..."
            ) : (
              <>
                Salvar alterações
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default SacSpan;
