import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FormControl, NativeSelect, Tooltip, Typography } from "@mui/material";
import { Loader } from "../../../components/Loader";
import Papa from "papaparse";

import iconUser from "../../../assets/noAvatar.svg";
import iconArronw from "../../../assets/Vector.png";
import iconLUpa from "../../../assets/MagnifyingGlass.png";
import iconFilter from "../../../assets/Funnel.png";
import iconExport from "../../../assets/Export.png";

import "./ListAutoMensagem.css";
import ModalAlert from "../../../components/ModalAlert";

const animatedComponents = makeAnimated();

const topArray = [
  { label: "ID", value: "id" },
  { label: "Title", value: "title" },
  { label: "Status", value: "status" },
  { label: "Tipo de disparo", value: "triggerType" },
  { label: "Disparo de 24hrs", value: "isFullDayDispatch" },
];

const ListAutoMensagem = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState("");
  const [modalAlert, setModalAlert] = useState(false);

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("partners/automatic_messages");
      if (data.data) {
        setClients(
          data?.data?.map((item) => {
            return {
              id: Number(item.id),
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = clients.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const selectedsNamesFilter = filterKeys.map((item) => item.value);

  const generateCSV = (data) => {
    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      Nome: item?.name || "Nome não informado",
      Telefone: item?.phone || "Número não informado",
      Interesse: item?.leadScore || "Inconclusivo",
      ÚltimoContato: item?.lastMessage
        ? moment(item.lastMessage).format("DD/MM/YYYY")
        : "Inconclusivo",
    }));

    const csv = Papa.unparse(formattedData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClose = () => {
    setTypeFilter("");
    setSearchTerm("");
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const handleDelete = async (id) => {
    try {
      await api.delete(`partners/automatic_messages/${id}`);
      toast.success("disparo deletado com sucesso!");
      getClients();
    } catch (error) {
      if (error?.response?.data?.errors[0]?.title)
        toast.error(
          `Erro ao deletar! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error("Erro ao deletar!");
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerAutoMensagens">
        <div
          style={{
            borderRadius: "0px 20px 20px 0px",
            alignSelf: "flex-end",
          }}
          onClick={() => navigate(`/auto-mensagens/create`)}
        >
          <div className="buttonAddAuto">ADICIONAR</div>
        </div>
        <div
          className="containerInputsClients"
          style={{ display: currentItems.length <= 0 && "none" }}
        >
          {typeFilter === "lupa" && (
            <input
              type="text"
              placeholder="Buscar por nome"
              value={searchTerm}
              onChange={handleSearchChange}
              className="inputSearch"
            />
          )}

          {typeFilter === "filter" && (
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={filterKeys}
              onChange={setFilterKeys}
              isMulti
              options={topArray}
            />
          )}
        </div>
        <div className="cardClients">
          <div className="header">
            {filterKeys?.map((value, idx) => (
              <div style={{ flex: value.label === "ID" && "0.2" }}>
                <div key={idx}>{value.label}</div>
              </div>
            ))}

            <div>
              <div className="containerIconFilter">
                {typeFilter === "lupa" ? (
                  <button onClick={() => handleClose()}>X</button>
                ) : (
                  <img
                    src={iconLUpa}
                    alt="icon"
                    onClick={() => setTypeFilter("lupa")}
                  />
                )}
                <img
                  src={iconExport}
                  alt="icon"
                  onClick={() => generateCSV(currentItems)}
                />

                {typeFilter === "filter" ? (
                  <button onClick={() => setTypeFilter("")}>X</button>
                ) : (
                  <img
                    src={iconFilter}
                    alt="icon"
                    onClick={() => setTypeFilter("filter")}
                  />
                )}
              </div>
            </div>
          </div>

          {currentItems.length <= 0 && !loading && (
            <div className="containerItens">
              <div className="client">
                <div>Nenhum item encontrado.</div>
              </div>
            </div>
          )}

          {loading ? (
            <Loader size={100} />
          ) : (
            <div className="containerItens">
              {currentItems?.map((item, idx) => (
                <div key={idx} className="client">
                  <div
                    style={{
                      width: "100px",
                      backgroundColor: idx % 2 === 0 && "#FFFFFF",
                      borderRadius: "20px 0px 0px 20px",
                      flex: "0.2",
                    }}
                  >
                    {selectedsNamesFilter.includes("id") && (
                      <Tooltip title={item?.id} className="containerItem">
                        <div>{item?.id}</div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("title") && (
                      <Tooltip title={item?.title}>
                        <div className="containerName">
                          <div>{item?.title}</div>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("status") && (
                      <Tooltip title={item?.status}>
                        <div>{item?.status}</div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("status") && (
                      <Tooltip
                        title={
                          item?.status === "manual" ? "Manual" : "Agendado"
                        }
                      >
                        <div>
                          {item?.status === "manual" ? "Manual" : "Agendado"}
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <div style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}>
                    {selectedsNamesFilter.includes("isFullDayDispatch") && (
                      <Tooltip
                        title={item?.isFullDayDispatch === true ? "Sim" : "Não"}
                      >
                        <div>
                          {item?.isFullDayDispatch === true ? "Sim" : "Não"}
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <div
                    style={{
                      backgroundColor: idx % 2 === 0 && "#FFFFFF",
                      borderRadius: "0px 20px 20px 0px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      onClick={() =>
                        navigate(`/auto-mensagens/edite/${item?.id}`)
                      }
                    >
                      <div className="buttonMoreCheck">EDITAR</div>
                    </div>

                    <div onClick={() => setModalAlert(true)}>
                      <div
                        className="buttonMoreCheck"
                        style={{
                          backgroundColor: "transparent",
                          border: "1px solid #fa460a",
                        }}
                      >
                        DELETE
                      </div>
                    </div>
                  </div>

                  <ModalAlert
                    handleClose={() => setModalAlert(false)}
                    handleSubmit={() => handleDelete(item.id)}
                    open={modalAlert}
                  >
                    <Typography variant={isMobileDevice() ? "h7" : "h6"}>
                      Tem certeza que deseja excluir esse disparo? Essa ação é
                      irreversível.
                    </Typography>
                  </ModalAlert>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="containerPagination">
          <div className="pagination">
            <button
              className="containerButton"
              onClick={() => handleClick("prev")}
              disabled={currentPage === 1}
              style={{ opacity: currentPage === 1 && "55%" }}
            >
              <img src={iconArronw} alt="icon" className="left" />
              <div>Anterior</div>
            </button>
            <strong>{` ${currentPage}-${totalPages} `}</strong>de{" "}
            <strong>{`${totalPages}`}</strong>
            <button
              className="containerButton"
              onClick={() => handleClick("next")}
              disabled={totalPages === 1}
              style={{ opacity: totalPages === 1 && "55%" }}
            >
              <div>Próxima</div>
              <img src={iconArronw} alt="icon" />
            </button>
          </div>

          <div className="totalPage">
            <div className="text">Linhas por página:</div>
            <div>
              {isMobileDevice() ? (
                <input
                  type="text"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                  style={{ width: "70px", height: "25px" }}
                />
              ) : (
                <FormControl fullWidth>
                  <NativeSelect
                    value={itemsPerPage}
                    onChange={handlePerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                    <option value={30}>30</option>
                    <option value={35}>35</option>
                    <option value={40}>40</option>
                    <option value={45}>45</option>
                    <option value={50}>50</option>
                    <option value={80}>80</option>
                    <option value={100}>100</option>
                  </NativeSelect>
                </FormControl>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default ListAutoMensagem;
