import { Close, Info, WhatsApp, ZoomIn } from "@mui/icons-material";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api from "../../../utils/api";
import { Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ModalAlert from "../../../components/ModalAlert";
import { InputField } from "../../../components/InputField";
import { useFormik } from "formik";
import { useTheme } from "@emotion/react";
import moment from "moment";

export const Finance = () => {
  const theme = useTheme();
  const [transactions, setTransactions] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingMetrics, setLoadingMetrics] = useState(true);
  const { id } = useParams();

  const [tablePage, setTablePage] = useState(0);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
      },
    },

    {
      name: "amount",
      label: "Valor",
      options: {
        filter: false,
        customBodyRender: (_value, { rowData }) => {
          if (rowData[3] === "Saída") {
            return (
              <Typography fontSize={14} color={"red"}>
                - R$ {Number(_value).toFixed(2)}
              </Typography>
            );
          } else {
            return (
              <Typography fontSize={14} color={"#539d65"}>
                + R$ {Number(_value).toFixed(2)}
              </Typography>
            );
          }
        },
      },
    },

    {
      name: "description",
      label: "Descrição",
      options: {
        filter: false,
      },
    },

    {
      name: "type",
      label: "Tipo de Transação",
      options: {
        filter: true,
        display: false,
        filterOptions: {
          fullWidth: true,
        },
      },
    },

    {
      name: "customer",
      label: "Cliente",
      options: {
        filter: true,
        display: false,
        filterOptions: {
          fullWidth: true,
        },
      },
    },

    {
      name: "tStatus",
      label: "Status da Transação",
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
      },
    },

    {
      name: "date",
      label: "Data e Hora",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const handleStatus = (status) => {
    switch (status) {
      case "notSend":
        return "Ainda não enviada para operadora de Cartão";
      case "authorized":
        return "Autorizado";
      case "captured":
        return "Capturada na Operadora de Cartão";
      case "denied":
        return "Negada na Operadora de Cartão";
      case "reversed":
        return "Estornada na Operadora de Cartão";
      case "cancel":
        return "Cancelada manualmente";
      case "payExternal":
        return "Paga fora do sistema";
      case "cancelByContract":
        return "Cancelada ao cancelar a cobrança";
      case "free":
        return "Gratuito";
      default:
        return "Outro";
    }
  };

  const getData = async () => {
    setLoading(true);
    setLoadingTable(true);
    try {
      const { data } = await api.get(`admins/payment_transactions?page=1`);

      if (data) {
        //console.log(data);
        setTransactions(
          data.map((item) => {
            return {
              id: item.galaxPayId,
              description: `Assinatura - ${
                item?.Subscription?.Customer?.name || "Não Informado"
              }`,
              date: moment(item.createdAt).format("DD/MM/YYYY hh:mm"),
              customer: item?.Subscription?.Customer?.name || "Não Informado",
              type: "Entrada",
              amount: item.value / 100,
              tStatus: handleStatus(item.status),
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar as transações.");
    } finally {
      setLoadingTable(false);
      setLoading(false);
    }
  };

  const getMetrics = async () => {
    setLoadingMetrics(true);
    try {
      const { data } = await api.get(
        "/admins/payment_transactions/balance_info"
      );
      if (data) {
        setMetrics(data);
        console.log(data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar as métricas financeiras");
    } finally {
      setLoadingMetrics(false);
    }
  };

  const getPage = async (page) => {
    setLoadingTable(true);
    try {
      const { data } = await api.get(
        `admins/payment_transactions?page=${page + 1}`
      );
      if (data) {
        //console.log(data);
        setTransactions(
          data.map((item) => {
            return {
              id: item.galaxPayId,
              description: `Assinatura - ${
                item?.Subscription?.Customer?.name || "Não Informado"
              }`,
              date: moment(item.createdAt).format("DD/MM/YYYY hh:mm"),
              customer: item?.Subscription?.Customer?.name || "Não Informado",
              type: "Entrada",
              amount: item.value / 100,
              tStatus: handleStatus(item.status),
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados do parceiro.");
    } finally {
      setLoadingTable(false);
    }
  };

  const changePage = async (page) => {
    setTablePage(page);

    try {
      await getPage(page);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    getMetrics();
  }, []);

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loader size={150} />
      ) : (
        <div style={{ margin: "24px" }}>
          <Box
            sx={{
              display: "flex",
              top: "10%",
              bgcolor: "white",
              minWidth: "650px",
              //height: "45%",
              flexDirection: "column",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 10,
              p: 4,
              marginBottom: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "white",
                width: "100%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  margin: 0,
                  fontFamily: [
                    '"Roboto"',
                    '"Helvetica"',
                    '"Arial"',
                    "sans-serif",
                  ],
                  fontWeight: 500,
                  fontSize: "1.25rem",
                  lineHeight: 1.6,
                  letterSpacing: "0.0075em",
                  alignSelf: "flex-start",
                  marginTop: "-15px",
                  marginBottom: "15px",
                }}
              >
                Relatório Financeiro
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  bgcolor: "white",
                  width: "50%",
                  //maxWidth: "525px",
                  minWidth: "525px",
                  justifyContent: "center",
                  alignItems: "center",
                  //   border: "2px solid #539d65",
                  //   backgroundColor: "#6fc7b2",
                  //   borderRadius: "30px 30px 30px 30px",
                  //   minHeight: "175px",
                  marginLeft: "25px",
                }}
              >
                {loadingMetrics ? (
                  <Loader size={150} />
                ) : (
                  <Grid container spacing={1} sx={{ height: "100%" }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 600,
                          fontSize: "1rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          // color: "white",
                          marginLeft: "30px",
                        }}
                      >
                        Plano Mais Aderido
                      </Typography>
                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 600,
                          fontSize: "1.5rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          color: "#539d65",
                          marginLeft: "30px",
                          marginBottom: "25px",
                        }}
                      >
                        {metrics?.topPlan?.plan?.name || "Nenhum"}
                      </Typography>

                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 600,
                          fontSize: "1rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          // color: "white",
                          marginLeft: "30px",
                        }}
                      >
                        Saldo disponível
                      </Typography>
                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 600,
                          fontSize: "1.5rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          color: "#539d65",
                          marginLeft: "30px",
                        }}
                      >
                        {`R$ ${
                          metrics?.balance?.Balance?.enabled
                            ? (
                                metrics?.balance?.Balance?.enabled / 100
                              ).toFixed(2)
                            : 0.0
                        }`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",

                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 600,
                          fontSize: "1rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          // color: "white",
                          marginLeft: "30px",
                        }}
                      >
                        Saldo a receber
                      </Typography>
                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 600,
                          fontSize: "1.5rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          color: "#539d65",
                          marginLeft: "30px",
                          marginBottom: "25px",
                        }}
                      >
                        {`R$ ${
                          metrics?.balance?.Balance?.enabled
                            ? (
                                metrics?.balance?.Balance?.blockedCard / 100
                              ).toFixed(2)
                            : 0.0
                        }`}
                      </Typography>

                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 600,
                          fontSize: "1rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          // color: "white",
                          marginLeft: "30px",
                        }}
                      >
                        Saldo em trânsito
                      </Typography>
                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 600,
                          fontSize: "1.5rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          color: "#539d65",
                          marginLeft: "30px",
                        }}
                      >
                        {`R$ ${
                          metrics?.balance?.Balance?.enabled
                            ? (
                                metrics?.balance?.Balance?.requested / 100
                              ).toFixed(2)
                            : 0.0
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
          <MUIDataTable
            title={
              <Typography
                variant="h6"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  Histórico de transações
                </div>
                {loadingTable && (
                  <Loader size={32} style={{ justifyContent: "flex-start" }} />
                )}
              </Typography>
            }
            data={transactions}
            columns={columns}
            options={{
              serverSide: true,
              onTableChange: (action, tableState) => {
                switch (action) {
                  case "changePage":
                    changePage(tableState.page);
                    break;
                  default:
                  //console.log("action not handled.");
                }
              },
              page: tablePage,
              pagination: true,
              rowsPerPage: 10,
              rowsPerPageOptions: [],
              count:
                transactions?.length === 10
                  ? 10 * (tablePage + 1) + 1
                  : 10 * tablePage + transactions?.length,
              selectableRows: "none",
              tableBodyHeight: "65vh",
              sortFilterList: false,
              filter: true,
              textLabels: {
                body: {
                  noMatch: "Nenhum registro encontrado.",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Página Seguinte",
                  previous: "Página Anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Pesquisar",
                  downloadCsv: "Fazer download de CSV",
                  print: "Imprimir",
                  viewColumns: "Ver Colunas",
                  filterTable: "Filtrar Tabela",
                },
                filter: {
                  all: "Todos",
                  title: "FILTROS",
                  reset: "Reiniciar",
                },
                viewColumns: {
                  title: "Mostrar Colunas",
                  titleAria: "Mostrar/Ocultar Colunas da Tabela",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                  delete: "Apagar",
                  deleteAria: "Apagar linhas selecionadas",
                },
              },
            }}
          />
        </div>
      )}
    </AuthorizedLayout>
  );
};
