import { VolumeUp } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "./ChatHistory.css";

const WppTemplate = ({ messages }) => {
  const formatDate = (dateStr) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Cria o objeto Date usando o formato ISO para evitar problemas de fuso horário
    const inputDate = new Date(dateStr);

    // Ajusta a data para o fuso horário local
    const localInputDate = new Date(
      inputDate.toLocaleString("en-US", { timeZone: "UTC" })
    );

    // Define a data de hoje ajustada para o fuso horário local
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Define uma função para comparar datas sem considerar a hora
    const isSameDay = (d1, d2) => {
      return d1.toDateString() === d2.toDateString();
    };

    if (isSameDay(localInputDate, today)) {
      return "hoje";
    }

    return localInputDate.toLocaleDateString("pt-BR", options);
  };

  const groupedMessages = messages.reduce((group, msg) => {
    const { date } = msg;
    if (!group[date]) {
      group[date] = [];
    }
    group[date].push(msg);
    return group;
  }, {});

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let gapNUmber = isMobileDevice() ? 20 : 30;

  return (
    <div>
      <div className="chat-box" style={{ minHeight: "40vh" }}>
        <div className="containerGroupMensagens" style={{ height: "auto" }}>
          {messages.length <= 0 && (
            <div className="dateHeader">Sem histórico de conversas!</div>
          )}
          {Object.keys(groupedMessages).map((date, index) => (
            <div
              key={index}
              className="containerBoxMenssagens"
              style={{ gap: gapNUmber }}
            >
              <div className="dateHeader">{formatDate(date)}</div>
              {groupedMessages[date].map((item, msgIndex) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: gapNUmber,
                    width: "100%",
                  }}
                >
                  {item.send !== "" && !item.audioMessage && (
                    <div className="message sent">
                      <div>{item.send}</div>
                      <span className="time">{item.time}</span>
                    </div>
                  )}

                  {item.send !== "" && item.audioMessage && (
                    <div
                      className="message sent"
                      // style={{ paddingTop: "25px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            marginLeft: "25px",
                            marginTop: "18px",
                            // left: "5px",
                            // marginRight: "5px",
                          }}
                          arrow
                          title={"Transcrição de áudio"}
                        >
                          <VolumeUp />
                        </Tooltip>
                        <div style={{ textIndent: "32px" }}>{item.send}</div>
                      </div>
                      <span className="time">{item.time}</span>
                    </div>
                  )}

                  {item.received !== "" && (
                    <div className="message received">
                      <div>{item.received}</div>
                      <span className="time">{item.time}</span>
                    </div>
                  )}
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WppTemplate;
