import { TextField, Typography } from "@mui/material";
import { Instagram, YouTube, Facebook } from "@mui/icons-material";
import Twiter from "../../../../assets/twitter_5968830 (2).png";

export const ChanelSocial = ({
  label,
  form,
  disabled = false,

  validate = false,

  mask = "",
  maskChar = "",

  helperText = "",

  variant = "standard",

  ...props
}) => (

    <div style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div>
          <Instagram sx={{ fontSize: "30px" }} />
        </div>
        <input
          className="inputStyle"
          type="text"
          name="instagramLink"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.instagramLink}
        />
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div>
          <Facebook sx={{ fontSize: "30px" }} />
        </div>
        <input
          className="inputStyle"
          type="text"
          name="facebookLink"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.facebookLink}
        />
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div>
          <img
            src={Twiter}
            alt="twiter"
            style={{ width: "24px", margin: "3px" }}
          />
        </div>
        <input
          className="inputStyle"
          type="text"
          name="twitterXLink"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.twitterXLink}
        />
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div>
          <YouTube sx={{ fontSize: "30px" }} />
        </div>
        <input
          className="inputStyle"
          type="text"
          name="youtubeLink"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.youtubeLink}
        />
      </div>
    </div>
);
