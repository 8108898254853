import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import NewPlanContext from "./NewPlanContext";
import api from "../../../../utils/api";
import { useFormik } from "formik";
import { AuthContext } from "../../../../providers/AuthProvider";
import parsePhoneAdmin from "../../../../utils/parsePhoneAdmin";
import { toast } from "react-toastify";
import unmask from "../../../../utils/unmask";
import cardBrand from "../../../../utils/cardBrand";
import moment from "moment";

function NewPlanProvider({ children }) {
  const { user, updateUser, checkComplete } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [selectedCard, setSelectedCard] = useState({});
  const [saveCard, setSaveCard] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);

  const navigate = useNavigate();

  const step1Form = useFormik({
    initialValues: {
      title: "",
      amount: "",
      id: "",
      type: "",
    },
    validationSchema: yup.object({
      title: yup.string().required(),
      amount: yup.string().required(),
    }),
    onSubmit: () => navigate("/financeiro/step2"),
  });

  const step2Form = useFormik({
    initialValues: {
      cardFullName: "",
      cardNumber: "",
      cardExpirationDate: "",
      cardCVV: "",
    },
    validationSchema: yup.object({
      cardFullName: yup
        .string()
        .required("Nome no cartão é obrigatório")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      cardNumber: yup
        .string()
        .required("Número do cartão é obrigatório")
        .matches(
          /^[0-9\s]{19}$/,
          "Número do cartão deve estar no formato 9999 9999 9999 9999"
        ),
      cardExpirationDate: yup
        .string()
        .required("Data de expiração é obrigatória")
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Data de expiração deve estar no formato MM/AA"
        )
        .test("expDate", "Data de expiração inválida", (value) => {
          if (!value) return false;
          const today = new Date();
          const [month, year] = value.split("/").map(Number);
          const expDate = new Date(`20${year}`, month - 1);
          return expDate >= today;
        }),
      cardCVV: yup
        .string()
        .required("CVV é obrigatório")
        .matches(/^[0-9]{3,4}$/, "CVV deve ter 3 dígitos"),
    }),
    onSubmit: () => registerCard(),
  });

  const getCards = async () => {
    setLoadingCards(true);
    try {
      const { data: cards } = await api.get(`/partners/credit_cards`);

      if (cards) {
        //console.log(cards.data);
        setCards(
          cards.data.map((item, index) => {
            return {
              id: item.id,
              position: index,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados dos cartões.");
    } finally {
      setLoadingCards(false);
    }
  };

  const registerCard = async () => {
    setSaveCard(true);
    try {
      const { data } = await api.post("/partners/credit_cards", {
        data: {
          attributes: {
            cardNumber: step2Form.values.cardNumber,
            cardHolderName: step2Form.values.cardFullName,
            expiresAt:
              "20" +
              unmask(step2Form.values.cardExpirationDate).slice(2, 4) +
              "-" +
              unmask(step2Form.values.cardExpirationDate).slice(0, 2),
            cardCvv: step2Form.values.cardCVV,
            brand: cardBrand(step2Form.values.cardNumber),
          },
        },
      });

      if (data) {
        // setModal(false);
        // step2Form.setValues(step2Form.initialValues);
        setSelectedCard(data.data);
        getCards();
        navigate("/financeiro/step3");
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors[0].title) {
        toast.error(error.response.data.errors[0].title);
      }
      return toast.error("Ocorreu um erro ao tentar cadastrar o cartão.");
    } finally {
      setSaveCard(false);
    }
  };

  const step3Form = useFormik({
    initialValues: {
      name: user?.name || "",
      phone: parsePhoneAdmin(user?.contactNumber) || "",
      email: user?.email || "",
      cpf: user?.document?.length
        ? user?.document?.length === 11
          ? user?.document
          : ""
        : "",
      cnpj: user?.document?.length
        ? user?.document?.length === 11
          ? ""
          : user?.document
        : "",
      documentType: user?.document?.length
        ? user?.document?.length === 11
          ? "cpf"
          : "cnpj"
        : "",
    },
    onSubmit: () => Subscribe(),
  });

  const Subscribe = async () => {
    setSubscribeLoading(true);
    try {
      const { data } = await api.post("/partners/payment_subscriptions", {
        data: {
          attributes: {
            firstPayDayDate: selectedCard.id ? moment().format("YYYY-MM-DD") : null,
            additionalInfo: "",
            mainPaymentMethodId: selectedCard.id ? "creditcard" : null,
            creditCardId: selectedCard.id ? selectedCard.id : null,
            paymentPlanId: step1Form.values.id,
          },
        },
      });

      if (data) {
        //console.log(data);
        toast.success("Plano aderido com sucesso!");
        handleSuccess();
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao aderir ao plano.");
    } finally {
      setSubscribeLoading(false);
    }
  };

  const handleSuccess = async () => {
    try {
      const { data } = await api.get(`/partners/partners/${user?.id}`);

      await updateUser(data);

      await checkComplete(user.id)

      if (step1Form?.values.type === "tokenPurchase") {
        navigate("/financeiro");
      } else if (!user?.active) {
        navigate("/dashboard");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (user) {
      getCards();
    }
  }, [user]);

  return (
    <NewPlanContext.Provider
      value={{
        step1Form,
        step2Form,
        step3Form,
        selectedCard,
        saveCard,
        subscribeLoading,
      }}
    >
      {children}
    </NewPlanContext.Provider>
  );
}

export default NewPlanProvider;
