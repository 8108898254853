import { CircularProgress } from "@mui/material";

export const Loader = ({ size, style, styleSVG }) => (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...style,
    }}
  >
    <CircularProgress size={size} style={{ ...styleSVG }} />
  </div>
);
