import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#539d65",
    },
  },
});

//primary #539d65

//secondary #122137

//light secondary #566986

//background green #f1fcf4

//background blue #e6e9ed
