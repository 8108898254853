import { InfoOutlined } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

export const SelectWithLabel = ({
  label,
  value,
  form,
  disabled = false,

  validate = false,

  helperText = "",

  variant = "standard",

  multiple = false,

  renderValue = null,

  style,

  onChange,

  children,

  ...props
}) => (
  <FormControl fullWidth>
    <Typography
      style={{ marginLeft: "2px" }}
      fontSize={16}
      fontWeight={600}
      color={"#539d65"}
    >
      {label}
    </Typography>
    <Typography
      style={{ marginLeft: "2px", marginBottom: "5px" }}
      fontSize={14}
      color={"#566986"}
    >
      {helperText}
    </Typography>
    <Select
      disabled={disabled}
      value={form.values[value]}
      multiple={multiple}
      renderValue={renderValue}
      style={style}
      onChange={
        onChange
          ? onChange
          : (e) => form.setFieldValue(`${value}`, e.target.value)
      }
      variant={variant}
    >
      {children}
    </Select>
  </FormControl>
);
