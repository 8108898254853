import React from "react";

import arrow from "../../../../../assets/Vector.png";

import "./Step3.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { Settings } from "@mui/icons-material";

const navigateimulator = {
  menu: "Simulador do Atendente",
  index: 6,
};

const Step3 = ({
  form,
  saveLoading,
  setMenuSelected,
  checkList,
  section1Ref,
  disabled,
  setCheckSave,
  scrollSimulator,
}) => {
  const navigate = useNavigate();

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const handleMenu = (item) => {
    if (
      item.menu === "Simulador do Atendente" &&
      !checkList.partnerDetailsFilled
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      navigate("/simulador");
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    form.setFieldValue(name, value);
    setCheckSave(false);
  };

  const SettingsButton = () => {
    return (
      <Tooltip title={"Configurações Automáticas"}>
        <IconButton
          style={{
            padding: "12px",
            position: "absolute",
            top: 0,
            right: 0,
            borderRadius: "0px 20px 0px 20px",
          }}
          onClick={() => navigate("/configuracoes/automatica")}
        >
          <Settings style={{ color: "#539d65" }} />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <div
      className="containerStep3"
      ref={section1Ref}
      style={{ position: "relative" }}
    >
      {!disabled && <SettingsButton />}
      <div className="titlePage">Produtos e Serviços</div>
      <div className="containerInputs">
        <div className="card">
          <div className="containerInput">
            <div className="title">Produtos do Negócio</div>
            <div className="subTitleInput">
              Descreva os produtos ou itens distintos que seu negócio
              disponibiliza no mercado
            </div>
            <input
              className="inputStyle"
              type="text"
              name="companyProducts"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.companyProducts}
              disabled={disabled}
            />
          </div>

          <div className="containerInput">
            <div className="title">Serviços do Negócio</div>
            <div className="subTitleInput">
              Liste os principais serviços ou soluções oferecidos pelo seu
              negócio
            </div>
            <input
              className="inputStyle"
              type="text"
              name="companyServices"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.companyServices}
              disabled={disabled}
            />
          </div>

          <div className="containerInput">
            <div className="title">Catálogo de Produtos/Serviços</div>
            <div className="subTitleInput">
              Insira o link do seu catálogo para o atendente fornecer ao cliente
              sempre que solicitado
            </div>
            <input
              className="inputStyle"
              type="text"
              name="catalogLink"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.catalogLink}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="card">
          <div className="containerInput">
            <div className="title">Campo de Atuação do Negócio</div>
            <div className="subTitleInput">
              Ex: Serviços de acompanhamento escolar, Serviços financeiros,
              Consultoria nutricional
            </div>
            <input
              className="inputStyle"
              type="text"
              name="servedRegion"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.servedRegion}
              disabled={disabled}
            />
          </div>

          <div className="containerInput">
            <div className="title">Diferenciais Chave</div>
            <div className="subTitleInput">
              Destaque os principais aspectos que tornam seu negócio único no
              mercado
            </div>
            <input
              className="inputStyle"
              type="text"
              name="keyDifferentials"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.keyDifferentials}
              disabled={disabled}
            />
          </div>

          <div className="containerInput">
            <div className="title">Site do Negócio</div>
            <div className="subTitleInput">
              Informe o endereço web do seu negócio, caso já possua um site
            </div>
            <input
              className="inputStyle"
              type="text"
              name="companyContact"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.companyContact}
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      {!disabled && (
        <div className="containerButtons">
          <div
            onClick={() => handleMenu(navigateimulator)}
            className={`buttonSimulator ${
              !checkList?.partnerDetailsFilled && "disabledMenus"
            }`}
          >
            Simular atendimento
          </div>

          <div onClick={form.handleSubmit} className="buttonSave">
            {saveLoading ? (
              "Carregando..."
            ) : (
              <>
                Salvar alterações
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Step3;
