import React, { useContext, useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import unmask from "../../../utils/unmask";
import cardBrand from "../../../utils/cardBrand";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api from "../../../utils/api";

import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";

import "./Checkout.css";
import { Loader } from "../../../components/Loader";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan } = location.state;
  const { user, updateUser } = useContext(AuthContext);
  const [saveCard, setSaveCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCards, setLoadingCards] = useState(true);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [modal, setModal] = useState(false);
  const [cards, setCards] = useState([]);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let width = isMobileDevice() ? 350 : 515;

  const form = useFormik({
    initialValues: {
      cardNumber: "",
      cardExpirationDate: "",
      cardCVV: "",
      cardFullName: "",
    },

    onSubmit: () => registerCard(),
  });

  const getCards = async () => {
    setLoadingCards(true);
    try {
      const { data: cards } = await api.get(`/partners/credit_cards`);

      if (cards) {
        //console.log(cards.data);
        setCards(
          cards.data.map((item, index) => {
            return {
              id: item.id,
              position: index,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados dos cartões.");
    } finally {
      setLoadingCards(false);
    }
  };

  useEffect(() => {
    getCards();
  }, []);

  const registerCard = async () => {
    setSaveCard(true);

    try {
      const { data } = await api.post("/partners/credit_cards", {
        data: {
          attributes: {
            cardNumber: form.values.cardNumber,
            cardHolderName: form.values.cardFullName,
            expiresAt:
              "20" +
              unmask(form.values.cardExpirationDate).slice(2, 4) +
              "-" +
              unmask(form.values.cardExpirationDate).slice(0, 2),
            cardCvv: form.values.cardCVV,
            brand: cardBrand(form.values.cardNumber),
          },
        },
      });

      if (data) {
        setModal(false);
        form.setValues(form.initialValues);
        getCards();
        setSelectedCard(data.data);
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar cadastrar o cartão.");
    } finally {
      setSaveCard(false);
    }
  };

  const Subscribe = async () => {
    setSubscribeLoading(true);
    try {
      const { data } = await api.post("/partners/payment_subscriptions", {
        data: {
          attributes: {
            firstPayDayDate: moment().format("YYYY-MM-DD"),
            additionalInfo: "",
            mainPaymentMethodId: "creditcard",
            creditCardId: selectedCard.id,
            paymentPlanId: plan.id,
          },
        },
      });

      if (data) {
        //console.log(data);
        toast.success("Plano aderido com sucesso!");
        handleSuccess();
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao aderir ao plano.");
    } finally {
      setSubscribeLoading(false);
    }
  };

  const BuyTokens = async () => {
    setSubscribeLoading(true);
    try {
      const { data } = await api.post("/partners/payments", {
        data: {
          attributes: {
            tokenQuantity: plan?.attributes?.tokenQuantity * 1000000,
            payday: moment().format("YYYY-MM-DD"),
            additionalInfo: "teste payment",
            mainPaymentMethodId: "creditcard",
            creditCardId: selectedCard.id,
            value: plan?.amount * 100,
          },
        },
      });

      if (data) {
        //console.log(data);
        toast.success("Compra de Tokens realizada com sucesso!");
        handleSuccess();
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar comprar os tokens.");
    } finally {
      setSubscribeLoading(false);
    }
  };

  const handleSuccess = async () => {
    try {
      const { data } = await api.get(`/partners/partners/${user?.id}`);

      await updateUser(data);

      if (plan?.type === "tokenPurchase") {
        navigate("/financeiro");
      } else if (!user?.active) {
        navigate("/atendente/passoapasso");
      } else {
        navigate("/financeiro");
      }
    } catch (error) {}
  };

  const renderCard = (card) => {
    const lastFourDigits = card.number.substr(-4);

    const maskedString = `xxxx-xxxx-xxxx-${lastFourDigits}`;

    const isCardExpired = (expiryDate) => {
      if (expiryDate) {
        const [year, month] = expiryDate?.split("-").map(Number);

        const expiry = new Date(year, month);

        const now = new Date();
        return expiry < now;
      }
      return "";
    };

    return (
      // <Button
      //   onClick={() => setSelectedCard(card)}
      //   sx={{
      //     display: "flex",
      //     flexDirection: "row",
      //     justifyContent: "space-between",
      //     alignItems: "center",
      //     paddingY: "10px",
      //     paddingX: "5px",
      //     width: "75%",
      //     border:
      //       card?.id === selectedCard?.id
      //         ? "1px solid #566986"
      //         : "1px solid #539d65",
      //     backgroundColor:
      //       card?.id === selectedCard?.id ? "#566986" : "#539d65",
      //     borderRadius: "10px",
      //     paddingLeft: "15px",
      //     marginY: "5px",
      //     ":hover": {
      //       backgroundColor: "#566986",
      //     },
      //     textTransform: "unset",
      //   }}
      // >
      //   <Typography
      //     sx={{
      //       margin: 0,
      //       fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', "sans-serif"],
      //       fontWeight: 500,
      //       fontSize: "1.25rem",
      //       lineHeight: 1.6,
      //       letterSpacing: "0.0075em",
      //       alignSelf: "flex-start",
      //       color: "white",
      //     }}
      //   >
      //     {String(card.brand).toUpperCase()} {maskedString}
      //   </Typography>
      // </Button>
      <div className="containerCredCards" onClick={() => setSelectedCard(card)} style={{ backgroundColor: card?.id === selectedCard?.id ? "#539d65" : ""}}>
        <div className="cardCredit">
          <strong className="text">Bandeira</strong>
          <div className="subtitle">{card?.brand}</div>
        </div>

        <div className="cardCredit">
          <strong className="text">Número</strong>
          <div className="subtitle">{maskedString}</div>
        </div>
      </div>
    );
  };

  return (
    <AuthorizedLayout>
      <div className="containerCheckout">
        <div className="headerCheckout">
          <div className="cardCheckout">
            <div className="CheckoutContainer">
              <div className="Checkout-used">
                <div className="subtitle">Resumo</div>
              </div>

              <div className="plans">
                <div className="containerText">
                  <div className="cicle">o</div>
                  <div>
                    <div className="text">
                      {plan?.type === "tokenPurchase"
                        ? "Tipo de Transação"
                        : "Plano Escolhido"}
                    </div>
                    <div className="subText">{plan.title}</div>
                  </div>
                </div>
              </div>

              <div className="plans">
                <div className="containerText">
                  <div className="cicle">o</div>
                  <div>
                    <div className="text">
                      {plan?.type === "tokenPurchase"
                        ? "Quantidade de Tokens"
                        : "Tipo de Assinatura"}
                    </div>
                    <div className="subText">
                      {plan?.type === "tokenPurchase"
                        ? `${(
                            plan?.attributes?.tokenQuantity * 1000000
                          ).toLocaleString("pt-br")}`
                        : "Mensal"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="plans">
                <div className="containerText">
                  <div className="cicle">o</div>
                  <div>
                    <div className="text">Tipo de Pagamento</div>
                    <div className="subText">Cartão de Crédito</div>
                  </div>
                </div>
              </div>

              <div className="plans">
                <div className="containerText">
                  <div className="cicle">o</div>
                  <div>
                    <div className="text">Valor da Cobrança</div>
                    <div className="subText">
                      {plan?.type === "tokenPurchase"
                        ? `R$ ${Number(plan.amount).toFixed(2)}`
                        : `R$ ${plan.amount}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="containerSlider">
          <div className="cardSlider">
            <div className="titleHea">Escolha seu cartão de crédito</div>

            {loadingCards ? (
              <Loader size={75} style={{ marginBottom: "25px" }} />
            ) : (
              <div className="credcardsAll">
                {cards?.length > 0 && cards.map((item) => renderCard(item))}
              </div>
            )}

            <div
              onClick={() => {
                if (plan?.type === "tokenPurchase") {
                  BuyTokens();
                } else {
                  Subscribe();
                }
              }}
              className="buttonSlide"
            >
              {subscribeLoading
                ? "Carregando..."
                : plan?.type === "tokenPurchase"
                ? `Comprar Tokens`
                : "Aderir ao Plano"}
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Checkout;
