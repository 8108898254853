import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import logo from "../../../assets/altLogo.svg";

import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import api from "../../../utils/api";

export const RecoveryRequest = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    try {
      setLoading(true);

      const { data } = await api.post("/partners/recover_password", {
        data: {
          attributes: {
            email: values.email,
          },
        },
      });
      if (data) {
        toast.success(
          "Email de recuperação enviado com sucesso! Confira sua caixa de entrada!"
        );
        navigate("/");
      }

      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu um erro ao enviar o email de recuperação.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "#f1fcf4",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={logo}
        alt={""}
        style={{ width: 600, marginBottom: "-50px", paddingRight: "15px" }}
      />
      <Typography
        color={"#122137"}
        fontSize={24}
        fontWeight={600}
        style={{
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          marginTop: "-50px",
          marginBottom: "15px",
        }}
      >{`Recuperação de Conta`}</Typography>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginBottom: "15%",
          width: "350px",
        }}
      >
        <form
          onSubmit={form.handleSubmit}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            label={"Email"}
            autoCapitalize={"none"}
            type={"email"}
            onChange={(e) => {
              form.setFieldValue("email", e.target.value);
            }}
            onBlur={() => {
              form.setFieldTouched("email", true);
            }}
          />
          {form.touched.email && form.errors.email && (
            <Typography
              style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
              color={"darkred"}
            >
              {form.errors.email}
            </Typography>
          )}
          <Button variant="contained" type="submit" style={{ marginTop: 15 }}>
            <Typography color={"#fff"}>
              {loading ? "Carregando..." : "Entrar"}
            </Typography>
          </Button>
        </form>
      </div>
    </Box>
  );
};
