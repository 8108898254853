import React, { useEffect, useRef } from "react";

const WppTemplate = ({ messages }) => {
  const formatDate = (dateStr) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Cria o objeto Date usando o formato ISO para evitar problemas de fuso horário
    const inputDate = new Date(dateStr);

    // Ajusta a data para o fuso horário local
    const localInputDate = new Date(
      inputDate.toLocaleString("en-US", { timeZone: "UTC" })
    );

    // Define a data de hoje ajustada para o fuso horário local
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Define uma função para comparar datas sem considerar a hora
    const isSameDay = (d1, d2) => {
      return d1.toDateString() === d2.toDateString();
    };

    if (isSameDay(localInputDate, today)) {
      return "hoje";
    }

    return localInputDate.toLocaleDateString("pt-BR", options);
  };

  const groupedMessages = messages.reduce((group, msg) => {
    const { date } = msg;
    if (!group[date]) {
      group[date] = [];
    }
    group[date].push(msg);
    return group;
  }, {});

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div>
      <div className="chat-box" style={{ minHeight: '40vh'}}>
        <div className="containerGroupMensagens">
          {messages.length <= 0 && (
            <div className="dateHeader">
             Sem histórico de conversas!
            </div>
          )}
          {Object.keys(groupedMessages).map((date, index) => (
            <div key={index} className="containerBoxMenssagens">
              <div className="dateHeader">{formatDate(date)}</div>
              {groupedMessages[date].map((item, msgIndex) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "25px",
                    width: "100%",
                  }}
                >
                  {item.send !== "" && (
                    <div className="message sent">
                      <div>{item.send}</div>
                      <span className="time">{item.time}</span>
                    </div>
                  )}

                  {item.received !== "" && (
                    <div className="message received">
                      <div>{item.received}</div>
                      <span className="time">{item.time}</span>
                    </div>
                  )}
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WppTemplate;
