import { InfoOutlined } from "@mui/icons-material";
import { InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import ReactInputMask from "react-input-mask";

export const InputField = ({
  label,
  value,
  form,
  disabled = false,

  validate = false,

  mask = "",
  maskChar = "",

  helperText = "",

  multiline = false,

  variant = "standard",

  ...props
}) => (
  <>
    {mask ? (
      <ReactInputMask
        mask={mask}
        value={form.values[value]}
        onChange={(e) => form.setFieldValue(`${value}`, e.target.value)}
        onBlur={() => {
          form.setFieldTouched(`${value}`, true);
        }}
        maskChar={maskChar}
        disabled={disabled}
      >
        {() => (
          <TextField
            label={label}
            variant={variant}
            sx={{
              marginBottom: "25px",
            }}
            disabled={disabled}
            InputProps={
              helperText && {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={helperText}>
                      <InfoOutlined />
                    </Tooltip>
                  </InputAdornment>
                ),
              }
            }
            {...props}
          />
        )}
      </ReactInputMask>
    ) : (
      <TextField
        label={label}
        variant={variant}
        sx={{
          marginBottom: "25px",
        }}
        value={form.values[value]}
        onChange={(e) => form.setFieldValue(`${value}`, e.target.value)}
        onBlur={() => {
          form.setFieldTouched(`${value}`, true);
        }}
        multiline={multiline}
        minRows={4}
        maxRows={4}
        disabled={disabled}
        InputProps={
          helperText && {
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={helperText}>
                  <InfoOutlined />
                </Tooltip>
              </InputAdornment>
            ),
          }
        }
        {...props}
      />
    )}

    {validate && form.errors[value] && form.touched[value] && (
      <Typography
        style={{ marginBottom: "10px", marginTop: "-15px" }}
        color={"red"}
      >
        {form.errors[value]}
      </Typography>
    )}
  </>
);
