import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import * as yup from "yup";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import validateCPF from "../../../utils/validateCPF";
import validateCNPJ from "../../../utils/validateCNPJ";
import unmask from "../../../utils/unmask";

import editIcon from "../../../assets/Frame 1 (1).png";
import arrow from "../../../assets/Vector.png";
import iconUser from "../../../assets/noAvatar.svg";

import "./Profile.css";

const Profile = () => {
  const {
    user,
    updateUser,
    updatePartnerDetail,
    signOut,
    checkComplete,
    checkList,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [metrics, setMetrics] = useState({});
  const [subscription, setSubscription] = useState({});
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [modalWarning, setModalWarning] = useState(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState(false);

  const handleDigit = (input) => {
    if (input?.length >= 11 && !input.startsWith("55")) {
      let num;
      num = `55${input}`;
      return num;
    }
    return input;
  };

  const handleDigitAPI = (input) => {
    if (input.startsWith("55")) {
      let num;
      num = input.slice(2);
      return num;
    }
    return input;
  };

  const [tempPhone, setTempPhone] = useState(handleDigit(user.contactNumber));

  const form = useFormik({
    initialValues: {
      name: user?.name || "",
      companyName: user?.companyName || "",
      phone: parsePhoneAdmin(handleDigit(user?.contactNumber)) || "",
      email: user?.email || "",
      avatar: null,
      cpf: user?.document?.length
        ? user?.document?.length === 11
          ? user?.document
          : ""
        : "",
      cnpj: user?.document?.length
        ? user?.document?.length === 11
          ? ""
          : user?.document
        : "",
      documentType: user?.document?.length
        ? user?.document?.length === 11
          ? "cpf"
          : "cnpj"
        : "",
      newPassword: "",
      passwordConfirmation: "",
    },

    validationSchema: yup.object({
      name: yup.string().required("Nome é obrigatório!"),
      phone: yup
        .string()
        .min(14, "Telefone inválido!")
        .required("Telefone de contato é obrigatório!"),

      cpf: yup.string().when("documentType", {
        is: "cpf",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test({
              name: "isValid",
              exclusive: false,
              params: {},
              message: "CPF inválido",
              test: function (value) {
                return validateCPF(value || "");
              },
            }),
      }),

      cnpj: yup.string().when("documentType", {
        is: "cnpj",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test({
              name: "isValid",
              exclusive: false,
              params: {},
              message: "CNPJ inválido",
              test: function (value) {
                return validateCNPJ(value || "");
              },
            }),
      }),
      documentType: yup
        .string()
        .required("Escolher um tipo de documento é obrigatório!"),
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
      newPassword: yup
        .string()
        .min(8, "A nova senha deve conter no mínimo 8 caracteres."),

      passwordConfirmation: yup
        .string()
        .oneOf(
          [yup.ref("newPassword"), ""],
          "As novas senhas devem ser iguais."
        ),
    }),

    onSubmit: (values) => updateSettings(values),
  });

  const updateSettings = async (values) => {
    setSaveLoading(true);

    const dataUser = values.avatar
      ? {
          data: {
            attributes: {
              name: values.name.trim(),
              avatar: { data: values.avatar },
              companyName: values.companyName.trim(),
              contact_number: handleDigitAPI(unmask(values.phone.trim())),
              document:
                values.documentType === "cpf"
                  ? unmask(values.cpf)
                  : values.documentType === "cnpj"
                  ? unmask(values.cnpj)
                  : null,
              password: values.newPassword ? values.newPassword : null,
              passwordConfirmation: values.passwordConfirmation
                ? values.passwordConfirmation
                : null,
            },
          },
        }
      : {
          data: {
            attributes: {
              name: values.name.trim(),
              companyName: values.companyName.trim(),
              contact_number: handleDigitAPI(unmask(values.phone.trim())),
              document:
                values.documentType === "cpf"
                  ? unmask(values.cpf)
                  : values.documentType === "cnpj"
                  ? unmask(values.cnpj)
                  : null,
              password: values.newPassword ? values.newPassword : null,
              passwordConfirmation: values.passwordConfirmation
                ? values.passwordConfirmation
                : null,
            },
          },
        };

    try {
      const { data } = await api.put(`/partners/partners/${user.id}`, {
        ...dataUser,
      });
      if (data) {
        updateUser(data);
        await checkComplete(user.id);
        toast.success("Dados da conta atualizados com sucesso!");
        form.setFieldValue(`avatar`, null);
        if (!checkList.partnerDetailsFilled && !checkList.activePlan) {
          navigate("/financeiro/step1");
        } else if (checkList.partnerDetailsFilled && !checkList.activePlan) {
          navigate("/financeiro/step1");
        } else if (!checkList.partnerDetailsFilled && checkList.activePlan) {
          navigate("/simulador");
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.errors[0]?.title?.message) {
        toast.error(
          `${error?.response?.data?.errors[0]?.title?.message} Por favor entre em contato com o suporte.`
        );
      } else
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
    } finally {
      setSaveLoading(false);
    }
  };

  function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return cnpj;
  }

  const getFirstName = (fullName) => {
    if (!fullName) return ""; // Verifica se a string é vazia ou null
    const names = fullName.split(" ");
    if (names.length < 2) return names[0]; // Verifica se há pelo menos dois nomes
    return `${names[0]} ${names[1]}`;
  };

  const getSubscription = async () => {
    setLoadingSubscription(true);
    try {
      const [data1, data2] = await Promise.all([
        api.get(`partners/payment_subscriptions/last_active_subscription`),
        api.get(`partners/payment_plans`),
      ]);

      const subscriptionData = data1.data;
      const plansData = data2.data;

      if (subscriptionData?.data !== null && plansData) {
        const sub = plansData?.data.find(
          (item) =>
            Number(item.id) ===
            Number(subscriptionData?.data?.attributes?.paymentPlan.id)
        );

        const payDate = moment().diff(
          moment(subscriptionData?.data?.attributes?.firstPayDayDate),
          "months"
        );

        if (subscriptionData) {
          const { data } = await api.get("partners/montly_usage_history");
          setMetrics(data?.data?.usageStatistics);
        }

        setSubscription({
          name: sub?.attributes?.name,
          periodicity: sub?.attributes?.periodicity,
          planPricePayment: sub?.attributes?.planPricePayment,
          planPriceValue: sub?.attributes?.planPriceValue,
          payDate: moment(
            subscriptionData?.data?.attributes?.firstPayDayDate
          ).add(payDate + 1, "months"),
        });
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados da assinatura.");
    } finally {
      setLoadingSubscription(false);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  useEffect(() => {
    setTempPhone(form.values.phone);
  }, [form.values.phone]);

  const deleteAccount = async () => {
    setLoadingDeleteAccount(true);
    try {
      await api.delete(`/partners/partners/${user.id}`);
      setModalWarning(false);
      signOut(0);
    } catch (error) {
    } finally {
      setLoadingDeleteAccount(false);
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        // console.log("File Is", file);
        form.setFieldValue(`avatar`, result);
      })
      .catch((error) => {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(`${error.response.data.errors[0].title}`);
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      });
  };

  return (
    <AuthorizedLayout>
      <div className="containerProfile">
        <div className="headerProfile">
          <div className="cardProfile">
            <div className="containerImg">
              <img
                src={form.values.avatar || user?.avatar || iconUser}
                alt="usuario"
                className="iconUser"
              />
              <label className="form-group button" htmlFor="fupload">
                <img
                  src={editIcon}
                  alt="edit"
                  className="editIcon"
                  htmlFor="fupload"
                />
                <input
                  type="file"
                  id="fupload"
                  name="fupload"
                  className="fupload form-control"
                  accept=".jpeg, .png, .jpg"
                  onChange={handleFileInputChange}
                />
              </label>
            </div>
            <div className="title">{getFirstName(form.values.name)}</div>
            <Tooltip title={form.values.email}>
              <div className="containerText">
                <strong>E-mail:</strong>
                <span>{form.values.email}</span>
              </div>
            </Tooltip>
            <div>
              <strong>Telefone:</strong> {`${form.values.phone}`}
            </div>
            <div>
              <strong>CPF/CNPJ:</strong>{" "}
              {form.values.documentType === "cpf"
                ? formatCPF(form.values.cpf) || ""
                : formatCNPJ(form.values.cnpj) || ""}
            </div>

            <div
              onClick={() => {
                setModalWarning(true);
              }}
              className="buttonDelete"
              style={{ alignSelf: "flex-start" }}
            >
              Excluir conta
            </div>
          </div>

          <div className="cardProfile">
            <div>
              <strong>Seu Negócio:</strong> {form.values.companyName}
            </div>
            <div>
              <strong>Seu Plano:</strong>{" "}
              {subscription?.name || "Nenhum plano selecionado"}
            </div>
            <div>
              <strong>Tokens disponíveis:</strong>{" "}
              {Number(metrics?.remainingTokens || 0)?.toLocaleString()}
            </div>

            <div
              onClick={() => navigate("/financeiro/step1")}
              className="buttonNextCRedCard"
              style={{ alignSelf: "flex-start" }}
            >
              Alterar Plano
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </div>

        {modalWarning && (
          <Modal
            open
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setModalWarning("false");
              }
            }}
          >
            <Box
              style={{
                background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                borderRadius: "20px",
              }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: isMobileDevice() ? 350 : 700,
                // height: 200,
                p: 2.5,
              }}
            >
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      margin: 0,
                      fontFamily: [
                        '"Roboto"',
                        '"Helvetica"',
                        '"Arial"',
                        "sans-serif",
                      ],
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.0075em",
                      alignSelf: "flex-start",
                      marginTop: "15px",
                      marginBottom: "15px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={28}
                      fontWeight={600}
                      color={"#e52207"}
                    >
                      ATENÇÃO
                    </Typography>
                  </Typography>

                  <Typography
                    textAlign={"center"}
                    fontWeight={"600"}
                    style={{ marginBottom: "25px" }}
                    fontSize={20}
                  >
                    {
                      "Tem certeza de que deseja excluir sua conta? Esta ação é permanente e não pode ser desfeita."
                    }
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                        backgroundColor: "#e52207",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        deleteAccount();
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {loadingDeleteAccount ? "EXCLUINDO..." : "EXCLUIR"}
                      </Typography>
                    </Button>

                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        setModalWarning(false);
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {"CANCELAR"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        <div className="containerInputsProfile">
          <div className="title">Editar dados da conta</div>
          <form className="containerCard">
            <div className="card">
              <div className="containerInput">
                <div className="title">E-mail</div>
                <div className="subTitleInput">
                  Digite o email para login e controle interno
                </div>
                <input
                  className="inputCredCard"
                  type="email"
                  name="email"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.email}
                  autoComplete="new-password"
                />
                {form.touched.email && form.errors.email ? (
                  <div>{form.errors.email}</div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Telefone de Contato</div>
                <div className="subTitleInput">
                  Digite o telefone a ser utilizado para informes
                </div>
                <InputMask
                  className="inputCredCard"
                  mask={"+99 99 99999-9999"}
                  name="phone"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.phone}
                  autoComplete="new-password"
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
                {form.touched.phone && form.errors.phone ? (
                  <div>{form.errors.phone}</div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Senha</div>
                <div className="subTitleInput">
                  Defina a senha de acesso à sua conta
                </div>
                <input
                  className="inputCredCard"
                  type="password"
                  name="newPassword"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.newPassword}
                  autoComplete="new-password"
                />
                {form.touched.newPassword && form.errors.newPassword ? (
                  <div>{form.errors.newPassword}</div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="subTitleInput">Confirme a senha</div>
                <input
                  className="inputCredCard"
                  type="password"
                  name="passwordConfirmation"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.passwordConfirmation}
                  autoComplete="new-password"
                />
                {form.touched.passwordConfirmation &&
                form.errors.passwordConfirmation ? (
                  <div>{form.errors.passwordConfirmation}</div>
                ) : null}
              </div>
            </div>

            <div className="line"></div>

            <div className="card">
              <div className="containerInput">
                <div className="title">Nome</div>
                <div className="subTitleInput">Digite aqui o seu nome</div>
                <input
                  className="inputCredCard"
                  type="text"
                  name="name"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.name}
                  autoComplete="new-password"
                />
                {form.touched.name && form.errors.name ? (
                  <div>{form.errors.name}</div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Nome do Negócio</div>
                <div className="subTitleInput">
                  Digite o nome do seu negócio para controle interno
                </div>
                <input
                  className="inputCredCard"
                  type="text"
                  name="companyName"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.companyName}
                  autoComplete="new-password"
                />
                {form.touched.companyName && form.errors.companyName ? (
                  <div>{form.errors.companyName}</div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Tipo de Documento</div>
                <div className="subTitleInput">
                  Selecione o tipo de documento a ser inserido
                </div>

                <RadioGroup
                  row
                  value={form.values.documentType}
                  style={{
                    height: "56px",
                    display: "flex",
                    gap: "20px",
                  }}
                  onClick={(e) => {
                    if (e.target.value)
                      form.setFieldValue("documentType", e.target.value);
                  }}
                >
                  <label className="checkbox-container">
                    <div
                      className="checkmarkBackground"
                      style={{ borderRadius: "15px" }}
                    >
                      <input
                        type="checkbox"
                        value="cpf"
                        checked={form?.values?.documentType === "cpf"}
                      />
                      <span
                        className="checkmark"
                        style={{ borderRadius: "15px" }}
                      ></span>
                    </div>
                    <div>CPF</div>
                  </label>
                  {/* <FormControlLabel
                    value="cpf"
                    control={<Radio />}
                    label="CPF"
                    className="subTitle"
                  /> */}

                  <label className="checkbox-container">
                    <div
                      className="checkmarkBackground"
                      style={{ borderRadius: "15px" }}
                    >
                      <input
                        type="checkbox"
                        checked={form?.values?.documentType === "CNPJ"}
                        value="CNPJ"
                      />
                      <span
                        className="checkmark"
                        style={{ borderRadius: "15px" }}
                      ></span>
                    </div>
                    <div>CNPJ</div>
                  </label>
                  {/* <FormControlLabel
                    value="cnpj"
                    control={<Radio />}
                    label="CNPJ"
                    className="subTitle"
                  /> */}
                </RadioGroup>
                {form.touched.documentType && form.errors.documentType ? (
                  <div>{form.errors.documentType}</div>
                ) : null}

                <div className="title">Número do Documento</div>
                <div className="subTitleInput">
                  Digite o número do seu documento
                </div>
                <div className="containerInput">
                  <InputMask
                    className="inputCredCard"
                    mask={
                      form.values.documentType === "cpf"
                        ? "999.999.999-99"
                        : form.values.documentType === "cnpj"
                        ? "99.999.999/9999-99"
                        : ""
                    }
                    name={form.values.documentType}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    autoComplete="new-password"
                    value={
                      form.values.documentType === "cpf"
                        ? form.values.cpf
                        : form.values.documentType === "cnpj"
                        ? form.values.cnpj
                        : null
                    }
                  >
                    {(inputProps) => <input {...inputProps} type="text" />}
                  </InputMask>
                  {form.values.documentType === "cpf" ? (
                    form.touched.cpf && form.errors.cpf ? (
                      <div>{form.errors.cpf}</div>
                    ) : null
                  ) : form.touched.cnpj && form.errors.cnpj ? (
                    <div>{form.errors.cnpj}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>

          <div onClick={form.handleSubmit} className="buttonNextCRedCard">
            {saveLoading ? (
              "Carregando..."
            ) : (
              <>
                Salvar alterações
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Profile;
