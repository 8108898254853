import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { AuthContext } from "../../providers/AuthProvider";

// Import Material Icons
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonIcon from "@mui/icons-material/Person";

import image1 from "../../assets/fuundo_ondas (1).png";
import image2 from "../../assets/fuundo_ondas.png";
import logo from "../../assets/Frame 1.png";
import arrow from "../../assets/Vector.png";

import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const { userType } = useParams();
  const { signIn, signInAdmin, signInSales } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const handleType = () => {
    switch (userType) {
      case "admin":
        return "Administrador";
      case "vendas":
        return "Afiliado";
      default:
        return "Parceiro";
    }
  };

  const type = handleType();

  const handleInv = () => {
    switch (userType) {
      case "admin":
        return ["Parceiro", "Afiliado"];
      case "vendas":
        return ["Parceiro", "Administrador"];
      default:
        return ["Afiliado", "Administrador"];
    }
  };

  const invType = handleInv();

  // Determine the icon based on user type
  const getIcon = (userType) => {
    switch (userType) {
      case "Administrador":
        return <AdminPanelSettingsIcon className="buttonIcon" />;
      case "Afiliado":
        return <GroupAddIcon className="buttonIcon" />;
      default:
        return <PersonIcon className="buttonIcon" />;
    }
  };

  // Icon for the current login type
  const icon = getIcon(type);

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("O campo e-mail é obrigatório!"),
      password: yup
        .string()
        .min(8, "Senha deve conter no mínimo 8 caracteres.")
        .required("O campo senha é obrigatório!"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        setErrorMessage(""); // Clear any previous error message
        if (type === "Administrador") {
          await signInAdmin(values);
        } else if (type === "Afiliado") {
          await signInSales(values);
        } else {
          await signIn(values);
        }
      } catch (error) {
        // Handle error by setting the error message
        setErrorMessage(
          "E-mail ou senha inválidos. Por favor, tente novamente."
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const renderInputField = (label, name, type = "text") => (
    <div className="inputGroup">
      <div className="labelInputLogin">{label}</div>
      <input
        className="inputLogin"
        type={type}
        name={name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={form.values[name]}
      />
      {form.touched[name] && form.errors[name] && (
        <Typography className="errorText">{form.errors[name]}</Typography>
      )}
    </div>
  );

  return (
    <div className="containerLogin">
      <header className="headerLogin">
        <img src={logo} alt="Logo" className="logoMain" />
        <div className="userTypeButtons">
          {invType
            .filter((item) => item !== "Administrador")
            .map((inv, index) => (
              <button
                key={index}
                className="buttonTypeLogin"
                onClick={() => {
                  if (inv === "Administrador") {
                    navigate("/admin");
                  } else if (inv === "Afiliado") {
                    navigate("/vendas");
                  } else {
                    navigate("/");
                  }
                }}
              >
                {getIcon(inv)}
                Entrar como {inv}
              </button>
            ))}
        </div>
      </header>

      <img src={image1} className="image1" alt="Background decoration" />
      <img src={image2} className="image2" alt="Background decoration" />

      <div className="containerCenterLogin">
        <div className="boxLogin">
          <div className="loginTitle">
            {icon}
            Entrar como {type}
          </div>
          <form onSubmit={form.handleSubmit} className="formLogin">
            {renderInputField("E-mail", "email", "email")}
            {renderInputField("Senha", "password", "password")}

            {/* Display error message if exists */}
            {errorMessage && <div className="errorMessage">{errorMessage}</div>}

            <button type="submit" className="buttonLogin">
              {loading ? (
                "Carregando..."
              ) : (
                <div className="buttonContent">
                  Entrar
                  <img src={arrow} alt="Seta para a direita" />
                </div>
              )}
            </button>
          </form>
        </div>

        {/* Existing link for "Parceiro" users */}
        {type === "Parceiro" && (
          <button
            onClick={() => navigate("/recuperacao")}
            className="buttonLink"
          >
            Esqueci minha senha
          </button>
        )}

        {type === "Parceiro" && (
          <button onClick={() => navigate("/cadastro")} className="buttonLink">
            Não é nosso parceiro?{" "}
            <strong style={{ textDecoration: "underline" }}>
              Cadastre-se agora!
            </strong>
          </button>
        )}

        {/* New link for "Afiliado" users */}
        {type === "Afiliado" && (
          <button
            onClick={() => navigate("/vendas/cadastro")}
            className="buttonLink"
          >
            Não é nosso afiliado?{" "}
            <strong style={{ textDecoration: "underline" }}>
              Cadastre-se agora!
            </strong>
          </button>
        )}
      </div>

      <footer className="footerLogin">
        sacgpt.com.br © 2024 - Todos os Direitos Reservados
      </footer>
    </div>
  );
};

export default Login;
