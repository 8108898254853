import { Circle, Close, Info, WhatsApp, ZoomIn } from "@mui/icons-material";
import { Button, Chip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api, { devEnv } from "../../../utils/api";
import { Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import ModalAlert from "../../../components/ModalAlert";
import parsePhone from "../../../utils/parsePhone";
import moment from "moment";

export const ClientList = () => {
  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  // const [clients, setClients] = useState([
  //   {
  //     id: 1,
  //     name: "João Victor Capistrano da Silva",
  //     phone: "(22) 9 8813-3721",
  //   },
  //   { id: 2, name: "Heloise Aline Viana", phone: "(77) 9 8701-9762" },
  //   { id: 3, name: "Erick Lucca Cláudio Farias", phone: "(86) 9 8404-0456" },
  //   { id: 4, name: "Hugo Paulo da Silva", phone: "(63) 9 8764-5360" },
  //   { id: 5, name: "Roberto Noah Igor Martins", phone: "(19) 9 8251-0940" },
  //   {
  //     id: 6,
  //     name: "Marcos Vinicius Oliver Vicente da Costa",
  //     phone: "(98) 9 9610-4492",
  //   },
  //   {
  //     id: 7,
  //     name: "Bernardo Mário Benjamin Carvalho",
  //     phone: "(82) 9 8742-7997",
  //   },
  // ]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientId, setClientId] = useState(null);
  const [modalAlert, setModalAlert] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log(selectedClient);
  // }, [selectedClient]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
      },
    },

    {
      name: "clientName",
      label: "Nome",
      options: {
        filter: false,
      },
    },

    {
      name: "phoneNumber",
      label: "Telefone",
      options: {
        filter: false,
        customBodyRender: (phoneNumber, { rowData }) => {
          return parsePhone(phoneNumber);
        },
      },
    },

    {
      name: "lead",
      label: "Classificação de Interesse",
      options: {
        filterOptions: {
          fullWidth: true,
        },
      },
    },

    {
      name: "lastMessageParsed",
      label: "Último Contato",
      options: {
        sort: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRender: (_value, { rowData }) => {
          return (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography fontSize={14}>{rowData[6]}</Typography>
              <Circle
                style={{ width: "6px", marginRight: "5px", marginLeft: "5px" }}
              />
              <Typography fontSize={14}>{rowData[7]}</Typography>
            </div>
          );
        },
      },
    },

    {
      name: "actions",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, { rowData }) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={"Ver mensagens"}>
                <Button onClick={() => navigate(`/chat/${rowData[0]}`)}>
                  <WhatsApp color="primary" />
                </Button>
              </Tooltip>
              {devEnv && (
                <Tooltip title={"Excluir cliente"}>
                  <Button
                    onClick={() => {
                      setModalAlert(true);
                      setClientId(rowData[0]);
                    }}
                  >
                    <Close color="error" />
                  </Button>
                </Tooltip>
              )}
            </div>
          );
        },
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
      },
    },

    {
      name: "lastMessageParsedDate",
      label: "lastMessageParsedDate",
      options: {
        display: "excluded",
      },
    },

    {
      name: "lastMessageParsedTime",
      label: "lastMessageParsedTime",
      options: {
        display: "excluded",
      },
    },
  ];

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("partners/partner_clients");

      if (data) {
        //console.log(data);
        setClients(
          data.data.map((item) => {
            return {
              id: Number(item.id),
              clientName: item.attributes?.name || "Nome não informado",
              phoneNumber: item.attributes?.phone || "Número não informado",
              lead: item.attributes?.leadScore || "Inconclusivo",
              lastMessageParsedTime:
                moment(item.attributes?.lastMessage).format("HH:mm") ||
                "Inconclusivo",
              lastMessageParsedDate:
                moment(item.attributes?.lastMessage).format("DD/MM/YYYY") ||
                "Inconclusivo",
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`partners/partner_clients/${id}`);
      setClients(clients.filter((client) => client.id !== id));
      toast.success("Cliente deletado com sucesso!");
      setClientId(null);
    } catch (error) {
      if (error?.response?.data?.errors[0]?.title)
        toast.error(
          `Erro ao deletar o cliente! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error("Erro ao deletar o cliente!");
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <AuthorizedLayout>
      <div style={{ margin: "24px" }}>
        {loading ? (
          <Loader size={150} />
        ) : (
          <>
            <MUIDataTable
              title={""}
              data={clients}
              columns={columns}
              options={{
                selectableRows: "none",
                tableBodyHeight: "65vh",
                sortFilterList: false,
                filter: true,
                textLabels: {
                  body: {
                    noMatch: "Nenhum registro encontrado.",
                    toolTip: "Ordenar",
                    columnHeaderTooltip: (column) =>
                      `Ordenar por ${column.label}`,
                  },
                  pagination: {
                    next: "Página Seguinte",
                    previous: "Página Anterior",
                    rowsPerPage: "Linhas por página:",
                    displayRows: "de",
                  },
                  toolbar: {
                    search: "Pesquisar",
                    downloadCsv: "Fazer download de CSV",
                    print: "Imprimir",
                    viewColumns: "Ver Colunas",
                    filterTable: "Filtrar Tabela",
                  },
                  filter: {
                    all: "Todos",
                    title: "FILTROS",
                    reset: "Reiniciar",
                  },
                  viewColumns: {
                    title: "Mostrar Colunas",
                    titleAria: "Mostrar/Ocultar Colunas da Tabela",
                  },
                  selectedRows: {
                    text: "linha(s) selecionada(s)",
                    delete: "Apagar",
                    deleteAria: "Apagar linhas selecionadas",
                  },
                },
              }}
            />
          </>
        )}
        <ModalAlert
          handleClose={() => setModalAlert(false)}
          handleSubmit={() => handleDelete(clientId)}
          open={modalAlert}
        >
          <Typography variant={isMobileDevice ? "h7" : "h6"}>
            Tem certeza que deseja excluir esse cliente? Essa ação é
            irreversível e é destinada a testes.
          </Typography>
        </ModalAlert>
      </div>
    </AuthorizedLayout>
  );
};
