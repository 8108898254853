import { CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { AuthProvider } from "./providers/AuthProvider";
import { Routes } from "./routes";
import { theme } from "./theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import NewPlanProvider from "./screens/PartnerScreens/Finance/Context/NewPlanProvider";
import TagManager from "react-gtm-module";
// import Hotjar from "./utils/hotjar";

const tagManagerArgs = {
  gtmId: "GTM-K83X34XT",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          {/* <NewPlanProvider> */}
          <CssBaseline />
          {/* <Hotjar /> */}
          <Routes />
          <ToastContainer />
          {/* </NewPlanProvider> */}
        </AuthProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
