import { Check, Close, Info, WhatsApp, ZoomIn } from "@mui/icons-material";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api, { devEnv } from "../../../utils/api";
import { Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ModalAlert from "../../../components/ModalAlert";
import { InputField } from "../../../components/InputField";
import { useFormik } from "formik";
import { useTheme } from "@emotion/react";
import moment from "moment";

export const Subscription = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { hash } = useParams();
  const [plans, setPlans] = useState([
    // {
    //   id: parseInt(Math.random() * 100000 + 1000),
    //   title: "Plano Básico",
    //   description: "Para pequenas soluções",
    //   amount: 19.99,
    //   benefits: [
    //     "Atendente Virtual Personalizável",
    //     "Painel Administrativo",
    //     "Históricos de Conversa com Clientes",
    //   ],
    // },
    // {
    //   id: parseInt(Math.random() * 100000 + 1000),
    //   title: "Plano Enterprise",
    //   description: "Para necessidades robustas",
    //   amount: 59.99,
    //   benefits: [
    //     "Atendente Virtual Personalizável",
    //     "Painel Administrativo",
    //     "Históricos de Conversa com Clientes",
    //     "Resumo Inteligente de Conversas",
    //     "Classificação de Interesse do Lead",
    //     "Agendamento automático de reuniões",
    //   ],
    // },
  ]);
  //   const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const form = useFormik({
    initialValues: {
      nameAttendant: "",
    },
  });

  // useEffect(() => {
  //   console.log(selectedClient);
  // }, [selectedClient]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
      },
    },

    {
      name: "amount",
      label: "Valor",
      options: {
        filter: false,
        customBodyRender: (_value, { rowData }) => {
          if (rowData[3] === "Saída") {
            return (
              <Typography fontSize={14} color={"red"}>
                - R$ {Number(_value).toFixed(2)}
              </Typography>
            );
          } else {
            return (
              <Typography fontSize={14} color={"#539d65"}>
                + R$ {Number(_value).toFixed(2)}
              </Typography>
            );
          }
        },
      },
    },

    {
      name: "description",
      label: "Descrição",
      options: {
        filter: false,
      },
    },

    {
      name: "type",
      label: "Tipo de Transação",
      options: {
        filter: true,
        display: false,
        filterOptions: {
          fullWidth: true,
        },
      },
    },

    {
      name: "date",
      label: "Data e Hora",
      options: {
        filter: false,
        sort: false,
      },
    },

    // {
    //   name: "actions",
    //   label: "Ações",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (_value, { rowData }) => {
    //       return (
    //         <div style={{ display: "flex", justifyContent: "center" }}>
    //           <Tooltip title={"Ver mensagens"}>
    //             <Button onClick={() => navigate(`/chat/${rowData[0]}`)}>
    //               <WhatsApp color="primary" />
    //             </Button>
    //           </Tooltip>
    //           <Tooltip title={"Excluir cliente"}>
    //             <Button
    //               onClick={() => {
    //                 setModalAlert(true);
    //                 setClientId(rowData[0]);
    //               }}
    //             >
    //               <Close color="error" />
    //             </Button>
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     setCellHeaderProps: () => {
    //       return { align: "center" };
    //     },
    //     setCellProps: () => {
    //       return { align: "center" };
    //     },
    //   },
    // },
  ];

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`partners/payment_plans`);
      if (data) {
        setPlans(
          data.data
            .filter((item) => !item.attributes.disable)
            .map((item) => {
              return {
                title: item.attributes.name,
                description: item.attributes.additionalInfo,
                amount: Number(item.attributes.planPriceValue) / 100,
                benefits:
                  item.attributes.name === "Plano Básico"
                    ? [
                        "Atendente Virtual Personalizável",
                        "Painel Administrativo",
                        "Históricos de Conversa com Clientes",
                      ]
                    : [
                        "Atendente Virtual Personalizável",
                        "Painel Administrativo",
                        "Históricos de Conversa com Clientes",
                        "Resumo Inteligente de Conversas",
                        "Classificação de Interesse do Lead",
                        "Agendamento automático de reuniões",
                      ],
                ...item,
              };
            })
        );
        //console.log(data.data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados do parceiro.");
    } finally {
      setLoading(false);
    }
  };

  const PlanCard = ({ plan }) => {
    let size = plan.benefits.length;
    let half = Math.ceil(size / 2);

    let benefitsA = plan.benefits.slice(0, half);
    let benefitsB = plan.benefits.slice(half);

    return (
      <Box
        style={{
          display: "flex",
          width: "40%",
          marginRight: "10px",
          marginLeft: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#f1fcf4",

            justifyContent: "center",
            alignItems: "center",
            marginBottom: "25px",
            borderRadius: "15px",
            boxShadow: 15,
            cursor: "pointer",
            ":hover": {
              opacity: "75%",
            },
            height: "600px",
          }}
          onClick={() => {
            navigate("/carrinho", { state: { plan } });
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
              borderTopRightRadius: "15px",
              borderTopLeftRadius: "15px",
              borderBottom: "1px solid #539d65",
              width: "100%",
              paddingBottom: "10px",
              backgroundColor: "#539d65",
            }}
          >
            <Typography fontSize={32} color={"white"}>
              {plan?.title}
            </Typography>
            <Typography
              style={{
                marginRight: "30px",
                marginLeft: "30px",
                textAlign: "center",
                borderTop: "1px solid #f1fcf4",
                paddingTop: "10px",
                marginTop: "5px",
              }}
              color={"white"}
            >
              {plan.description}
            </Typography>
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "600px",
              borderLeft: "1px solid #539d65",
              borderRight: "1px solid #539d65",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                paddingY: "10px",
                paddingX: "5px",

                // borderLeft: "1px solid #539d65",
                // borderRight: "1px solid #539d65",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography fontSize={18} sx={{ marginBottom: "5px" }}>
                  Franquia mensal de{" "}
                  <span style={{ color: "#539d65" }}>
                    {Number(plan?.attributes?.maxTokenCount).toLocaleString()}
                  </span>{" "}
                  tokens
                </Typography>
                <Typography fontSize={18} sx={{ marginY: "5px" }}>
                  +{" "}
                  <span style={{ color: "#539d65" }}>
                    R${" "}
                    {Number(
                      plan?.attributes?.costPerThousandToukens / 100
                    ).toLocaleString()}
                  </span>{" "}
                  a cada <span style={{ color: "#539d65" }}>mil</span> tokens
                  extra
                </Typography>
              </div>
              <div
                style={{
                  borderTop: "1px solid #539d65",
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              ></div>
              {plan.benefits.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Check
                    style={{
                      color: "#539d65",
                      height: "25px",
                      width: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <Typography fontSize={18} key={index} sx={{ marginY: "5px" }}>
                    {item}
                  </Typography>
                </div>
              ))}
              {/* <Grid container spacing={1} sx={{ height: "100%" }}>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {benefitsA.map((item, index) => (
                  <Typography key={index} sx={{ marginY: "5px" }}>
                    {item}
                  </Typography>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {benefitsB.map((item, index) => (
                  <Typography key={index} sx={{ marginY: "5px" }}>
                    {item}
                  </Typography>
                ))}
              </Grid>
            </Grid> */}
            </Box>
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px",
              //   borderTop: "1px solid #539d65",
              borderRadius: "0px 0px 15px 15px",
              border: "1px solid #539d65",
              width: "100%",
              paddingTop: "10px",
            }}
          >
            <Typography fontSize={24} color={"#122137"}>
              R$ {Number(plan.amount).toFixed(2)} /mês
            </Typography>
            <Typography fontSize={20} color={"#539d65"}>
              Assine já
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loader size={150} />
      ) : (
        <div style={{ marginBottom: "50px" }}>
          <Box
            sx={{
              display: "flex",
              top: "10%",
              // bgcolor: "white",
              minWidth: "650px",
              //height: "45%",
              flexDirection: "row",

              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              // boxShadow: 10,
              p: 4,
              marginBottom: 2,
            }}
          >
            {/* {plans.map((item, index) => {
              if (item?.title === "SacGPT Essential") {
                return <PlanCard key={index} plan={item} />;
              } else if (item?.title === "SacGPT Free Trial") {
                if (hash === "trialgpt")
                  return <PlanCard key={index} plan={item} />;
                else return null;
              } else if (devEnv) {
                return <PlanCard key={index} plan={item} />;
              } else return null;
            })} */}

            {plans.map((item, index) => {
              return <PlanCard key={index} plan={item} />;
            })}
          </Box>
        </div>
      )}
    </AuthorizedLayout>
  );
};
