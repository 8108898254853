import React, { useContext, useEffect, useState } from "react";
import NewPlanContext from "./Context/NewPlanContext";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import cardBrand from "../../../utils/cardBrand";
import InputMask from "react-input-mask";

import iconCard from "../../../assets/CreditCard.png";
import arrow from "../../../assets/Vector.png";

import "./NewCreditCard.css";
import { toast } from "react-toastify";
import api from "../../../utils/api";
import unmask from "../../../utils/unmask";

const NewCreditCard = () => {
  const navigate = useNavigate();
  const [saveCard, setSaveCard] = useState(false);

  const NewCreditCardForm = useFormik({
    initialValues: {
      cardFullName: "",
      cardNumber: "",
      cardExpirationDate: "",
      cardCVV: "",
    },
    validationSchema: yup.object({
      cardFullName: yup
        .string()
        .required("Nome no cartão é obrigatório")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      cardNumber: yup
        .string()
        .required("Número do cartão é obrigatório")
        .matches(
          /^[0-9\s]{19}$/,
          "Número do cartão deve estar no formato 9999 9999 9999 9999"
        ),
      cardExpirationDate: yup
        .string()
        .required("Data de expiração é obrigatória")
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Data de expiração deve estar no formato MM/AA"
        )
        .test("expDate", "Data de expiração inválida", (value) => {
          if (!value) return false;
          const today = new Date();
          const [month, year] = value.split("/").map(Number);
          const expDate = new Date(`20${year}`, month - 1);
          return expDate >= today;
        }),
      cardCVV: yup
        .string()
        .required("CVV é obrigatório")
        .matches(/^[0-9]{3,4}$/, "CVV deve ter 3 dígitos"),
    }),
    onSubmit: () => registerCard(),
  });

  const registerCard = async () => {
    setSaveCard(true);
    try {
      const { data } = await api.post("/partners/credit_cards", {
        data: {
          attributes: {
            cardNumber: NewCreditCardForm.values.cardNumber,
            cardHolderName: NewCreditCardForm.values.cardFullName,
            expiresAt:
              "20" +
              unmask(NewCreditCardForm.values.cardExpirationDate).slice(2, 4) +
              "-" +
              unmask(NewCreditCardForm.values.cardExpirationDate).slice(0, 2),
            cardCvv: NewCreditCardForm.values.cardCVV,
            brand: cardBrand(NewCreditCardForm.values.cardNumber),
          },
        },
      });

      if (data) {
        navigate(-1);
        return toast.success("Cartão alterado com sucesso.");
      }
    } catch (error) {
      if (error.response.data.errors[0].title) {
        toast.error(error.response.data.errors[0].title);
      }
      return toast.error("Ocorreu um erro ao tentar cadastrar o cartão.");
    } finally {
      setSaveCard(false);
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerCredCard2">
        <div className="title">
          <img src={iconCard} alt="card" />
          ALTERAR OPÇÃO DE PAGAMENTO
        </div>
        <div className="subTitle">
          Insira os dados do seu Cartão de Crédito
        </div>

        <form className="containerCard">
          <div className="card">
            <div className="containerInput">
              <div className="title">Nome do Titular do Cartão</div>
              <input
                className="inputCredCard"
                type="text"
                name="cardFullName"
                onChange={NewCreditCardForm.handleChange}
                onBlur={NewCreditCardForm.handleBlur}
                value={NewCreditCardForm.values.cardFullName}
              />
              {NewCreditCardForm.touched.cardFullName &&
              NewCreditCardForm.errors.cardFullName ? (
                <div>{NewCreditCardForm.errors.cardFullName}</div>
              ) : null}
            </div>

            <div className="containerInput">
              <div className="title">Número do Cartão</div>
              <InputMask
                className="inputCredCard"
                mask="**** **** **** 9999"
                name="cardNumber"
                onChange={NewCreditCardForm.handleChange}
                onBlur={() => {
                  NewCreditCardForm.setFieldTouched("cardNumber", true);
                }}
                value={NewCreditCardForm.values.cardNumber}
              >
                {(inputProps) => <input {...inputProps} type="text" />}
              </InputMask>
              {NewCreditCardForm.touched.cardNumber && NewCreditCardForm.errors.cardNumber ? (
                <div>{NewCreditCardForm.errors.cardNumber}</div>
              ) : null}
            </div>
          </div>

          <div className="line"></div>

          <div className="card">
            <div className="containerInput">
              <div className="title">Validade</div>
              <InputMask
                className="inputCredCard"
                mask="99/99"
                name="cardExpirationDate"
                onChange={NewCreditCardForm.handleChange}
                onBlur={NewCreditCardForm.handleBlur}
                value={NewCreditCardForm.values.cardExpirationDate}
              >
                {(inputProps) => <input {...inputProps} type="text" />}
              </InputMask>
              {NewCreditCardForm.touched.cardExpirationDate &&
              NewCreditCardForm.errors.cardExpirationDate ? (
                <div>{NewCreditCardForm.errors.cardExpirationDate}</div>
              ) : null}
            </div>

            <div className="containerInput">
              <div className="title">Código de Segurança</div>
              <InputMask
                className="inputCredCard"
                mask="999"
                name="cardCVV"
                onChange={NewCreditCardForm.handleChange}
                onBlur={NewCreditCardForm.handleBlur}
                value={NewCreditCardForm.values.cardCVV}
              >
                {(inputProps) => <input {...inputProps} type="text" />}
              </InputMask>
              {NewCreditCardForm.touched.cardCVV && NewCreditCardForm.errors.cardCVV ? (
                <div>{NewCreditCardForm.errors.cardCVV}</div>
              ) : null}
            </div>
          </div>
        </form>

        <div className="containerButtonsSumary">
          <div
            onClick={() => navigate(-1)}
            className="buttonBackCRedCard"
          >
            <img src={arrow} alt="arrow" />
            Voltar
          </div>

          <div onClick={NewCreditCardForm.handleSubmit} className="buttonNextCRedCard">
            {saveCard ? (
              "Carregando..."
            ) : (
              <>
                Salvar
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default NewCreditCard;
