import React from "react";
import { useNavigate } from "react-router-dom";

import userIcon from "../../../../assets/User.png";
import arrowDown from "../../../../assets/CaretDown.png";

import "./LeadsCard.css";

const LeadsCard = ({ reports }) => {
  const navigate = useNavigate();

  return (
    <div className="containerCardLeads">
      {reports?.overview?.clientScores?.length > 0 ? (
        reports?.overview?.clientScores
          .sort((a, b) => b.clientScore - a.clientScore)
          .map((data, index) => {
            const color = index % 2 === 0 && "#FFFFFF";
            return (
              <div
                key={index}
                className="card"
                style={{ backgroundColor: color }}
                onClick={() => {
                  navigate(`/chat/${data?.client?.data?.id}`);
                }}
              >
                <div className="containerUser">
                  <img src={userIcon} alt="user" className="user" />
                  <div className="text">
                    {data?.client?.data?.attributes?.name || "Não identificado"}
                  </div>
                  <div className="lines"></div>
                  <div>
                    {data?.clientScore
                      ? `${data?.clientScore} pontos`
                      : "Inconclusivo"}
                  </div>
                </div>
                <img src={arrowDown} alt="user" className="arrow" />
              </div>
            );
          })
      ) : (
        <div className="card">
          {" "}
          <div className="text">
            <strong>Nenhum lead em evidência no momento</strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadsCard;
