import React, { useContext, useEffect, useState } from "react";
import { TextField, InputAdornment, Slider } from "@mui/material";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import CpuIcon from "../../../assets/Cpu.png";

import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";

import "./Tokens.css";

const Tokens = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { metrics } = location.state;
  const { user } = useContext(AuthContext);
  const tokenInitial = user?.currentPlan?.max_token_count / 2 / 1000000;
  const [token, setToken] = useState(0.1);

  const tokenPrice = user.currentPlan.cost_per_thousand_toukens;

  useEffect(() => {
    if (token < 0) setToken(10);
  }, [token]);

  const sumTotalToken = () => {
    if (tokenInitial > token && tokenInitial > 5) {
      return tokenInitial;
    } else if (token > tokenInitial && token > 5) {
      return token;
    } else if (token < 5 && tokenInitial < 5) {
      return 5;
    } else {
       if (tokenInitial < 5) {
        return 5
       } else {
        return tokenInitial
       }
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerTokens">
        <div className="headerTokens">
          <div className="cardTokens">
            <div className="tokensContainer">
              <div className="tokens-used">
                <img src={CpuIcon} alt="arrow" />
                <div className="subtitle">Seus tokens:</div>
              </div>

              <div className="plans">
                <div className="containerText">
                  <div className="cicle">o</div>
                  <div>
                    <div className="text">Tokens Restantes</div>
                    <div className="subText">
                      {Number(metrics?.remainingTokens).toLocaleString() || "0"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="plans">
                <div className="containerText">
                  <div className="cicle">o</div>
                  <div>
                    <div className="text">Média de Gasto por Dia</div>
                    <div className="subText">
                      {Number(metrics?.averageSpentPerDay).toLocaleString() ||
                        "0"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="plans">
                <div className="containerText">
                  <div className="cicle">o</div>
                  <div>
                    <div className="text">Tokens Gastos</div>
                    <div className="subText">
                      {Number(metrics?.montlyTokensConsumed).toLocaleString() ||
                        "0"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="plans">
                <div className="containerText">
                  <div className="cicle">o</div>
                  <div>
                    <div className="text">Previsão de Gastos Totais do Mês</div>
                    <div className="subText">
                      {Number(
                        metrics?.totalMonthlySpendingForecast
                      ).toLocaleString() || "0"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="containerSlider">
          <div className="cardSlider">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="titleHea">Comprar Tokens Adicionais</div>
              <TextField
                type="number"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                onBlur={(e) => {
                  if (token < 0.1) {
                    setToken(0.1);
                  }
                }}
                style={{
                  width: "175px",
                  marginLeft: "50px",
                  alignSelf: "center",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {token === 1 ? "milhão" : "milhões"}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <Slider
                style={{
                  marginLeft: "25px",
                  marginRight: "15px",
                  marginTop: "10px",
                }}
                min={0.1}
                defaultValue={token}
                value={token}
                onChange={(e) => {
                  setToken(e.target.value);
                }}
                max={sumTotalToken()}
                valueLabelFormat={(value) => {
                  if (value >= 1) {
                    return `${value} milhões`;
                  } else return `${value * 1000} mil`;
                }}
                marks={[
                  {
                    value: 0.1,
                    label: "100 mil",
                  },
                  sumTotalToken() > 5 && {
                    value: sumTotalToken() / 2,
                    label: `${sumTotalToken() / 2} milhões`,
                  },
                  sumTotalToken() > 5 && {
                    value: sumTotalToken(),
                    label: `${sumTotalToken()} milhões`,
                  },
                  sumTotalToken() <= 5 && {
                    value: 2.5,
                    label: `2.5 milhões`,
                  },
                  sumTotalToken() <= 5 && {
                    value: 5,
                    label: `5 milhões`,
                  },
                ]}
                step={0.1}
                valueLabelDisplay="auto"
              />
            </div>
          </div>

          <div className="cardSlider">
            <div className="titleHea">Resumo da Compra</div>

            <div className="containerResume">
              <div className="plans">
                <div className="text">Tokens a serem adquiridos</div>
                <div className="subText">{`${(token * 1000000).toLocaleString(
                  "pt-br"
                )}`}</div>
              </div>

              <div className="plans">
                <div className="text">Custo da Compra</div>
                <div className="subText">{`R$ ${Number(
                  token * (tokenPrice / 100)
                ).toFixed(2)}`}</div>
              </div>
            </div>

            <div
              onClick={() => {
                navigate("/carrinho", {
                  state: {
                    plan: {
                      amount: token * (tokenPrice / 100),
                      attributes: {
                        name: "Compra de Tokens",
                        tokenQuantity: token,
                        planPricePayment: "creditcard",
                      },
                      title: "Compra de Tokens",
                      type: "tokenPurchase",
                    },
                  },
                });
              }}
              className="buttonSlide"
            >
              Comprar tokens
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Tokens;
