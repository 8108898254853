import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import Step5 from "./Components/Step5";
import "./Attendant.css";

const Attendant = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [menuSelected, setMenuSelected] = useState({
    menu: "Simulador do Atendente",
    index: 6,
  });

  // Função para verificar se é um dispositivo móvel (opcional)
  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  // Se houver alguma configuração necessária específica para o simulador, você pode adicionar aqui.
  useEffect(() => {
    // Carregar configurações necessárias para o simulador, se houver.
  }, []);

  // Renderiza o componente Step5, que é o Simulador do Atendente
  return (
    <AuthorizedLayout>
      <div className="containerAttendant">
        <div className="contentWeb">
          <Step5
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            menuSelected={menuSelected}
          />
        </div>

        <div className="contentMobile">
          <Step5
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            menuSelected={menuSelected}
          />
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Attendant;
