import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import parsePhoneAdmin from "../../../utils/parsePhone";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Box, FormControl, Modal, NativeSelect, Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import Papa from "papaparse";

import iconUser from "../../../assets/noAvatar.svg";
import iconArronw from "../../../assets/Vector.png";
import iconLUpa from "../../../assets/MagnifyingGlass.png";
import iconFilter from "../../../assets/Funnel.png";
import iconExport from "../../../assets/Export.png";

import "./PartnerList.css";
import unmask from "../../../utils/unmask";
import trimString from "../../../utils/trimString";
import { FileUpload, FileUploadOutlined } from "@mui/icons-material";

const animatedComponents = makeAnimated();

const topArray = [
  { label: "ID", value: "id" },
  { label: "Nome", value: "partnerName" },
  { label: "Nome do Negócio", value: "companyName" },
  { label: "Email", value: "email" },
  // { label: "Telefone", value: "cNumber" },
  { label: "Assinatura", value: "subscription" },
  { label: "Data de Cadastro", value: "createdAt" },
  { label: "Whatsapp", value: "wppConnected" },
  { label: "Google", value: "connectedWithGoogle" },
];

const PartnerList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState({});

  const getPartners = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("admins/partners/");

      if (data) {
        //console.log(data);
        setPartners(
          data.data.map((item) => {
            return {
              id: Number(item.id),
              partnerName: item.attributes?.name || "Nome não informado",
              sNumber: item.attributes?.serviceNumber
                ? unmask(item.attributes?.serviceNumber)
                : "Número não informado",
              cNumber: item.attributes?.contactNumber
                ? unmask(item.attributes?.contactNumber)
                : "Número não informado",
              subscription: handleSubscription(
                item.attributes?.active,
                item.attributes?.currentPlan
              ),
              ...item.attributes,
            };
          })
          // .sort((a, b) => {
          //   return a.id - b.id;
          // })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar a lista de parceiros.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubscription = (active, plan) => {
    if (!active || !plan) {
      return "Plano Gratuito";
    } else {
      return plan?.name;
    }
  };

  useEffect(() => {
    getPartners();
  }, []);

  const handleClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = partners.filter((item) =>
    item.partnerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const selectedsNamesFilter = filterKeys.map((item) => item.value);

  const generateCSV = (data) => {
    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      "Nome do Parceiro": item?.partnerName || "Nome não informado",
      "Nome da Empresa": item?.name || "Nome não informado",
      Email: item?.email || "N/A",
      "Telefone de Contato":
        parsePhoneAdmin(item?.cNumber) || "Número não informado",
      "Telefone Vinculado":
        parsePhoneAdmin(item?.sNumber) || "Número não informado",
      Assinatura: item?.subscription || "N/A",
      Documento: item?.document || "N/A",
      Google: item?.connectedWithGoogle ? "Vinculado" : "Não Vinculado",
      WhatsApp: item?.wppConnected ? "Vinculado" : "Não Vinculado",
      Status: item?.active ? "Ativa" : "Inativa",
      "Data de Cadastro": item?.createdAt
        ? moment(item?.createdAt).format("DD/MM/YYYY")
        : "N/A",
      "Uso de Tokens": `${item?.montlyTokensConsumed || 0} / ${
        item?.montlyTokensConsumed + item?.monthlyTokensLeft
      }`,
    }));

    const csv = Papa.unparse(formattedData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `SacGPT Parceiros ${moment().format("DD-MM-YYYY")}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function formatCPF(cpf) {
    console.log(cpf);
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return cnpj;
  }

  const truncateEmail = (email, maxLength) => {
    if (email.length <= maxLength) return email;

    const start = email.slice(0, 5); // Exibir os primeiros 5 caracteres
    const end = email.slice(-10); // Exibir os últimos 10 caracteres

    return `${start}...${end}`;
  };

  function extrairNomeAtePrimeiroEspaco(nomeCompleto) {
    const primeiroEspacoIndex = nomeCompleto.indexOf(" ");

    if (primeiroEspacoIndex === -1) {
      return nomeCompleto;
    }

    const nomeAtePrimeiroEspaco = nomeCompleto.substring(
      0,
      primeiroEspacoIndex
    );

    return nomeAtePrimeiroEspaco;
  }

  console.log(currentItems);

  return (
    <AuthorizedLayout>
      <div className="containerClients">
        <div className="containerInputsClients">
          {typeFilter === "lupa" && (
            <input
              type="text"
              placeholder="Buscar por nome"
              value={searchTerm}
              onChange={handleSearchChange}
              className="inputSearch"
            />
          )}

          {typeFilter === "filter" && (
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={filterKeys}
              onChange={setFilterKeys}
              isMulti
              options={topArray}
            />
          )}
        </div>
        <div className="containerPartners">
          <div className="cardClients">
            <div className="header">
              {filterKeys?.map((value, idx) => (
                <div key={idx} style={{ flex: value.label === "ID" && "0.2" }}>
                  <div>{value.label}</div>
                </div>
              ))}

              <div>
                <div className="containerIconFilter">
                  {typeFilter === "lupa" ? (
                    <Tooltip title={"Limpar filtro"}>
                      <button onClick={() => setTypeFilter("")}>X</button>
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Buscar"}>
                      <img
                        src={iconLUpa}
                        alt="icon"
                        onClick={() => setTypeFilter("lupa")}
                      />
                    </Tooltip>
                  )}

                  <Tooltip title={"Exportar página atual"}>
                    <img
                      src={iconExport}
                      alt="icon"
                      onClick={() => generateCSV(currentItems)}
                    />
                  </Tooltip>

                  <Tooltip title={"Exportar tabela completa"}>
                    <button
                      style={{ backgroundColor: "transparent" }}
                      onClick={() => generateCSV(partners)}
                    >
                      <FileUploadOutlined sx={{ color: "#566986" }} />
                    </button>
                  </Tooltip>

                  {/* {typeFilter === "filter" ? (
                    <button onClick={() => setTypeFilter("")}>X</button>
                  ) : (
                    <img
                      src={iconFilter}
                      alt="icon"
                      onClick={() => setTypeFilter("filter")}
                    />
                  )} */}
                </div>
              </div>
            </div>

            {currentItems.length <= 0 && !loading && (
              <div className="containerItens">
                <div className="client">
                  <div>Nenhum parceiro encontrado.</div>
                </div>
              </div>
            )}

            {loading ? (
              <Loader size={100} />
            ) : (
              <div className="containerItens">
                {currentItems?.map((item, idx) => (
                  <div key={idx} className="client">
                    <div
                      style={{
                        backgroundColor: idx % 2 === 0 && "#FFFFFF",
                        borderRadius: "20px 0px 0px 20px",
                        flex: "0.2",
                      }}
                    >
                      {selectedsNamesFilter.includes("id") && (
                        <div className="containerItem">
                          <div>{item?.id}</div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                    >
                      {selectedsNamesFilter.includes("partnerName") && (
                        <div>
                          <div
                            className="containerName"
                            onClick={() => {
                              setSelectedPartner(item);
                              setModal(true);
                            }}
                          >
                            {/* <img
                              src={item?.avatar || iconUser}
                              alt="user"
                              className="userIMage"
                            /> */}
                            <div>{trimString(item?.partnerName, 20)}</div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                    >
                      {selectedsNamesFilter.includes("partnerName") && (
                        <div>
                          <div>{trimString(item?.partnerName, 20)}</div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                    >
                      {selectedsNamesFilter.includes("email") && (
                        <div>
                          <div>{trimString(item?.email, 20)}</div>
                        </div>
                      )}
                    </div>

                    {/* <div
                      style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                    >
                      {console.log(item?.cNumber)}
                      {selectedsNamesFilter.includes("cNumber") && (
                        <div>
                          <div>{item?.cNumber}</div>
                        </div>
                      )}
                    </div> */}

                    <div
                      style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                    >
                      {selectedsNamesFilter.includes("subscription") && (
                        <div>
                          <div>{item?.subscription}</div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                    >
                      {selectedsNamesFilter.includes("createdAt") && (
                        <div>
                          <div>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                    >
                      {selectedsNamesFilter.includes("wppConnected") && (
                        <Tooltip title={item?.wppConnected}>
                          <div>
                            {item?.wppConnected ? "Conectado" : "Desconectado"}
                          </div>
                        </Tooltip>
                      )}
                    </div>

                    <div
                      style={{ backgroundColor: idx % 2 === 0 && "#FFFFFF" }}
                    >
                      {selectedsNamesFilter.includes("connectedWithGoogle") && (
                        <Tooltip title={item?.connectedWithGoogle}>
                          <div>
                            {item?.connectedWithGoogle
                              ? "Conectado"
                              : "Desconectado"}
                          </div>
                        </Tooltip>
                      )}
                    </div>

                    <div
                      style={{
                        backgroundColor: idx % 2 === 0 && "#FFFFFF",
                        borderRadius: "0px 20px 20px 0px",
                      }}
                      onClick={() => navigate(`/admin/parceiros/${item?.id}`)}
                    >
                      <div className="buttonMoreCheck">Ver Clientes</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="containerPagination">
          <div className="pagination">
            <button
              className="containerButton"
              onClick={() => handleClick("prev")}
              disabled={currentPage === 1}
              style={{ opacity: currentPage === 1 && "55%" }}
            >
              <img src={iconArronw} alt="icon" className="left" />
              <div>Anterior</div>
            </button>
            <strong>{` ${currentPage}-${totalPages} `}</strong>de{" "}
            <strong>{`${totalPages}`}</strong>
            <button
              className="containerButton"
              onClick={() => handleClick("next")}
              disabled={totalPages === 1}
              style={{ opacity: totalPages === 1 && "55%" }}
            >
              <div>Próxima</div>
              <img src={iconArronw} alt="icon" />
            </button>
          </div>

          <div className="totalPage">
            <div className="text">Linhas por página:</div>
            <div>
              <FormControl fullWidth>
                <NativeSelect
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                  <option value={30}>30</option>
                  <option value={35}>35</option>
                  <option value={40}>40</option>
                  <option value={45}>45</option>
                  <option value={50}>50</option>
                  <option value={80}>80</option>
                  <option value={100}>100</option>
                </NativeSelect>
              </FormControl>
            </div>
          </div>
        </div>
        {modal && (
          <Modal
            open
            onClose={() => {
              setModal(false);
              setSelectedPartner({});
            }}
            disableAutoFocus
          >
            <Box
              style={{
                background: "#f3f5f8",
                borderRadius: "20px",
              }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                // height: 200,
                p: 2.5,
              }}
            >
              <div className="containerProfile">
                <div className="headerProfile" style={{ maxWidth: "350px" }}>
                  <div className="cardProfile">
                    <div className="containerImg">
                      <img
                        src={selectedPartner?.avatar || iconUser}
                        alt="usuario"
                        className="iconUser"
                      />
                    </div>
                    <div className="title">{selectedPartner?.name}</div>
                    <Tooltip title={selectedPartner?.email}>
                      <div className="containerText">
                        <strong>E-mail:</strong>
                        <span>{selectedPartner?.email}</span>
                      </div>
                    </Tooltip>
                    <div>
                      <strong>Telefone:</strong>{" "}
                      {`${
                        selectedPartner?.contactNumber
                          ? parsePhoneAdmin(selectedPartner?.contactNumber)
                          : "N/A"
                      }`}
                    </div>
                    <div>
                      <strong>CPF/CNPJ:</strong>{" "}
                      {selectedPartner?.document === null
                        ? "N/A"
                        : selectedPartner?.document?.length === 11
                        ? formatCPF(selectedPartner?.document)
                        : formatCNPJ(selectedPartner?.document)}
                    </div>
                    <div>
                      <strong>Data de Cadastro:</strong>{" "}
                      {moment(selectedPartner?.createdAt).format("DD/MM/YYYY")}
                    </div>
                  </div>

                  <div className="cardProfile">
                    <div>
                      <strong>Nome do Negócio:</strong>{" "}
                      {selectedPartner?.companyName || "N/A"}
                    </div>
                    <div>
                      <strong>Assinatura:</strong>{" "}
                      {selectedPartner?.subscription ||
                        "Nenhum plano selecionado"}
                    </div>
                    <div>
                      <strong>Tokens disponíveis:</strong>{" "}
                      {Number(
                        selectedPartner?.remainingTokens || 0
                      )?.toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default PartnerList;
