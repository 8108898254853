const detectCardFlag = (number) => {
  const cleanedNumber = number.replace(/\D/g, "");

  if (/^4/.test(cleanedNumber)) {
    return "Visa";
  } else if (/^5[1-5]/.test(cleanedNumber)) {
    return "Mastercard";
  } else if (/^3[47]/.test(cleanedNumber)) {
    return "American Express";
  } else if (/^6(?:011|5)/.test(cleanedNumber)) {
    return "Discover";
  } else {
    return "Desconhecida";
  }
};

export default detectCardFlag;
